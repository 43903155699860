import React, { useEffect, useState, useCallback } from "react";
import './index.scss'; 
import { useTranslation } from "react-i18next";
import { throttle, screenType } from '../../../components/common/utils.js'
const FeatureRow = ({ 
  tag,
  title,
  description,
  children, 
  imageUrl, 
  reverse, 
  mobiletitle = title, 
  mobileDescription = description
}) => {
  let { i18n } = useTranslation();
  const [type, setType] = useState("");

  const handleResize = useCallback(() => {
    const throttleFn = throttle(() => {
      setType(screenType());
    }, 300);
    throttleFn();
  },[setType]);

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    }
  }, [handleResize]);
  return (
    <div className={`FeatureRow ${reverse ? 'reverse' : ''} ${i18n.language}`}>
      <div className='feature_content'>
        <div className="feature_tag font-dinot">
          <div className="tag_text">{tag}</div>
        </div>
        <h2 className="title">{type === 'mobile' ? mobiletitle : title}</h2>
        <div className="description">{type === 'mobile' ? mobileDescription : description}</div>
        <div className="content">{children}</div>
      </div>
      <img className="feature_image" src={imageUrl} alt="Feature_image" />
    </div>
  );
};

export default FeatureRow;