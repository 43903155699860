import avatar1 from '../../assets/images/Ecommerce/avatar_1.svg'
import avatar2 from '../../assets/images/Ecommerce/avatar_2.svg'
import avatar3 from '../../assets/images/Ecommerce/avatar_3.svg'
import feature1 from '../../assets/images/Ecommerce/feature_1.svg'
import feature2 from '../../assets/images/Ecommerce/feature_2.png'
import feature3 from '../../assets/images/Ecommerce/feature_3.svg'
import feature_icon_1_1 from '../../assets/images/Ecommerce/icon/feature_icon_1_1.svg'
import feature_icon_1_2 from '../../assets/images/Ecommerce/icon/feature_icon_1_2.svg'
import feature_icon_1_3 from '../../assets/images/Ecommerce/icon/feature_icon_1_3.svg'
import feature_icon_2_1 from '../../assets/images/Ecommerce/icon/feature_icon_2_1.svg'
import feature_icon_2_2 from '../../assets/images/Ecommerce/icon/feature_icon_2_2.svg'
import feature_icon_2_3 from '../../assets/images/Ecommerce/icon/feature_icon_2_3.svg'
import feature_icon_3_1 from '../../assets/images/Ecommerce/icon/feature_icon_3_1.svg'
import feature_icon_3_2 from '../../assets/images/Ecommerce/icon/feature_icon_3_2.svg'
import feature_icon_3_3 from '../../assets/images/Ecommerce/icon/feature_icon_3_3.svg'



import i18n from "../../i18n";

const { t } = i18n

const CaseList = [
    {
        id:1,
        avatarSrc: avatar1,
        name: t('ecommerce.businessCase.case1.name'),
        efficiency: {
            item1: {
                value: t('ecommerce.businessCase.case1.item1.value'),
                num: t('ecommerce.businessCase.case1.item1.num'),
                down: true,
            },
            item2: {
                value: t('ecommerce.businessCase.case1.item2.value'),
                num: t('ecommerce.businessCase.case1.item2.num'),
                down: false,
            }
        }
    },
    {
        id:2,
        avatarSrc: avatar2,
        name: t('ecommerce.businessCase.case2.name'),
        efficiency: {
            item1: {
                value: t('ecommerce.businessCase.case2.item1.value'),
                num: t('ecommerce.businessCase.case2.item1.num'),
                down: true,
            },
            item2: {
                value: t('ecommerce.businessCase.case2.item2.value'),
                num: t('ecommerce.businessCase.case2.item2.num'),
                down: false,
            }
        }
    },
    {
        id:3,
        avatarSrc: avatar3,
        name: t('ecommerce.businessCase.case3.name'),
        efficiency: {
            item1: {
                value: t('ecommerce.businessCase.case3.item1.value'),
                num: t('ecommerce.businessCase.case3.item1.num'),
                down: false,
            },
            item2: {
                value: t('ecommerce.businessCase.case3.item2.value'),
                num: t('ecommerce.businessCase.case3.item2.num'),
                down: false,
            }
        }
    }
]

const FeatureContentList = [
    {
        imageSrc: feature1,
        title: t(`ecommerce.feature.feature1.title`),
        description: t(`ecommerce.feature.feature1.description`),
        textContent: [
            {
                icon: feature_icon_1_1,
                text: t(`ecommerce.feature.feature1.text1`),
            },
            {
                icon: feature_icon_1_2,
                text: t(`ecommerce.feature.feature1.text2`),
            },
            {
                icon: feature_icon_1_3,
                text: t(`ecommerce.feature.feature1.text3`),
            }
        ]
    },
    {
        imageSrc: feature2,
        title: t(`ecommerce.feature.feature2.title`),
        description: t(`ecommerce.feature.feature2.description`),
        textContent: [
            {
                icon: feature_icon_2_1,
                text: t(`ecommerce.feature.feature2.text1`),
            },
            {
                icon: feature_icon_2_2,
                text: t(`ecommerce.feature.feature2.text2`),
            },
            {
                icon: feature_icon_2_3,
                text: t(`ecommerce.feature.feature2.text3`),
            }
        ]
    },
    {
        imageSrc: feature3,
        title: t(`ecommerce.feature.feature3.title`),
        description: t(`ecommerce.feature.feature3.description`),
        textContent: [
            {
                icon: feature_icon_3_1,
                text: t(`ecommerce.feature.feature3.text1`),
            },
            {
                icon: feature_icon_3_2,
                text: t(`ecommerce.feature.feature3.text2`),
            },
            {
                icon: feature_icon_3_3,
                text: t(`ecommerce.feature.feature3.text3`),
            }
        ]
    },
]

export {
    CaseList,
    FeatureContentList
};