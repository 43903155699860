import cmge from "../../assets/images/Example/cmge.png";
import leiting from "../../assets/images/Example/leiting.png";
import eyewind from "../../assets/images/Example/eyewind.png";
import minijoy from "../../assets/images/Example/minijoy.png";
import sagi from "../../assets/images/Example/sagi.png";
import wawa from "../../assets/images/Example/wawa.png";
import tantan from "../../assets/images/Example/tantan.png";
import tiptop from "../../assets/images/Example/tiptop 1.png"
import going from "@/assets/images/Example/going.png"
import gamehaus from "@/assets/images/Example/gamehaus.png"
import alictus from "@/assets/images/Example/alictus.png"

import leitingImg from "../../assets/images/More/leiting/bgImg.png";
import cmgeImg from "../../assets/images/More/cmge/bgImg.png";
import eyewindImg from "../../assets/images/More/eyewind/bgImg.png";
import minijoyImg from "../../assets/images/More/minijoy/bgImg.png";
import sagiImg from "../../assets/images/More/sagi/bgImg.png";
import wawaImg from "../../assets/images/More/wawa/bgImg.png";
import tantanImg from "../../assets/images/More/tantan/bgImg.png"
import TipTopImg from "@/assets/images/More/TipTop/bgImg.png"
import goingImg from "@/assets/images/More/going/bgImg.jpg"
import gamehausImg from "@/assets/images/More/gamehaus/bgImg.jpg"
import alictusImg from "@/assets/images/More/alictus/bgImg.jpg"

import i18n from "../../i18n";
const { t } = i18n
const brandList = [
  {
    id: 11,
    name: "example.brand.item11.name",
    logo: going,
    link: "",
    img: goingImg,
    value: t(`example.brand.item11.text`),
  },
  {
    id: 12,
    name: "example.brand.item12.name",
    logo: gamehaus,
    link: "",
    img: gamehausImg,
    value: t(`example.brand.item12.text`),
  },
  {
    id: 13,
    name: "example.brand.item13.name",
    logo: alictus,
    link: "",
    img: alictusImg,
    value: t(`example.brand.item13.text`),
  },
  {
    id: 10,
    name: "example.brand.item10.name",
    logo: tiptop,
    img: TipTopImg,
    link: "",
    value: t(`example.brand.item10.text`),
  },
  {
    id: 2,
    name: "example.brand.item2.name",
    logo: cmge,
    link: "",
    img: cmgeImg,
    value: t(`example.brand.item2.text`),
  },
  {
    id: 1,
    name: "example.brand.item1.name",
    logo: leiting,
    link: "",
    img: leitingImg,
    value: t(`example.brand.item1.text`),
  },
  {
    id: 4,
    name: "example.brand.item4.name",
    logo: minijoy,
    link: "",
    img: minijoyImg,
    value: t(`example.brand.item4.text`),
    backColor: "#254FFF",
  },
  {
    id: 3,
    name: "example.brand.item3.name",
    logo: eyewind,
    link: "",
    img: eyewindImg,
    value: t(`example.brand.item3.text`),
  },
  {
    id: 5,
    name: "example.brand.item5.name",
    logo: wawa,
    link: "",
    img: wawaImg,
    value: t(`example.brand.item5.text`),
  },
  {
    id: 8,
    name: "example.brand.item8.name",
    logo: tantan,
    link: "",
    img: tantanImg,
    value: t(`example.brand.item8.text`),
  },
  {
    id: 6,
    name: "example.brand.item6.name",
    logo: sagi,
    link: "",
    img: sagiImg,
    value: t(`example.brand.item6.text`),
  },

];

console.log(brandList)

export { brandList };
