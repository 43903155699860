import React, { Component } from "react";
import { Translation } from "react-i18next";
import Marquee from "react-fast-marquee";
import PraiseSwiper from "../../components/common/PraiseSwiper";
import protectAdd from '../../assets/images/Home/protect_add.png';
import { screenType, getFreeUrl, throttle } from "../../components/common/utils"
import FreeUse from "../../components/common/FreeUse";
import BottomBar from "../../components/common/BottomBar";
import Carousel from "./Carousel";
import {
  companyLists,
  praiseList,
  brandList,
  applications_cn,
  applications_en,
  protectList
} from "./config";
import IncNumber from "../../components/common/IncNumber";
import { goLoginOrSignUp } from '@/utils/common.js';

import "./index.scss";
import i18n from "../../i18n";

export default class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dashboardY: 0,
      type: "pc",
      updateBrandList: [],
    };
    this.updateBrandList = i18n.language === 'en' ? brandList.slice(0, -1) : brandList
  }

  handleResize() {
    this.setState({
      type: screenType(),
    });
  }
  throttledHandleResize = throttle(() => {
    this.handleResize();
  }, 300);

  handleLoginOrSignUp = () => {
    goLoginOrSignUp(i18n.language);
  }

  componentDidMount() {
    this.handleResize();
    window.addEventListener("scroll", this.bindHandleScroll);
    window.addEventListener('resize', this.throttledHandleResize);
  }

  bindHandleScroll = () => {
    let innerWidth = window.innerWidth;
    let scrollTop = document.documentElement.scrollTop;
    let temp = innerWidth * 0.026 * 10;

    scrollTop > temp
      ? this.setState({ dashboardY: temp })
      : this.setState({ dashboardY: scrollTop });
  };


  render() {
    const { type } = this.state;
    return (
      <Translation>
        {(t) => {
          return (
            <div className={`Home ${i18n.language}`}>
              <Carousel type={type} openDialog={this.handleLoginOrSignUp}/>
              <div className="company_wrapper">
                <h2 ref={this.companyTitle} className="title" dangerouslySetInnerHTML={{ __html: type === 'mobile' ? t("home.company.mobileTitle") : t("home.company.title") }}>
                </h2>
                <div className="company_list">
                  {this.updateBrandList.map((section, sectionIndex, index) => {
                    return (
                      <div className="company_list_section" key={sectionIndex}>
                        <h3 className="company_list_title font-black">{section.title}</h3>
                        <div className="company_list_items">{
                          section.list.map((item, index) => {
                            // 获取logo名称
                            const brandName = item.split('/').pop().split('.')[0];
                            const remarkText = section.remark ? section.remark[brandName] : null;
                            return (
                              <div className="company_list_item" key={index}>
                                <img src={item} alt="" width="352px" height="76px"/>
                                {remarkText && <p className="company_list_remark font-dinot">{remarkText}</p>}
                              </div>
                            )
                        })}</div>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="application_wrapper">
                <div className="content_wrapper">
                  <div className="title_wrapper">
                    <h2 className={`title ${i18n.language === 'cn' && 'font-num'}`}>{t("home.application.title")}</h2>
                    <div className="more">
                      <FreeUse
                        config={{
                          bgColor: "blue",
                          value: "home.main.learnMore",
                          onClick: () => window.location.href = `/${i18n.language}/use`,
                          type,
                        }}
                      ></FreeUse>
                    </div>
                  </div>
                    {
                      (i18n.language === 'en' ? applications_en : applications_cn).map(item => (
                        <div className="content_item" key={item.title}>
                          <a href={`/${i18n.language}/use`} >
                            <img src={item.img} alt="" />
                            <h3 className={`${i18n.language === 'cn' && 'font-num'}`}>{item.title}</h3>
                          </a>
                        </div>
                      ))
                    }
                  </div>
              </div>
              <div className="efficiency_wrapper">
                <div className={`title_wrapper ${i18n.language}`}>
                  <h2 className={`${i18n.language === 'cn' && 'font-num'}`}>{t("home.efficiency.title")}</h2>
                </div>
                <div className="wrapper">
                  <div className={["percent_wrapper", i18n.language].join(" ")}>
                    <div className="record_item">
                      <div className="text">
                        {t("home.efficiency.item1.value")}
                      </div>
                      <IncNumber
                        text={t("home.efficiency.item1.num")}
                        down={true}
                        type1={type}
                      ></IncNumber>
                    </div>
                    <div className="record_item">
                      <div className="text">
                        {t("home.efficiency.item2.value")}
                      </div>
                      <IncNumber
                        text={t("home.efficiency.item2.num")}
                        down={true}
                        type1={type}
                      ></IncNumber>
                    </div>
                    <div className="record_item">
                      <div className="text">
                        {t("home.efficiency.item3.value")}
                      </div>
                      <IncNumber
                        text={t("home.efficiency.item3.num")}
                        type1={type}
                      ></IncNumber>
                    </div>
                    <div className="record_item">
                      <div className="text">
                        {t("home.efficiency.item4.value")}
                      </div>
                      <IncNumber
                        text={t("home.efficiency.item4.num")}
                        type1={type}
                      ></IncNumber>
                    </div>
                    <div className="record_item">
                      <div className="text">
                        {t("home.efficiency.item5.value")}
                      </div>
                      <IncNumber
                        text={t("home.efficiency.item5.num")}
                        type1={type}
                      ></IncNumber>
                    </div>
                    <div className="record_item">
                      <div className="text">
                        {t("home.efficiency.item6.value")}
                      </div>
                      <IncNumber
                        text={t("home.efficiency.item6.num")}
                        type1={type}
                      ></IncNumber>
                    </div>
                  </div>
                  <div className="blue_box"></div>
                </div>
                <div className="more">
                  <FreeUse
                    config={{
                      bgColor: "blue",
                      value: "home.efficiency.freeUse",
                      onClick: this.handleLoginOrSignUp,
                      type,
                    }}
                  ></FreeUse>
                </div>
              </div>
              <div className={["brand_wrapper", i18n.language].join(" ")}>
                <h2 className={`title ${i18n.language === 'cn' && 'font-num'}`}>{type === 'mobile' ? t('home.brand.mobileTitle') : t("home.brand.title")}</h2>
                <div className="swiper_wrapper">
                  {companyLists.map((item, index) =>
                    <Marquee gradient={false} direction={(index === 1|| index === 3 ? 'right' : 'left')} key={index}>
                      {item?.map((iitem, index) => {
                        return <img src={iitem} key={iitem} alt="" />;
                      })}
                    </Marquee>
                  )}
                </div>
              </div>
              <div className="option_wrapper">
                <h2 className="title">{t("home.option.title")}</h2>
                <div className="swiper_container">
                  <PraiseSwiper
                    bannerList={praiseList}
                    type={type}
                  ></PraiseSwiper>
                </div>
              </div>
              <div className="protect_wrapper">
                <h2 className="title">{t("home.protect.title")}</h2>
                <div className="wrapper">
                  {
                    protectList.map((item, index) => (
                      <React.Fragment key={index}>
                        <div className="protect_item">
                          <img src={item.img} alt="" />
                          <div className={i18n.language === 'en' ? 'font-medium' : 'font-dinot'}>{type === 'mobile' && item.mobileText ? item.mobileText : item.text}</div>
                        </div>
                        { index+1 !== protectList.length && <img src={protectAdd} alt="" />}
                      </React.Fragment>
                    ))
                  }
                </div>
              </div>
              <BottomBar type="type1" text={type==='mobile' ? t('home.connect.mobileTitle') : t('home.connect.title')} buttonText={t('home.connect.freeUse')} onClick={this.handleLoginOrSignUp}></BottomBar>
            </div>
          );
        }}
      </Translation>
    );
  }
}
