import React from "react";
import "./index.scss";
import { ReactComponent as Up } from "../../../assets/images/up.svg";
import { ReactComponent as Down } from "../../../assets/images/down.svg";
import {CaseList} from '../config'

function BusinessCase() {
    
    return (
      <div className="EcommerceBusinessCase">
        {CaseList.map(item => (
            <div className="business_case_item" key={item.id}>
                    <div className="profile_wrapper">
                        <img className="avatar" src={item.avatarSrc} alt="avatar"/>
                        <span className="name">{item.name}</span>
                    </div>
                    <div className="efficiency_wrapper">
                        <div className="efficiency_value">{item.efficiency.item1.value}</div>
                        <div className="efficiency_num blue">
                            <div className="icon_wrapper"> 
                                {item.efficiency.item1.down ? <Down /> : <Up/>}
                            </div>
                            <span className="num font-num ">{item.efficiency.item1.num}</span>
                        </div>
                    </div>
                    <div className="efficiency_wrapper">
                        <div className="efficiency_value">{item.efficiency.item2.value}</div>
                        <div className="efficiency_num yellow">
                            <div className="icon_wrapper"> 
                                {item.efficiency.item2.down ? <Down /> : <Up/>}
                            </div>
                            <span className="num font-num ">{item.efficiency.item2.num}</span>
                        </div>
                    </div>
            </div>
        ))}
      </div>
    );
  }
  
  export default BusinessCase;