import React from "react";
import "./index.scss";
import { ReactComponent as Close } from "./Close.svg";

function Tag(props) {
  const { name, isSelected, onClick, onClose } = props;
  return (
      <div
        className={`tag ${isSelected ? "tag_selected" : ""}`}
        onClick={onClick}
      >
        <span className="tag_name font-dinot">{name}</span>
        {isSelected && (
            <Close 
              className="tag_close"
              onClick={(e) => {
                e.stopPropagation();
                onClose();
              }}
            />
        )}
      </div>
    );
  }
  
  export default Tag;