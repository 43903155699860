import i18n from '../../i18n'
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { screenType } from '../../components/common/utils.js'
import { options,list } from './config.js'
import FilterBox from '../../components/common/FilterBox/index.jsx';
import "./index.scss";

const logoList = i18n.language === 'en' ? list.filter(item => ![18,19,20,21,25,27,28,30,31,32,33].includes(item.id)) : list
function Partner() {
  const { t, i18n } = useTranslation();
  const [type, setType] = useState("");
  const [selectedTags, setSelectedTags] = useState({
    serviceType: [],
    businessArea: [],
    promotionArea: []
  });
  const [showList, setShowList] = useState(logoList);

  const handleTagSelection = (filterType, newSelectedTags) => {
    setSelectedTags(prevSelectedTags => ({
      ...prevSelectedTags,
      [filterType]: newSelectedTags,
    }));
  };
  useEffect(()=>{
    setType(screenType());
  },[])
  useEffect(() => {
    const updatedShowList = logoList.filter(logo => 
      ["serviceType", "businessArea", "promotionArea"].every(type => {
        if (!selectedTags[type].length) return true; 
    
        return selectedTags[type].some(tag => logo[type].includes(tag) || !logo[type].length); 
      })
    );

    setShowList(updatedShowList);
  }, [selectedTags]);
  return (
    <div className="partner">
      <header>
        <div className="white-box"></div>
        <h1 className={`${i18n.language}`}>{type === 'mobile' ? t('partner.mobileTitle') : t('partner.title')}</h1>
        <div className="blue-box"></div>
        <div className="orange-box"></div>
      </header>
      <main>
        <div className='filter_box'>
          <div className='service_type'>
            <FilterBox 
              label={t('partner.serviceType.label')} 
              options={options.serviceTypeOption} 
              filterType={'serviceType'} 
              currentTag={selectedTags.serviceType} 
              onDone={handleTagSelection}
            />
          </div>
          <div className='business_area'>
            <FilterBox 
              label={t('partner.businessArea.label')}  
              options={options.businessAreaOption} 
              filterType={'businessArea'} 
              currentTag={selectedTags.businessArea} 
              onDone={handleTagSelection}
            />
          </div>
          <div className='promotion_area'>
            <FilterBox 
              label={t('partner.promotionArea.label')}  
              options={options.promotionAreaOption} 
              filterType={'promotionArea'} 
              currentTag={selectedTags.promotionArea} 
              onDone={handleTagSelection}
              />
            </div>
        </div>
        <div className='list'>
          {
            showList.map(item => (
              <div className="list_item" key={item.id}>
                <a className="list_item_img" href={i18n.language === 'en' ? item.enUrl : item.chUrl} target='__blank'>
                  <img src={item.logo} alt="logo" width={item.width} height={item.height}/>
                </a>
                <div className="list_item_text">
                  <p className='font-bold'>{item.name}</p>
                  <span className='font-dinot'>{i18n.language === 'en' ? item.enType : item.chType}</span>
                </div>
              </div>
            ))
          }
        </div>
      </main>
    </div>
  );
}
export default Partner;
