import {getFreeUrl} from "../../common/utils.js"
import i18n from "../../../i18n"
const { t } = i18n

const getMoreUrl = (i18nPath) => {
	return `/more/${t(i18nPath, { lng: 'en'}).toLowerCase().replace(' ', '-')}`
}
const routerList = [
	{
		name:"/use",
		url:getFreeUrl("header.urlList.use")
	},
	{
		name:"/example",
		url:getFreeUrl("header.urlList.example")
	},
	{
		name:"/partner",
		url:getFreeUrl("header.urlList.partner")
	},
	{
		name:"/ecommerce",
		url:getFreeUrl("header.urlList.ecommerce")
	},
	{
		name:"/appsolution",
		url:getFreeUrl("header.urlList.appsolution")
	},
	{
		name:getMoreUrl('example.brand.item1.name'),
		url:getFreeUrl("header.urlList.leiting")
	},
	{
		name: getMoreUrl('example.brand.item2.name'),
		url:getFreeUrl("header.urlList.cmge")
	},
	{
		name: getMoreUrl('example.brand.item3.name'),
		url:getFreeUrl("header.urlList.eyewind")
	},
	{
		name: getMoreUrl('example.brand.item4.name'),
		url:getFreeUrl("header.urlList.minijoy")
	},
	{
		name: getMoreUrl('example.brand.item5.name'),
		url:getFreeUrl("header.urlList.wawa")
	},
	{
		name: getMoreUrl('example.brand.item6.name'),
		url:getFreeUrl("header.urlList.sagi")
	},
	{
		name: getMoreUrl('example.brand.item7.name'),
		url:getFreeUrl("header.urlList.supercent")
	},
	{
		name: getMoreUrl('example.brand.item8.name'),
		url:getFreeUrl("header.urlList.tantan")
	},
]
export {routerList}