import React, { Component } from "react";
import { Translation } from "react-i18next";
import SwiperCore, { Autoplay, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { isMobileByScreen } from '../utils.js';
import "./index.scss";

SwiperCore.use([Autoplay, Pagination]);

export default class AppSwiper extends Component {
  constructor(props) {
    super(props);
    this.handleResize();
  }

  handleResize = () => {
    console.log(window.innerWidth)
    if(isMobileByScreen()) {
      this.preViewCount = 1.8
    } else {
      if(window.innerWidth >= 1920) {
        this.preViewCount = 2
      } else {
        this.preViewCount = 2.5
      }
    }
  };

  componentDidMount() {
    if (!this.props.slidesPerView) {
      window.addEventListener('resize', this.handleResize);
    }
  }

  componentWillUnmount() {
    if (!this.props.slidesPerView) {
      window.removeEventListener('resize', this.handleResize);
    }
  }

  render() {
    const {direction, bannerList, slidesPerView} = this.props;
    const preViewCount = slidesPerView || this.preViewCount;

    return (
      <Translation>
        {(t) => {
          return (
                <Swiper
                  loop
                  slidesPerView={preViewCount}
                  direction="vertical"
                  speed={4000}
                  grabCursor
                  allowTouchMove={false}
                  preventClicksPropagation={false}
                  autoplay={{
                    delay: 1,
                    disableOnInteraction: false,
                    reverseDirection: direction,
                  }}
                >
                  {bannerList?.map((item, index) => {
                    return (
                      <SwiperSlide key={index}>
                        <div className="imgs_wrapper">
                          <img src={item.url} alt="" />
                        </div>
                      </SwiperSlide>
                    );
                  })}
                </Swiper>
          );
        }}
      </Translation>
    );
  }
}
