import googleAds from '../../assets/images/Partner/google.png'
import meta from '../../assets/images/Partner/meta.png'
import tiktok from '../../assets/images/Partner/TTFB.png'
import apple from '../../assets/images/Partner/search ads.png'
import kwai from '../../assets/images/Partner/kwai.png'
import huawei from '../../assets/images/Partner/petal ads.png'

import mintegral from '../../assets/images/Partner/mtg.png'
import liftoff from '../../assets/images/Partner/liftoff.png'
import AppLovin from '../../assets/images/Partner/applovin.png'
import Unity from '../../assets/images/Partner/unity.png'
import ironSource from '../../assets/images/Partner/isronsource.png'
import AppsFlyer from '../../assets/images/Partner/appsflyer.png'
import Adjust from '../../assets/images/Partner/adjust.png'
import Tenjin from '../../assets/images/Partner/tenjin.png'
import TrackingIO from '../../assets/images/Partner/trackingio.png'
import MAX from '../../assets/images/Partner/max.png'
import TopOn from '../../assets/images/Partner/topon.png'
import Shopify from '../../assets/images/Partner/shopify.png'
import SHOPLINE from '../../assets/images/Partner/shopline.png'
import SolorEngine from '../../assets/images/Partner/solarengine.png'

import baijing from '../../assets/images/Partner/baijing.png'
// import tobidad from '../../assets/images/Partner/tobid.png'
import qimai from '../../assets/images/Partner/qimai.png'
import bigseller from '../../assets/images/Partner/bigseller.png'
import paasoo from '../../assets/images/Partner/paasoo.png'
import dashu from '../../assets/images/Partner/dashu.png'
import adspower from '../../assets/images/Partner/adspower.png'
import kuajing from '../../assets/images/Partner/kuajing.png'
import wintopay from '../../assets/images/Partner/wintopay.png'
import DuoPlus from '../../assets/images/Partner/DuoPlus.png'
import BiTeLiuLanQi from '../../assets/images/Partner/BiTeLiuLanQi.png'
import HuoBaoLiuLanQi from '../../assets/images/Partner/HuoBaoLiuLanQi.png'
import XiaoXiongHTTP from '../../assets/images/Partner/XiaoXiongHTTP.png'
import VeryFb from '../../assets/images/Partner/VeryFb.png'

import i18n from "../../i18n";
const { t } = i18n
const options = {
  serviceTypeOption: {
    cloudService: t('partner.serviceType.cloudService'),
    dataServices: t('partner.serviceType.dataServices'),
    userAcquistition: t('partner.serviceType.userAcquistition'),
    monetization: t('partner.serviceType.monetization'),
    localization: t('partner.serviceType.localization'),
    audioAndVideo: t('partner.serviceType.audioAndVideo'),
    analytics: t('partner.serviceType.analytics'),
    measurementAttribution: t('partner.serviceType.measurementAttribution'),
    payment: t('partner.serviceType.payment'),
    testing: t('partner.serviceType.testing'),
    securityandCompliance: t('partner.serviceType.securityandCompliance'),
    influencer: t('partner.serviceType.influencer'),
    SMS: t('partner.serviceType.SMS'),
    industryMedia: t('partner.serviceType.industryMedia'),
    other: t('partner.serviceType.other')
  },
  businessAreaOption: {
    lifestyleServices: t('partner.businessArea.lifestyleServices'),
    education: t('partner.businessArea.education'),
    healthMedical: t('partner.businessArea.healthMedical'),
    gamesEntertainment: t('partner.businessArea.gamesEntertainment'),
    utilities: t('partner.businessArea.utilities'),
    socialNetworks: t('partner.businessArea.socialNetworks'),
    newsMagazines: t('partner.businessArea.newsMagazines'),
    travelTransportation: t('partner.businessArea.travelTransportation'),
    ecommerce: t('partner.businessArea.ecommerce'),
    financialServices: t('partner.businessArea.financialServices'),
    musicVideo: t('partner.businessArea.musicVideo'),
    security: t('partner.businessArea.security'),
    sportsFitness: t('partner.businessArea.sportsFitness'),
    other: t('partner.businessArea.other'),
    all: t('partner.businessArea.all')
  },
  promotionAreaOption: {
    NorthAmerica: t('partner.promotionArea.NorthAmerica'),
    Europe: t('partner.promotionArea.Europe'),
    Asia: t('partner.promotionArea.Asia'),
    SouthAmerica: t('partner.promotionArea.SouthAmerica'),
    Africa: t('partner.promotionArea.Africa'),
    Oceania: t('partner.promotionArea.Oceania'),
    MiddleEast: t('partner.promotionArea.MiddleEast'),
    Other: t('partner.promotionArea.Other'),
    Global: t('partner.promotionArea.Global'),
  }
}

const list = [
  {
    id: 1,
    name: 'Google Ads',
    logo: googleAds,
    enUrl: 'https://ads.google.com/home/',
    chUrl: 'https://ads.google.com/intl/zh-CN_cn/home/',
    enType: 'Ad Channels',
    chType: '广告渠道',
    width: '100%',
    height: 'auto',
    serviceType: [
      t('partner.serviceType.userAcquistition'),
    ],
    businessArea: [],
    promotionArea: []
  },
  {
    id: 2,
    name: 'Meta Ads',
    logo: meta,
    enUrl: 'https://www.facebook.com/business/m/get-started-meta-advertising?content_id=LzdOWfcAMfsPX4J&utm_term=meta%20ads&gclid=Cj0KCQiA67CrBhC1ARIsACKAa8TzNzT1t1oP-g-tBVD893Wk_VMXEBZyeEjm2hBYET0yHG0MDHc9DcgaAt-nEALw_wcB#meta-ads',
    chUrl: 'https://zh-tw.facebook.com',
    enType: 'Ad Channels',
    chType: '广告渠道',
    width: '100%',
    height: 'auto',
    serviceType: [
      t('partner.serviceType.userAcquistition'),
    ],
    businessArea: [],
    promotionArea: []
  },
  {
    id: 3,
    name: 'TikTok for Business',
    logo: tiktok,
    enUrl: 'https://www.tiktok.com/business/en',
    chUrl: 'https://www.tiktok.com/business/zh',
    enType: 'Ad Channels',
    chType: '广告渠道',
    width: '100%',
    height: 'auto',
    serviceType: [
      t('partner.serviceType.userAcquistition'),
    ],
    businessArea: [],
    promotionArea: []
  },
  {
    id: 4,
    name: 'Apple Search Ads',
    logo: apple,
    enUrl: 'https://searchads.apple.com/',
    chUrl: 'https://ads.apple.com/cn',
    enType: 'Ad Channels',
    chType: '广告渠道',
    width: '100%',
    height: 'auto',
    serviceType: [
      t('partner.serviceType.userAcquistition'),
    ],
    businessArea: [],
    promotionArea: []
  },
  {
    id: 5,
    name: 'Kwai for business',
    logo: kwai,
    enUrl: 'https://www.kwai.com/business#ads-solutions',
    chUrl: 'https://www.kwai.com/business/zh-CN#ads-solutions',
    enType: 'Ad Channels',
    chType: '广告渠道',
    width: '100%',
    height: 'auto',
    serviceType: [
      t('partner.serviceType.userAcquistition'),
    ],
    businessArea: [],
    promotionArea: []
  },
  {
    id: 6,
    name: 'Petal Ads',
    logo: huawei,
    enUrl: 'https://ads.huawei.com/usermgtportal/home/index.html#/',
    chUrl: 'https://ads.huawei.com/usermgtportal/home/index.html#/',
    enType: 'Ad Channels',
    chType: '广告渠道',
    width: '100%',
    height: 'auto',
    serviceType: [
      t('partner.serviceType.userAcquistition'),
    ],
    businessArea: [],
    promotionArea: []
  },
  {
    id: 7,
    name: 'Mintegral',
    logo: mintegral,
    enUrl: 'https://www.mintegral.com/en',
    chUrl: 'https://www.mintegral.com/cn/',
    enType: 'Ad Channels',
    chType: '广告渠道',
    width: '100%',
    height: 'auto',
    serviceType: [
      t('partner.serviceType.userAcquistition'),
    ],
    businessArea: [],
    promotionArea: []
  },
  {
    id: 8,
    name: 'liftoff',
    logo: liftoff,
    enUrl: 'https://liftoff.io/',
    chUrl: 'https://liftoff.io/zh-hans/',
    enType: 'Ad Channels',
    chType: '广告渠道',
    width: '100%',
    height: 'auto',
    serviceType: [
      t('partner.serviceType.userAcquistition'),
    ],
    businessArea: [],
    promotionArea: []
  },
  {
    id: 9,
    name: 'AppLovin',
    logo: AppLovin,
    enUrl: 'https://www.applovin.com/',
    chUrl: 'https://www.applovin.com/cn/',
    enType: 'Ad Channels',
    chType: '广告渠道',
    width: '100%',
    height: 'auto',
    serviceType: [
      t('partner.serviceType.userAcquistition'),
    ],
    businessArea: [],
    promotionArea: []
  },
  {
    id: 10,
    name: 'Unity Ads',
    logo: Unity,
    enUrl: 'https://unity.com/products/unity-ads',
    chUrl: 'https://unity.com/cn/products/unity-ads',
    enType: 'Ad Channels',
    chType: '广告渠道',
    width: '100%',
    height: 'auto',
    serviceType: [
      t('partner.serviceType.userAcquistition'),
    ],
    businessArea: [],
    promotionArea: []
  },
  {
    id: 11,
    name: 'ironSource',
    logo: ironSource,
    enUrl: 'https://www.is.com/',
    chUrl: 'https://www.is.com/zh-hant/',
    enType: 'Ad Channels',
    chType: '广告渠道',
    width: '100%',
    height: 'auto',
    serviceType: [
      t('partner.serviceType.userAcquistition'),
    ],
    businessArea: [],
    promotionArea: []
  },
  {
    id: 12,
    name: 'AppsFlyer',
    logo: AppsFlyer,
    enUrl: 'https://www.appsflyer.com/',
    chUrl: 'https://www.appsflyer-gcr.cn/',
    enType: 'MMP',
    chType: '归因与分析',
    width: '100%',
    height: 'auto',
    serviceType: [
      t('partner.serviceType.measurementAttribution'),
    ],
    businessArea: [],
    promotionArea: []
  },
  {
    id: 13,
    name: 'Adjust',
    logo: Adjust,
    enUrl: 'https://www.adjust.com/',
    chUrl: 'https://www.adjust.com/zh/',
    enType: 'MMP',
    chType: '归因与分析',
    width: '100%',
    height: 'auto',
    serviceType: [
      t('partner.serviceType.measurementAttribution'),
    ],
    businessArea: [],
    promotionArea: []
  },
  {
    id: 14,
    name: 'Tenjin',
    logo: Tenjin,
    enUrl: 'https://tenjin.com/',
    chUrl: 'https://tenjin.com/zh/',
    enType: 'MMP',
    chType: '归因与分析',
    width: '100%',
    height: 'auto',
    serviceType: [
      t('partner.serviceType.measurementAttribution'),
    ],
    businessArea: [],
    promotionArea: []
  },
  {
    id: 15,
    name: 'TrackingIO',
    logo: TrackingIO,
    enUrl: 'https://new.trackingio.com/#/home',
    chUrl: 'https://new.trackingio.com/#/home',
    enType: 'MMP',
    chType: '归因与分析',
    width: '100%',
    height: 'auto',
    serviceType: [
      t('partner.serviceType.measurementAttribution'),
    ],
    businessArea: [],
    promotionArea: [
      t('partner.promotionArea.Asia'),
    ]
  },
  {
    id: 16,
    name: 'MAX',
    logo: MAX,
    enUrl: 'https://www.applovin.com/max/',
    chUrl: 'https://www.applovin.com/cn/max/',
    enType: 'Monetization',
    chType: '变现渠道',
    width: '100%',
    height: 'auto',
    serviceType: [
      t('partner.serviceType.monetization'),
    ],
    businessArea: [],
    promotionArea: []
  },
  {
    id: 17,
    name: 'TopOn',
    logo: TopOn,
    enUrl:  `${window.location.origin}/en-partner/docs/TopOn`,
    chUrl:  `${window.location.origin}/cn-partner/docs/TopOn`,
    enType: 'Monetization',
    chType: '变现渠道',
    width: '100%',
    height: 'auto',
    serviceType: [
      t('partner.serviceType.monetization'),
    ],
    businessArea: [],
    promotionArea: []
  },
  {
    id: 18,
    name: 'Shopify',
    logo: Shopify,
    enUrl: 'https://www.shopify.com/hk-en/free-trial/3s?term=shopify&adid=566072909668&campaignid=15433369410&branded_enterprise=1&BOID=brand&utm_medium=cpc&utm_source=google&gclid=CjwKCAiAjrarBhAWEiwA2qWdCFNxcCLNBLd9_ugApMTdz8K9lp2K_lU8Hr2DggA22xxqh7WRxZC4cBoCcbYQAvD_BwE&cmadid=516585705;cmadvertiserid=10730501;cmcampaignid=26990768;cmplacementid=324494758;cmcreativeid=163722649;cmsiteid=5500011',
    chUrl: 'https://www.shopify.com/zh/free-trial/3-steps?term=shopify&adid=566089809037&campaignid=15433369881&branded_enterprise=1&BOID=brand&utm_medium=cpc&utm_source=google&gclid=CjwKCAiAjrarBhAWEiwA2qWdCDNrHrfxGnIyrCyLFh3Udn6uLgAli6lOXFGo90mpD5_qRigwq3GD7BoCUGoQAvD_BwE&cmadid=516585705;cmadvertiserid=10730501;cmcampaignid=26990768;cmplacementid=324494758;cmcreativeid=163722649;cmsiteid=5500011',
    chType: '独立站平台',
    width: '100%',
    height: 'auto',
    serviceType: [
      t('partner.serviceType.other'),
    ],
    businessArea: [
      t('partner.businessArea.other'),
    ],
    promotionArea: []
  },
  {
    id: 19,
    name: 'SHOPLINE',
    logo: SHOPLINE,
    enUrl: 'https://www.shopline.sg/',
    chUrl: 'https://shopline.hk/?utm_source=google&utm_medium=PM&utm_campaign=PM_Trial&utm_term=&utm_content=&gad_source=1&gclid=CjwKCAiAjrarBhAWEiwA2qWdCAzcqo9T4yg9l0hM8VbwelMTL9dayIpLNxhdUYKygzOwuyWUg3x0HhoC6m8QAvD_BwE',
    chType: '独立站平台',
    width: '100%',
    height: 'auto',
    serviceType: [
      t('partner.serviceType.other'),
    ],
    businessArea: [
      t('partner.businessArea.other'),
    ],
    promotionArea: [
      t('partner.promotionArea.Asia'),
    ]
  },
  {
    id: 20,
    name: '白鲸出海',
    logo: baijing,
    chUrl: `${window.location.origin}/cn-partner/docs/bai-jing-chu-hai`,
    chType: '行业媒体',
    width: '100%',
    height: 'auto',
    serviceType: [
      t('partner.serviceType.industryMedia'),
    ],
    businessArea: [
      t('partner.businessArea.other'),
    ],
    promotionArea: [
      t('partner.promotionArea.Asia'),
    ]
  },
  {
    id: 21,
    name: '七麦数据',
    logo: qimai,
    chUrl: `${window.location.origin}/cn-partner/docs/qi-mai-shu-ju`,
    chType: '数据分析平台',
    width: '100%',
    height: 'auto',
    serviceType: [
      t('partner.serviceType.dataServices'),
      t('partner.serviceType.userAcquistition'),
    ],
    businessArea: [],
    promotionArea: []
  },
  {
    id: 22,
    name: 'SolorEngine',
    logo: SolorEngine,
    enUrl: 'https://www.solar-engine.com/',
    chUrl: 'https://www.solar-engine.com/',
    enType: 'MMP',
    chType: '归因与分析',
    width: '100%',
    height: 'auto',
    serviceType: [
      t('partner.serviceType.measurementAttribution'),
    ],
    businessArea: [],
    promotionArea: []
  },
  {
    id: 23,
    name: 'BigSeller',
    logo: bigseller,
    enUrl: `${window.location.origin}/en-partner/docs/BigSeller`,
    chUrl: `${window.location.origin}/cn-partner/docs/BigSeller-t7OX`,
    enType: 'E-Commerce ERP',
    chType: '电商ERP',
    width: '100%',
    height: 'auto',
    serviceType: [
      t('partner.serviceType.userAcquistition'),
    ],
    businessArea: [],
    promotionArea: []
  },
  {
    id: 24,
    name: 'PaaSoo',
    logo: paasoo,
    enUrl: `${window.location.origin}/en-partner/docs/PaaSoo`,
    chUrl: `${window.location.origin}/cn-partner/docs/PaaSoo`,
    enType: 'Cloud Communication',
    chType: '国际短信',
    width: '100%',
    height: 'auto',
    serviceType: [
      t('partner.serviceType.cloudService'),
    ],
    businessArea: [],
    promotionArea: []
  },
  {
    id: 25,
    name: '大数跨境',
    logo: dashu,
    chUrl: `${window.location.origin}/cn-partner/docs/da-shu-kua-jing`,
    chType: '行业媒体',
    width: '100%',
    height: 'auto',
    serviceType: [
      t('partner.serviceType.industryMedia'),
    ],
    businessArea: [],
    promotionArea: []
  },
  {
    id: 26,
    name: 'AdsPower',
    logo: adspower,
    enUrl: `${window.location.origin}/en-partner/docs/AdsPower`,
    chUrl: `${window.location.origin}/cn-partner/docs/AdsPower`,
    enType: 'Browser',
    chType: '浏览器',
    width: '100%',
    height: 'auto',
    serviceType: [
      t('partner.serviceType.other'),
    ],
    businessArea: [],
    promotionArea: []
  },
  {
    id: 27,
    name: '跨境折扣网',
    logo: kuajing,
    chUrl: `${window.location.origin}/cn-partner/docs/kua-jing-zhe-kou-wang`,
    chType: '行业媒体',
    width: '100%',
    height: 'auto',
    serviceType: [
      t('partner.serviceType.industryMedia'),
    ],
    businessArea: [
      t('partner.businessArea.ecommerce'),
    ],
    promotionArea: []
  },
  {
    id: 28,
    name: 'Wintopay',
    logo: wintopay,
    chUrl: `${window.location.origin}/cn-partner/docs/Wintopay`,
    chType: '支付/收款',
    width: '100%',
    height: 'auto',
    serviceType: [
      t('partner.serviceType.payment'),
    ],
    businessArea: [],
    promotionArea: []
  },
  {
    id: 29,
    name: t('partner.name.DuoPlus'),
    logo: DuoPlus,
    chUrl: `${window.location.origin}/cn-blog/docs/guang-zhou-biao-pin-ruan-jian-you-xian-gong-si`,
    enUrl:  `${window.location.origin}/en-partner/docs/DuoPlus`,
    chType: '云服务',
    enType: 'Cloud Phone',
    width: '50%',
    height: 'auto',
    serviceType: [
      t('partner.serviceType.cloudService'),
    ],
    businessArea: [
      t('partner.businessArea.utilities'),
      t('partner.businessArea.socialNetworks'),
      t('partner.businessArea.ecommerce'),
    ],
    promotionArea: []
  },
  {
    id: 30,
    name: '比特浏览器',
    logo: BiTeLiuLanQi,
    chUrl: `${window.location.origin}/cn-blog/docs/bi-te-liu-lan-qi`,
    chType: '浏览器',
    width: '30%',
    height: 'auto',
    serviceType: [
      t('partner.serviceType.other'),
    ],
    businessArea: [
      t('partner.businessArea.socialNetworks'),
      t('partner.businessArea.ecommerce'),
      t('partner.businessArea.security'),
    ],
    promotionArea: []
  },
  {
    id: 31,
    name: '火豹浏览器',
    logo: HuoBaoLiuLanQi,
    chUrl: `${window.location.origin}/cn-blog/docs/huo-bao-liu-lan-qi`,
    chType: '浏览器',
    width: '30%',
    height: 'auto',
    serviceType: [
      t('partner.serviceType.other'),
    ],
    businessArea: [
      t('partner.businessArea.socialNetworks'),
      t('partner.businessArea.ecommerce'),
      t('partner.businessArea.security'),
    ],
    promotionArea: []
  },
  {
    id: 32,
    name: '小熊HTTP',
    logo: XiaoXiongHTTP,
    chUrl: `${window.location.origin}/cn-blog/docs/xiao-xiong-HTTP`,
    chType: '其他',
    width: '50%',
    height: 'auto',
    serviceType: [
      t('partner.serviceType.other'),
    ],
    businessArea: [
      t('partner.businessArea.other'),
    ],
    promotionArea: [
      t('partner.promotionArea.Asia'),
    ]
  },
  {
    id: 33,
    name: 'VeryFb',
    logo: VeryFb,
    chUrl: `${window.location.origin}/cn-blog/docs/VeryFb`,
    chType: '行业媒体',
    width: '50%',
    height: 'auto',
    serviceType: [
      t('partner.serviceType.industryMedia'),
    ],
    businessArea: [],
    promotionArea: []
  }
]
export {
  options,
  list
}