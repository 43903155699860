import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import quoIcon from "../../assets/images/More/quotation marks1.png";
import Brand from "../../components/common/Brand";
import IncNumber from "../../components/common/IncNumber";
import { pageList } from "./config.js";
import {screenType} from  "../../components/common/utils"
import BottomBar from "../../components/common/BottomBar";
import returnImg from '../../assets/images/More/return.svg'
import "./index.scss";
import { goLoginOrSignUp } from '@/utils/common.js';

function More(props) {
  const { t } = useTranslation();
  let { i18n } = useTranslation();
  const [indexList, setIndexList] = useState([]);
  const [type, setType] = useState("");

  useEffect(() => {
    setType(screenType());

    let name = window.location.href.split("/").pop()
    name = name.includes('?') ? name.split('?')[0] : name
    setIndexList(pageList.find((item) => t(item.name, {lng: 'en'}).toLowerCase().replace(' ', '-') === name));
  }, []);

  const toPage = (name) => () =>{
    window.location.href = `/${i18n.language}/more/${t(name, { lng: 'en' }).toLowerCase().replace(' ', '-')}`;
  }

  const handleLoginOrSignUp = () => {
    goLoginOrSignUp(i18n.language);
  }

  return (
    <div className={`More ${i18n.language}`}>
      <div className="return" onClick={() => window.location.href = `/${i18n.language}/example`}>
        <img src={returnImg} alt="" />
        <span className="font-black">{t('more.return')}</span>
      </div>
      <div className="about_wrapper">
        <div className="content_wrapper">
          <div className="about_img_wrapper">
            <img src={indexList.bgImg} alt="" />
          </div>
          <h1 className={`content ${i18n.language} ${i18n.language === 'cn' ? 'font-num': 'font-black'}`}>{t(indexList.title)}</h1>
        </div>
        <div
          className="orange"
          style={{ backgroundColor: indexList?.backColor }}
        ></div>
      </div>
      <div className="record_wrapper">
        <div className="content_wrapper">
          <div className="wrapper">
            <div className="icon_">
              <img src={quoIcon} alt="" />
            </div>
            <div className="content_">{t(indexList?.record?.text)}</div>
            <div className="footer">
              <div className="record">
                {indexList?.record?.numArr?.map((item) => {
                  return (
                    <div className="record_item" key={item.id}>
                      <IncNumber
                        text={t(item?.value)}
                        color={"blue_type"}
                        down={item.down}
                        type1={type}
                      ></IncNumber>
                      <div className="record_text">{t(item?.content)}</div>
                    </div>
                  );
                })}
              </div>
              <div className="img_wrapper">
                {indexList?.record?.imgs?.map((item, index) => {
                  return (
                    <div className="img_item" key={index}>
                      <img src={item} alt="" />
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        {/* <div className="orange_square"></div> */}
      </div>
      <div className="plan_wrapper">
        <h2 className={`title ${i18n.language === 'cn' ? 'font-num' : 'font-black'}`}>{t(indexList?.plan?.title)}</h2>
        {/* <div className="banner_wrapper"> */}
            {
            indexList?.plan?.planList?.length > 0 &&
            <div className="cards">
              {
                indexList?.plan?.planList?.map(item => (
                  <div className="card" key={item.id}>
                    <div className="title font-black">
                      <span className="font-black">{t(item.title)}</span>
                    </div>
                    <div className="text font-dinot">{t(item.text)}</div>
                  </div>
                ))
              }
            </div>
          }
        {/* </div> */}
      </div>
      <div className="game_wrapper">
        <div className="content_wrapper">
        <div className="game_img_wrapper">
            <img src={indexList?.intro?.img} alt="" />
          </div>
          <div className="content">
            <h2 className="title">{t(indexList?.about?.title)}</h2>
            <p className="font-dinot">{t(indexList?.about?.text)}</p>
          </div>
          <div className="bg"></div>
        </div>
      </div>
      <div className="example_wrapper">
        <h2 className="title1">{t(indexList?.example?.title)}</h2>
        <div className="braand_wrapper">
          {indexList?.example?.brandList.map((item, index) => {
            return <Brand item={item} key={item.id} pcType={type} toPage={toPage}></Brand>;
          })}
        </div>
      </div>
      <BottomBar type="type1" text={type === 'mobile' ? t("more.connect.mobileTitle") : t("more.connect.title")} buttonText={t("more.connect.text")} onClick={handleLoginOrSignUp}></BottomBar>
    </div>
  );
}

export default More;
