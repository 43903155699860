import cmge from "../../assets/images/More/cmge/cmge.png";
import leiting from "../../assets/images/More/leiting/leiting.png";
import eyewind from "../../assets/images/More/eyewind/eyewind.png";
import minijoy from "../../assets/images/More/minijoy/minijoy.png";
import sagi from "../../assets/images/More/sagi/sagigames.png";
import wawa from "../../assets/images/More/wawa/wawayu.png";
import eyewind1 from "../../assets/images/More/eyewind/1.png";
import eyewind2 from "../../assets/images/More/eyewind/2.png";
import eyewind3 from "../../assets/images/More/eyewind/3.png";
import eyewind4 from "../../assets/images/More/eyewind/4.png";
import eyewind5 from "../../assets/images/More/eyewind/5.png";
import eyewind6 from "../../assets/images/More/eyewind/6.png";
import leiting1 from "../../assets/images/More/leiting/1.png";
import leiting2 from "../../assets/images/More/leiting/2.png";
import leiting3 from "../../assets/images/More/leiting/3.png";
import leiting4 from "../../assets/images/More/leiting/4.png";
import leiting5 from "../../assets/images/More/leiting/5.png";
import leiting6 from "../../assets/images/More/leiting/6.png";
import minijoy1 from "../../assets/images/More/minijoy/1.png";
import minijoy2 from "../../assets/images/More/minijoy/2.png";
import minijoy3 from "../../assets/images/More/minijoy/3.png";
import minijoy4 from "../../assets/images/More/minijoy/4.png";
import minijoy5 from "../../assets/images/More/minijoy/5.png";
import minijoy6 from "../../assets/images/More/minijoy/6.png";
import sagi1 from "../../assets/images/More/sagi/1.png";
import sagi2 from "../../assets/images/More/sagi/2.png";
import sagi3 from "../../assets/images/More/sagi/3.png";
import sagi4 from "../../assets/images/More/sagi/4.png";
import sagi5 from "../../assets/images/More/sagi/5.png";
import sagi6 from "../../assets/images/More/sagi/6.png";
import wawa1 from "../../assets/images/More/wawa/1.png";
import wawa2 from "../../assets/images/More/wawa/2.png";
import wawa3 from "../../assets/images/More/wawa/3.png";
import wawa4 from "../../assets/images/More/wawa/4.png";
import wawa5 from "../../assets/images/More/wawa/5.png";
import wawa6 from "../../assets/images/More/wawa/6.png";
import cmge2 from "../../assets/images/More/cmge/2.png";
import cmge3 from "../../assets/images/More/cmge/3.png";
import cmge4 from "../../assets/images/More/cmge/4.png";
import cmge5 from "../../assets/images/More/cmge/5.png";
import cmge1 from "../../assets/images/More/cmge/1.png";
import supercent1 from "../../assets/images/More/supercent/1.png"
import supercent2 from "../../assets/images/More/supercent/2.png"
import supercent3 from "../../assets/images/More/supercent/3.png"
import supercent4 from "../../assets/images/More/supercent/4.png"
import supercent5 from "../../assets/images/More/supercent/5.png"
import supercent6 from "../../assets/images/More/supercent/6.png"
import TipTop1 from "@/assets/images/More/TipTop/1.webp"
import TipTop2 from "@/assets/images/More/TipTop/2.webp"
import TipTop3 from "@/assets/images/More/TipTop/3.webp"
import TipTop4 from "@/assets/images/More/TipTop/4.webp"
import TipTop5 from "@/assets/images/More/TipTop/5.webp"
import TipTop6 from "@/assets/images/More/TipTop/6.webp"
import TipTop from "@/assets/images/More/TipTop/tiptop1.png"
import leitingImg from "../../assets/images/More/leiting/bgImg.png";
import cmgeImg from "../../assets/images/More/cmge/bgImg.png";
import eyewindImg from "../../assets/images/More/eyewind/bgImg.png";
import minijoyImg from "../../assets/images/More/minijoy/bgImg.png";
import sagiImg from "../../assets/images/More/sagi/bgImg.png";
import wawaImg from "../../assets/images/More/wawa/bgImg.png";
import leitingMain from "../../assets/images/More/leiting/mainImg.png";
import cmgeMain from "../../assets/images/More/cmge/mainImg.png";
import eyewindMain from "../../assets/images/More/eyewind/mainImg.png";
import minijoyMain from "../../assets/images/More/minijoy/mainImg.png";
import sagiMain from "../../assets/images/More/sagi/mainImg.png";
import wawaMain from "../../assets/images/More/wawa/mainImg.png";
import superMain from "../../assets/images/More/supercent/mainImg.png"
import tantanMain from "../../assets/images/More/tantan/bgImg.png"
import ilanaMain from "@/assets/images/More/ilana/mainImg.png"
import TipTopMain from "@/assets/images/More/TipTop/bgImg.png"
import leitingContentImg from "../../assets/images/More/leiting/contentImg.png";
import cmgeContentImg from "../../assets/images/More/cmge/contentImg.png";
import eyewindContentImg from "../../assets/images/More/eyewind/contentImg.png";
import minijoyContentImg from "../../assets/images/More/minijoy/contentImg.png";
import sagiContentImg from "../../assets/images/More/sagi/contentImg.png";
import wawaContentImg from "../../assets/images/More/wawa/contentImg.png";
import supercentContentImg from "../../assets/images/More/supercent/contentImg.png";
import tantanContentImg from "../../assets/images/More/tantan/contentImg.png"
import iLnanaContentImg from "@/assets/images/More/ilana/contentImg.png"
import TipTopContentImg from "@/assets/images/More/TipTop/contentImg.png"

import goingBg from "@/assets/images/More/going/bgImg.jpg"
import goingImg from "@/assets/images/More/going/going-image.jpeg"
import going1 from "@/assets/images/More/going/1.png"
import going2 from "@/assets/images/More/going/2.png"
import going3 from "@/assets/images/More/going/3.png"
import going4 from "@/assets/images/More/going/4.png"
import going from "@/assets/images/More/going/going.png"
import gamehausImg from "@/assets/images/More/gamehaus/gamehaus-image.jpeg"
import gamehausBg from "@/assets/images/More/gamehaus/bgImg.jpg"
import gamehaus from "@/assets/images/More/gamehaus/gamehaus.png"
import gamehaus1 from "@/assets/images/More/gamehaus/1.png"
import gamehaus2 from "@/assets/images/More/gamehaus/2.png"
import gamehaus3 from "@/assets/images/More/gamehaus/3.png"
import alictusImg from "@/assets/images/More/alictus/alictus-image.jpeg"
import alictusBg from "@/assets/images/More/alictus/bgImg.jpg"
import alictus from "@/assets/images/More/alictus/alictus.png"
import alictus1 from "@/assets/images/More/alictus/1.png"
import alictus2 from "@/assets/images/More/alictus/2.png"
import alictus3 from "@/assets/images/More/alictus/3.png"
import alictus4 from "@/assets/images/More/alictus/4.png"
import alictus5 from "@/assets/images/More/alictus/5.png"

const pageList = [
  {
    id: 1,
    title: "more.leiting.title",
    name: "example.brand.item1.name",
    url:"more.leiting.url",
    bgImg: leitingMain,
    backColor: "#254FFF",
    record: {
      title: "111",
      text: "more.leiting.record.text",
      numArr: [
        {
          id: 1,
          value: "more.leiting.record.numArr.item1.value",
          icon: "",
          content: "more.leiting.record.numArr.item1.text",
          down:true
        },
        {
          id: 2,
          value: "more.leiting.record.numArr.item2.value",
          icon: "",
          content: "more.leiting.record.numArr.item2.text",
          down:false
        },
      ],
      imgs: [leiting1, leiting2, leiting3, leiting4, leiting5, leiting6],
    },
    plan: {
      title: "more.leiting.plan.title",
      planList:[
        {
          id:1,
          title:'more.leiting.plan.planList.item1.title',
          text:'more.leiting.plan.planList.item1.text',
          backColor:'#373D53',
          color:'rgb(255, 255, 255)',
          inactiveColor: 'rgba(255, 255, 255, 0.8)'
        },
        {
          id:2,
          title:'more.leiting.plan.planList.item2.title',
          text:'more.leiting.plan.planList.item2.text',
          backColor:'#254FFF',
          color:'rgb(255, 255, 255)',
          inactiveColor: 'rgba(255, 255, 255, 0.8)'
        },
        {
          id:3,
          title:'more.leiting.plan.planList.item3.title',
          text:'more.leiting.plan.planList.item3.text',
          backColor:'#FBBB05',
          color:'rgb(0, 0, 0)',
          inactiveColor: 'rgba(0, 0, 0, 0.8)'
        },
      ]
    },
    about: {
      title: "more.leiting.about.title",
      text: "more.leiting.about.text",
      bgColor:"#fbbb05"
    },
    example: {
      title: "more.leiting.example.title",
      brandList: [
        {
          id: 2,
          name: "example.brand.item2.name",
          logo: cmge,
          img: cmgeImg,
          link: "",
          value: `example.brand.item2.text`,
          type: "example.brand.item2.type",
        },
        {
          id: 3,
          name: "example.brand.item3.name",
          logo: eyewind,
          img: eyewindImg,
          link: "",
          value: `example.brand.item3.text`,
          type: "example.brand.item3.type",
        },
        {
          id: 6,
          name: "example.brand.item6.name",
          logo: sagi,
          img: sagiImg,
          link: "",
          value: `example.brand.item6.text`,
          type: "example.brand.item6.type",
        },
      ],
    },
    intro: {
      img: leitingContentImg,
    },
  },
  {
    id: 2,
    title: "more.cmge.title",
    name: "example.brand.item2.name",
    url:"more.cmge.url",
    bgImg: cmgeMain,
    record: {
      title: "111",
      text: "more.cmge.record.text",
      numArr: [
        {
          id: 1,
          value: "more.cmge.record.numArr.item1.value",
          icon: "",
          content: "more.cmge.record.numArr.item1.text",
          down:true
        },
        {
          id: 2,
          value: "more.cmge.record.numArr.item2.value",
          icon: "",
          content: "more.cmge.record.numArr.item2.text",
          down:false
        },
      ],
      imgs: [cmge1, cmge2, cmge3, cmge4, cmge5],
    },
    plan: {
      title: "more.cmge.plan.title",
      planList:[
        {
          id:1,
          title:'more.cmge.plan.planList.item1.title',
          text:'more.cmge.plan.planList.item1.text',
          backColor:'#373D53',
          color:'rgb(255, 255, 255)',
          inactiveColor: 'rgba(255, 255, 255, 0.8)'
        },
        {
          id:2,
          title:'more.cmge.plan.planList.item2.title',
          text:'more.cmge.plan.planList.item2.text',
          backColor:'#254FFF',
          color:'rgb(255, 255, 255)',
          inactiveColor: 'rgba(255, 255, 255, 0.8)'
        },
        {
          id:3,
          title:'more.cmge.plan.planList.item3.title',
          text:'more.cmge.plan.planList.item3.text',
          backColor:'#FBBB05',
          color:'rgb(0, 0, 0)',
          inactiveColor: 'rgba(0, 0, 0, 0.8)'
        },
      ]
    },
    about: {
      title: "more.cmge.about.title",
      text: "more.cmge.about.text",
      bgColor:"#fbbb05"
    },
    example: {
      title: "more.cmge.example.title",
      brandList: [
        {
          id: 1,
          name: "example.brand.item1.name",
          logo: leiting,
          img: leitingImg,
          link: "",
          value: `example.brand.item1.text`,
          type: "example.brand.item1.type",
        },
        {
          id: 5,
          name: "example.brand.item5.name",
          logo: wawa,
          img: wawaImg,
          link: "",
          value: `example.brand.item5.text`,
          type: "example.brand.item5.type",
        },
        {
          id: 4,
          name: "example.brand.item4.name",
          logo: minijoy,
          img: minijoyImg,
          link: "",
          value: `example.brand.item4.text`,
          type: "example.brand.item4.type",
        },
      ],
    },
    intro: {
      img: cmgeContentImg,
    },
  },
  {
    id: 3,
    title: "more.eyewind.title",
    name: "example.brand.item3.name",
    url:"more.eyewind.url",
    bgImg: eyewindMain,
    record: {
      title: "111",
      text: "more.eyewind.record.text",
      numArr: [
        {
          id: 1,
          value: "more.eyewind.record.numArr.item1.value",
          icon: "",
          content: "more.eyewind.record.numArr.item1.text",
        },
        {
          id: 2,
          value: "more.eyewind.record.numArr.item2.value",
          icon: "",
          content: "more.eyewind.record.numArr.item2.text",
        },
      ],
      imgs: [eyewind1, eyewind2, eyewind3, eyewind4, eyewind5, eyewind6],
    },
    plan: {
      title: "more.eyewind.plan.title",
      planList:[
        {
          id:1,
          title:'more.eyewind.plan.planList.item1.title',
          text:'more.eyewind.plan.planList.item1.text',
          backColor:'#373D53',
          color:'rgb(255, 255, 255)',
          inactiveColor: 'rgba(255, 255, 255, 0.8)'
        },
        {
          id:2,
          title:'more.eyewind.plan.planList.item2.title',
          text:'more.eyewind.plan.planList.item2.text',
          backColor:'#254FFF',
          color:'rgb(255, 255, 255)',
          inactiveColor: 'rgba(255, 255, 255, 0.8)'
        },
        {
          id:3,
          title:'more.eyewind.plan.planList.item3.title',
          text:'more.eyewind.plan.planList.item3.text',
          backColor:'#FBBB05',
          color:'rgb(0, 0, 0)',
          inactiveColor: 'rgba(0, 0, 0, 0.8)'
        },
      ]
    },
    about: {
      title: "more.eyewind.about.title",
      text: "more.eyewind.about.text",
      bgColor:"#fbbb05"
    },
    example: {
      title: "more.eyewind.example.title",
      brandList: [
        {
          id: 5,
          name: "example.brand.item5.name",
          logo: wawa,
          img: wawaImg,
          link: "",
          value: `example.brand.item5.text`,
          type: "example.brand.item5.type",
        },
        {
          id: 6,
          name: "example.brand.item6.name",
          logo: sagi,
          img: sagiImg,
          link: "",
          value: `example.brand.item6.text`,
          type: "example.brand.item6.type",
        },
        {
          id: 4,
          name: "example.brand.item4.name",
          logo: minijoy,
          img: minijoyImg,
          link: "",
          value: `example.brand.item4.text`,
          type: "example.brand.item4.type",
        },
      ],
    },
    intro: {
      img: eyewindContentImg,
    },
  },
  {
    id: 4,
    title: "more.minijoy.title",
    name: "example.brand.item4.name",
    url:"more.minijoy.url",
    bgImg: minijoyMain,
    backColor: "#254FFF",
    record: {
      title: "111",
      text: "more.minijoy.record.text",
      numArr: [
        {
          id: 1,
          value: "more.minijoy.record.numArr.item1.value",
          icon: "",
          content: "more.minijoy.record.numArr.item1.text",
          down:true
        },
        {
          id: 2,
          value: "more.minijoy.record.numArr.item2.value",
          icon: "",
          content: "more.minijoy.record.numArr.item2.text",
          down:false
        },
      ],
      imgs: [minijoy1, minijoy2, minijoy3, minijoy4, minijoy5, minijoy6],
    },
    plan: {
      title: "more.minijoy.plan.title",
      planList:[
        {
          id:1,
          title:'more.minijoy.plan.planList.item1.title',
          text:'more.minijoy.plan.planList.item1.text',
          backColor:'#373D53',
          color:'rgb(255, 255, 255)',
          inactiveColor: 'rgba(255, 255, 255, 0.8)'
        },
        {
          id:2,
          title:'more.minijoy.plan.planList.item2.title',
          text:'more.minijoy.plan.planList.item2.text',
          backColor:'#254FFF',
          color:'rgb(255, 255, 255)',
          inactiveColor: 'rgba(255, 255, 255, 0.8)'
        },
        {
          id:3,
          title:'more.minijoy.plan.planList.item3.title',
          text:'more.minijoy.plan.planList.item3.text',
          backColor:'#FBBB05',
          color:'rgb(0, 0, 0)',
          inactiveColor: 'rgba(0, 0, 0, 0.8)'
        },
      ]
    },
    about: {
      title: "more.minijoy.about.title",
      text: "more.minijoy.about.text",
      bgColor:"#fbbb05"
    },
    example: {
      title: "more.minijoy.example.title",
      brandList: [
        {
          id: 5,
          name: "example.brand.item5.name",
          logo: wawa,
          img: wawaImg,
          link: "",
          value: `example.brand.item5.text`,
          type: "example.brand.item5.type",
        },
        {
          id: 3,
          name: "example.brand.item3.name",
          logo: eyewind,
          img: eyewindImg,
          link: "",
          value: `example.brand.item3.text`,
          type: "example.brand.item3.type",
        },
        {
          id: 6,
          name: "example.brand.item6.name",
          logo: sagi,
          img: sagiImg,
          link: "",
          value: `example.brand.item6.text`,
          type: "example.brand.item6.type",
        },
      ],
    },
    intro: {
      img: minijoyContentImg,
    },
  },
  {
    id: 5,
    title: "more.wawa.title",
    name: "example.brand.item5.name",
    url:"more.wawa.url",
    bgImg: wawaMain,
    record: {
      title: "111",
      text: "more.wawa.record.text",
      numArr: [
        {
          id: 1,
          value: "more.wawa.record.numArr.item1.value",
          icon: "",
          content: "more.wawa.record.numArr.item1.text",
          down:true
        },
        {
          id: 2,
          value: "more.wawa.record.numArr.item2.value",
          icon: "",
          content: "more.wawa.record.numArr.item2.text",
          down:false
        },
      ],
      imgs: [wawa1, wawa2, wawa3, wawa4, wawa5, wawa6],
    },
    plan: {
      title: "more.wawa.plan.title",
      planList:[
        {
          id:1,
          title:'more.wawa.plan.planList.item1.title',
          text:'more.wawa.plan.planList.item1.text',
          backColor:'#373D53',
          color:'rgb(255, 255, 255)',
          inactiveColor: 'rgba(255, 255, 255, 0.8)'
        },
        {
          id:2,
          title:'more.wawa.plan.planList.item2.title',
          text:'more.wawa.plan.planList.item2.text',
          backColor:'#254FFF',
          color:'rgb(255, 255, 255)',
          inactiveColor: 'rgba(255, 255, 255, 0.8)'
        },
        {
          id:3,
          title:'more.wawa.plan.planList.item3.title',
          text:'more.wawa.plan.planList.item3.text',
          backColor:'#FBBB05',
          color:'rgb(0, 0, 0)',
          inactiveColor: 'rgba(0, 0, 0, 0.8)'
        },
      ]
    },
    about: {
      title: "more.wawa.about.title",
      text: "more.wawa.about.text",
      bgColor:"#fbbb05"
    },
    example: {
      title: "more.wawa.example.title",
      brandList: [
        {
          id: 4,
          name: "example.brand.item4.name",
          logo: minijoy,
          img: minijoyImg,
          link: "",
          value: `example.brand.item4.text`,
          type: "example.brand.item4.type",
        },
        {
          id: 3,
          name: "example.brand.item3.name",
          logo: eyewind,
          img: eyewindImg,
          link: "",
          value: `example.brand.item3.text`,
          type: "example.brand.item3.type",
        },
        {
          id: 6,
          name: "example.brand.item6.name",
          logo: sagi,
          img: sagiImg,
          link: "",
          value: `example.brand.item6.text`,
          type: "example.brand.item6.type",
        },
      ],
    },
    intro: {
      img: wawaContentImg,
    },
  },
  {
    id: 6,
    title: "more.sagi.title",
    name: "example.brand.item6.name",
    url:"more.sagi.url",
    bgImg: sagiMain,
    record: {
      title: "111",
      text: "more.sagi.record.text",
      numArr: [
        {
          id: 1,
          value: "more.sagi.record.numArr.item1.value",
          icon: "",
          content: "more.sagi.record.numArr.item1.text",
          down:true
        },
        {
          id: 2,
          value: "more.sagi.record.numArr.item2.value",
          icon: "",
          content: "more.sagi.record.numArr.item2.text",
          down:false
        },
      ],
      imgs: [sagi1, sagi2, sagi3, sagi4, sagi5, sagi6],
    },
    plan: {
      title: "more.sagi.plan.title",
      planList:[
        {
          id:1,
          title:'more.sagi.plan.planList.item1.title',
          text:'more.sagi.plan.planList.item1.text',
          backColor:'#373D53',
          color:'rgb(255, 255, 255)',
          inactiveColor: 'rgba(255, 255, 255, 0.8)'
        },
        {
          id:2,
          title:'more.sagi.plan.planList.item2.title',
          text:'more.sagi.plan.planList.item2.text',
          backColor:'#254FFF',
          color:'rgb(255, 255, 255)',
          inactiveColor: 'rgba(255, 255, 255, 0.8)'
        },
        {
          id:3,
          title:'more.sagi.plan.planList.item3.title',
          text:'more.sagi.plan.planList.item3.text',
          backColor:'#FBBB05',
          color:'rgb(0, 0, 0)',
          inactiveColor: 'rgba(0, 0, 0, 0.8)'
        },
      ]
    },
    about: {
      title: "more.sagi.about.title",
      text: "more.sagi.about.text",
      bgColor:"#fbbb05"
    },
    example: {
      title: "more.wawa.example.title",
      brandList: [
        {
          id: 5,
          name: "example.brand.item5.name",
          logo: wawa,
          img: wawaImg,
          link: "",
          value: `example.brand.item5.text`,
          type: "example.brand.item5.type",
        },
        {
          id: 4,
          name: "example.brand.item4.name",
          logo: minijoy,
          img: minijoyImg,
          link: "",
          value: `example.brand.item4.text`,
          type: "example.brand.item4.type",
        },
        {
          id: 3,
          name: "example.brand.item3.name",
          logo: eyewind,
          img: eyewindImg,
          link: "",
          value: `example.brand.item3.text`,
          type: "example.brand.item3.type",
        },
      ],
    },
    intro: {
      img: sagiContentImg,
    },
  },
  {
    id: 7,
    title: "more.supercent.title",
    name: "example.brand.item7.name",
    url:"more.supercent.url",
    bgImg: superMain,
    record: {
      title: "111",
      text: "more.supercent.record.text",
      numArr: [
        {
          id: 1,
          value: "more.supercent.record.numArr.item1.value",
          icon: "",
          content: "more.supercent.record.numArr.item1.text",
          down:false
        },
        {
          id: 2,
          value: "more.supercent.record.numArr.item2.value",
          icon: "",
          content: "more.supercent.record.numArr.item2.text",
          down:false
        },
      ],
      imgs: [supercent1, supercent2, supercent3, supercent4, supercent5, supercent6],
    },
    plan: {
      title: "more.supercent.plan.title",
      planList:[
        {
          id:1,
          title:'more.supercent.plan.planList.item1.title',
          text:'more.supercent.plan.planList.item1.text',
          backColor:'#373D53',
          color:'rgb(255, 255, 255)',
          inactiveColor: 'rgba(255, 255, 255, 0.8)'
        },
        {
          id:2,
          title:'more.supercent.plan.planList.item2.title',
          text:'more.supercent.plan.planList.item2.text',
          backColor:'#254FFF',
          color:'rgb(255, 255, 255)',
          inactiveColor: 'rgba(255, 255, 255, 0.8)'
        },
        {
          id:3,
          title:'more.supercent.plan.planList.item3.title',
          text:'more.supercent.plan.planList.item3.text',
          backColor:'#FBBB05',
          color:'rgb(0, 0, 0)',
          inactiveColor: 'rgba(0, 0, 0, 0.8)'
        },
      ]
    },
    about: {
      title: "more.supercent.about.title",
      text: "more.supercent.about.text",
      bgColor:"#ededed"
    },
    example: {
      title: "more.supercent.example.title",
      brandList: [
        {
          id: 5,
          name: "example.brand.item5.name",
          logo: wawa,
          img: wawaImg,
          link: "",
          value: `example.brand.item5.text`,
          type: "example.brand.item5.type",
        },
        {
          id: 4,
          name: "example.brand.item4.name",
          logo: minijoy,
          img: minijoyImg,
          link: "",
          value: `example.brand.item4.text`,
          type: "example.brand.item4.type",
        },
        {
          id: 3,
          name: "example.brand.item3.name",
          logo: eyewind,
          img: eyewindImg,
          link: "",
          value: `example.brand.item3.text`,
          type: "example.brand.item3.type",
        },
      ],
    },
    intro: {
      img: supercentContentImg,
    },
  },
  {
    id: 8,
    title: "more.tantan.title",
    name: "example.brand.item8.name",
    url:"more.tantan.url",
    bgImg: tantanMain,
    record: {
      title: "111",
      text: "more.tantan.record.text",
      numArr: [
        {
          id: 1,
          value: "more.tantan.record.numArr.item1.value",
          icon: "",
          content: "more.tantan.record.numArr.item1.text",
          down:true
        },
        {
          id: 2,
          value: "more.tantan.record.numArr.item2.value",
          icon: "",
          content: "more.tantan.record.numArr.item2.text",
          down:false
        },
      ],
      imgs: [],
    },
    plan: {
      title: "more.tantan.plan.title",
      planList:[
        {
          id:1,
          title:'more.tantan.plan.planList.item1.title',
          text:'more.tantan.plan.planList.item1.text',
          backColor:'#373D53',
          color:'rgb(255, 255, 255)',
          inactiveColor: 'rgba(255, 255, 255, 0.8)'
        },
        {
          id:2,
          title:'more.tantan.plan.planList.item2.title',
          text:'more.tantan.plan.planList.item2.text',
          backColor:'#254FFF',
          color:'rgb(255, 255, 255)',
          inactiveColor: 'rgba(255, 255, 255, 0.8)'
        },
        {
          id:3,
          title:'more.tantan.plan.planList.item3.title',
          text:'more.tantan.plan.planList.item3.text',
          backColor:'#FBBB05',
          color:'rgb(0, 0, 0)',
          inactiveColor: 'rgba(0, 0, 0, 0.8)'
        },
      ]
    },
    about: {
      title: "more.tantan.about.title",
      text: "more.tantan.about.text",
      bgColor:"#fbbb05"
    },
    example: {
      title: "more.tantan.example.title",
      brandList: [
        {
          id: 5,
          name: "example.brand.item5.name",
          logo: wawa,
          img: wawaImg,
          link: "",
          value: `example.brand.item5.text`,
          type: "example.brand.item5.type",
        },
        {
          id: 4,
          name: "example.brand.item4.name",
          logo: minijoy,
          img: minijoyImg,
          link: "",
          value: `example.brand.item4.text`,
          type: "example.brand.item4.type",
        },
        {
          id: 3,
          name: "example.brand.item3.name",
          logo: eyewind,
          img: eyewindImg,
          link: "",
          value: `example.brand.item3.text`,
          type: "example.brand.item3.type",
        },
      ],
    },
    intro: {
      img: tantanContentImg,
    },
  },
  {
    id: 9,
    title: "more.iLana.title",
    name: "example.brand.item9.name",
    url:"more.iLana.url",
    bgImg: ilanaMain,
    record: {
      title: "111",
      text: "more.iLana.record.text",
      numArr: [
        {
          id: 1,
          value: "more.iLana.record.numArr.item1.value",
          icon: "",
          content: "more.iLana.record.numArr.item1.text",
          down:false
        },
        {
          id: 2,
          value: "more.iLana.record.numArr.item2.value",
          icon: "",
          content: "more.iLana.record.numArr.item2.text",
          down:false
        },
      ],
      imgs: [],
    },
    plan: {
      title: "more.iLana.plan.title",
      planList:[
        {
          id:1,
          title:'more.iLana.plan.planList.item1.title',
          text:'more.iLana.plan.planList.item1.text',
          backColor:'#373D53',
          color:'rgb(255, 255, 255)',
          inactiveColor: 'rgba(255, 255, 255, 0.8)'
        },
        {
          id:2,
          title:'more.iLana.plan.planList.item2.title',
          text:'more.iLana.plan.planList.item2.text',
          backColor:'#254FFF',
          color:'rgb(255, 255, 255)',
          inactiveColor: 'rgba(255, 255, 255, 0.8)'
        },
        {
          id:3,
          title:'more.iLana.plan.planList.item3.title',
          text:'more.iLana.plan.planList.item3.text',
          backColor:'#FBBB05',
          color:'rgb(0, 0, 0)',
          inactiveColor: 'rgba(0, 0, 0, 0.8)'
        },
      ]
    },
    about: {
      title: "more.iLana.about.title",
      text: "more.iLana.about.text",
      bgColor:"#fe90ad",
      color:"#ffffff"
    },
    example: {
      title: "more.iLana.example.title",
      brandList: [
        {
          id: 5,
          name: "example.brand.item5.name",
          logo: wawa,
          img: wawaImg,
          link: "",
          value: `example.brand.item5.text`,
          type: "example.brand.item5.type",
        },
        {
          id: 4,
          name: "example.brand.item4.name",
          logo: minijoy,
          img: minijoyImg,
          link: "",
          value: `example.brand.item4.text`,
          type: "example.brand.item4.type",
        },
        {
          id: 3,
          name: "example.brand.item3.name",
          logo: eyewind,
          img: eyewindImg,
          link: "",
          value: `example.brand.item3.text`,
          type: "example.brand.item3.type",
        },
      ],
    },
    intro: {
      img: iLnanaContentImg,
    },
  },
  {
    id: 10,
    title: "more.TipTop.title",
    name: "example.brand.item10.name",
    url:"more.TipTop.url",
    bgImg: TipTopMain,
    record: {
      title: "111",
      text: "more.TipTop.record.text",
      numArr: [
        {
          id: 1,
          value: "more.TipTop.record.numArr.item1.value",
          icon: "",
          content: "more.TipTop.record.numArr.item1.text",
          down:false
        },
        {
          id: 2,
          value: "more.TipTop.record.numArr.item2.value",
          icon: "",
          content: "more.TipTop.record.numArr.item2.text",
          down:false
        },
      ],
      imgs: [TipTop1,TipTop2,TipTop3,TipTop4,TipTop5,TipTop6,],
    },
    plan: {
      title: "more.TipTop.plan.title",
      planList:[
        {
          id:1,
          title:'more.TipTop.plan.planList.item1.title',
          text:'more.TipTop.plan.planList.item1.text',
          backColor:'#373D53',
          color:'rgb(255, 255, 255)',
          inactiveColor: 'rgba(255, 255, 255, 0.8)'
        },
        {
          id:2,
          title:'more.TipTop.plan.planList.item2.title',
          text:'more.TipTop.plan.planList.item2.text',
          backColor:'#254FFF',
          color:'rgb(255, 255, 255)',
          inactiveColor: 'rgba(255, 255, 255, 0.8)'
        },
        {
          id:3,
          title:'more.TipTop.plan.planList.item3.title',
          text:'more.TipTop.plan.planList.item3.text',
          backColor:'#FBBB05',
          color:'rgb(0, 0, 0)',
          inactiveColor: 'rgba(0, 0, 0, 0.8)'
        },
      ]
    },
    about: {
      title: "more.TipTop.about.title",
      text: "more.TipTop.about.text",
      bgColor:"#FBBB05",
      color:"#ffffff"
    },
    example: {
      title: "more.TipTop.example.title",
      brandList: [
        {
          id: 5,
          name: "example.brand.item5.name",
          logo: wawa,
          img: wawaImg,
          link: "",
          value: `example.brand.item5.text`,
          type: "example.brand.item5.type",
        },
        {
          id: 4,
          name: "example.brand.item4.name",
          logo: minijoy,
          img: minijoyImg,
          link: "",
          value: `example.brand.item4.text`,
          type: "example.brand.item4.type",
        },
        {
          id: 3,
          name: "example.brand.item3.name",
          logo: eyewind,
          img: eyewindImg,
          link: "",
          value: `example.brand.item3.text`,
          type: "example.brand.item3.type",
        },
      ],
    },
    intro: {
      img: TipTopContentImg,
    },
  },
  {
    id: 11,
    title: "more.Going.title",
    name: "example.brand.item11.name",
    url:"more.Going.url",
    bgImg: goingBg,
    record: {
      title: "example.brand.item11.name",
      text: "more.Going.record.text",
      numArr: [
        {
          id: 1,
          value: "more.Going.record.numArr.item1.value",
          icon: "",
          content: "more.Going.record.numArr.item1.text",
          down:false
        },
        {
          id: 2,
          value: "more.Going.record.numArr.item2.value",
          icon: "",
          content: "more.Going.record.numArr.item2.text",
          down:false
        },
      ],
      imgs: [going1, going2,going3,going4],
    },
    plan: {
      title: "more.Going.plan.title",
      planList:[
        {
          id:1,
          title:'more.Going.plan.planList.item1.title',
          text:'more.Going.plan.planList.item1.text',
          backColor:'#373D53',
          color:'rgb(255, 255, 255)',
          inactiveColor: 'rgba(255, 255, 255, 0.8)'
        },
        {
          id:2,
          title:'more.Going.plan.planList.item2.title',
          text:'more.Going.plan.planList.item2.text',
          backColor:'#254FFF',
          color:'rgb(255, 255, 255)',
          inactiveColor: 'rgba(255, 255, 255, 0.8)'
        },
        {
          id:3,
          title:'more.Going.plan.planList.item3.title',
          text:'more.Going.plan.planList.item3.text',
          backColor:'#FBBB05',
          color:'rgb(0, 0, 0)',
          inactiveColor: 'rgba(0, 0, 0, 0.8)'
        },
      ]
    },
    about: {
      title: "more.Going.about.title",
      text: "more.Going.about.text",
      bgColor:"#FBBB05",
      color:"#ffffff"
    },
    example: {
      title: "more.Going.example.title",
      brandList: [
        {
          id: 12,
          name: "example.brand.item12.name",
          logo: gamehaus,
          img: gamehausBg,
          link: "",
          value: `example.brand.item12.text`,
          type: "example.brand.item12.type",
        },
        {
          id: 13,
          name: "example.brand.item13.name",
          logo: alictus,
          img: alictusBg,
          link: "",
          value: `example.brand.item13.text`,
          type: "example.brand.item13.type",
        },
        {
          id: 4,
          name: "example.brand.item4.name",
          logo: minijoy,
          img: minijoyImg,
          link: "",
          value: `example.brand.item4.text`,
          type: "example.brand.item4.type",
        },
      ],
    },
    intro: {
      img: goingImg,
    },
  },
  {
    id: 12,
    title: "more.Gamehaus.title",
    name: "example.brand.item12.name",
    url:"more.Gamehaus.url",
    bgImg: gamehausBg,
    record: {
      title: "example.brand.item12.name",
      text: "more.Gamehaus.record.text",
      numArr: [
        {
          id: 1,
          value: "more.Gamehaus.record.numArr.item1.value",
          icon: "",
          content: "more.Gamehaus.record.numArr.item1.text",
          down:true
        },
        {
          id: 2,
          value: "more.Gamehaus.record.numArr.item2.value",
          icon: "",
          content: "more.Gamehaus.record.numArr.item2.text",
          down:false
        },
      ],
      imgs: [gamehaus1, gamehaus2,gamehaus3],
    },
    plan: {
      title: "more.Gamehaus.plan.title",
      planList:[
        {
          id:1,
          title:'more.Gamehaus.plan.planList.item1.title',
          text:'more.Gamehaus.plan.planList.item1.text',
          backColor:'#373D53',
          color:'rgb(255, 255, 255)',
          inactiveColor: 'rgba(255, 255, 255, 0.8)'
        },
        {
          id:2,
          title:'more.Gamehaus.plan.planList.item2.title',
          text:'more.Gamehaus.plan.planList.item2.text',
          backColor:'#254FFF',
          color:'rgb(255, 255, 255)',
          inactiveColor: 'rgba(255, 255, 255, 0.8)'
        },
        {
          id:3,
          title:'more.Gamehaus.plan.planList.item3.title',
          text:'more.Gamehaus.plan.planList.item3.text',
          backColor:'#FBBB05',
          color:'rgb(0, 0, 0)',
          inactiveColor: 'rgba(0, 0, 0, 0.8)'
        },
      ]
    },
    about: {
      title: "more.Gamehaus.about.title",
      text: "more.Gamehaus.about.text",
      bgColor:"#FBBB05",
      color:"#ffffff"
    },
    example: {
      title: "more.Gamehaus.example.title",
      brandList: [
        {
          id: 13,
          name: "example.brand.item13.name",
          logo: alictus,
          img: alictusBg,
          link: "",
          value: `example.brand.item13.text`,
          type: "example.brand.item13.type",
        },
        {
          id: 11,
          name: "example.brand.item11.name",
          logo: going,
          img: goingBg,
          link: "",
          value: `example.brand.item11.text`,
          type: "example.brand.item11.type",
        },
        {
          id: 5,
          name: "example.brand.item5.name",
          logo: wawa,
          img: wawaImg,
          link: "",
          value: `example.brand.item5.text`,
          type: "example.brand.item5.type",
        },
      ],
    },
    intro: {
      img: gamehausImg,
    },
  },
  {
    id: 13,
    title: "more.Alictus.title",
    name: "example.brand.item13.name",
    url:"more.Alictus.url",
    bgImg: alictusBg,
    record: {
      title: "example.brand.item13.name",
      text: "more.Alictus.record.text",
      numArr: [
        {
          id: 1,
          value: "more.Alictus.record.numArr.item1.value",
          icon: "",
          content: "more.Alictus.record.numArr.item1.text",
          down:true
        },
        {
          id: 2,
          value: "more.Alictus.record.numArr.item2.value",
          icon: "",
          content: "more.Alictus.record.numArr.item2.text",
          down:false
        },
      ],
      imgs: [alictus1, alictus2,alictus3,alictus4,alictus5],
    },
    plan: {
      title: "more.Alictus.plan.title",
      planList:[
        {
          id:1,
          title:'more.Alictus.plan.planList.item1.title',
          text:'more.Alictus.plan.planList.item1.text',
          backColor:'#373D53',
          color:'rgb(255, 255, 255)',
          inactiveColor: 'rgba(255, 255, 255, 0.8)'
        },
        {
          id:2,
          title:'more.Alictus.plan.planList.item2.title',
          text:'more.Alictus.plan.planList.item2.text',
          backColor:'#254FFF',
          color:'rgb(255, 255, 255)',
          inactiveColor: 'rgba(255, 255, 255, 0.8)'
        },
        {
          id:3,
          title:'more.Alictus.plan.planList.item3.title',
          text:'more.Alictus.plan.planList.item3.text',
          backColor:'#FBBB05',
          color:'rgb(0, 0, 0)',
          inactiveColor: 'rgba(0, 0, 0, 0.8)'
        },
      ]
    },
    about: {
      title: "more.Alictus.about.title",
      text: "more.Alictus.about.text",
      bgColor:"#FBBB05",
      color:"#ffffff"
    },
    example: {
      title: "more.Alictus.example.title",
      brandList: [
        {
          id: 12,
          name: "example.brand.item12.name",
          logo: gamehaus,
          img: gamehausBg,
          link: "",
          value: `example.brand.item12.text`,
          type: "example.brand.item12.type",
        },
        {
          id: 11,
          name: "example.brand.item11.name",
          logo: going,
          img: goingBg,
          link: "",
          value: `example.brand.item11.text`,
          type: "example.brand.item11.type",
        },
        {
          id: 10,
          name: "example.brand.item10.name",
          logo: TipTop,
          img: TipTopMain,
          link: "",
          value: `example.brand.item10.text`,
          type: "example.brand.item10.type",
        },
      ],
    },
    intro: {
      img: alictusImg,
    },
  },

];

const planList = [
  {
    id:1,
    title:'more.plan.build.title',
    text:'more.plan.build.text',
    backColor:'#373D53',
    color:'rgb(255, 255, 255)',
    inactiveColor: 'rgba(255, 255, 255, 0.8)'
  },
  {
    id:2,
    title:'more.plan.manage.title',
    text:'more.plan.manage.text',
    backColor:'#254FFF',
    color:'rgb(255, 255, 255)',
    inactiveColor: 'rgba(255, 255, 255, 0.8)'
  },
  {
    id:3,
    title:'more.plan.record.title',
    text:'more.plan.record.text',
    backColor:'#FBBB05',
    color:'rgb(0, 0, 0)',
    inactiveColor: 'rgba(0, 0, 0, 0.8)'
  },
]

export { pageList,planList };
