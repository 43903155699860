import React from "react";
import { useTranslation } from "react-i18next";
import "./index.scss";
import FreeUse from "../FreeUse";

function Brand(props) {
  const { t } = useTranslation();
  let { i18n } = useTranslation();
  const {
    name,
    logo,
    value,
    img,
  } = props.item;
  const { pcType,mobileType } = props;

  return (
    <div
      className={["page-brand", mobileType].join(" ")}
      style={{ cursor: "pointer" }}
      onClick={props.toPage(name)}
    >
      <div className="Brand_img_wrapper">
        <img src={img} alt=""/>
      </div>
      <div className="content_wrapper">
          <div className={["brand-header", i18n.language].join(" ")}>
            <img src={logo} alt="" width="200px" height="60px"/>
          </div>
          <div className="brand-content font-dinot">{t(value)}</div>
          <div className="brand-more">
            <FreeUse
              config={{
                bgColor: "blue",
                value: "example.more",
                url: `/${i18n.language}/more/${t(name, {lng: 'en'}).toLowerCase().replace(' ', '-')}`,
                type: pcType,
                color: 'black'
              }}
            ></FreeUse>
          </div>
      </div>
    </div>
  );
}

export default Brand;
