import { isMobileByScreen } from '../../components/common/utils';
const imageContext = require.context(
    "../../assets/images/Affiliate",
    false,
    /banner.*\.(png|jpe?g|gif|svg)$/
);
const images = imageContext.keys().map(imageContext);
const content = {
  plan: {
    title: 'XMP推荐返现计划',
    text: '邀请好友成功付费，可得20%佣金返现',
    add: { button: '立即申请'}
  },
  qualification: {
    title: '推荐资格',
    list: [
      {
        image: 1,
        text: '所有自然人或代理商均有资格参与此推荐返利计划'
      },
      {
        image: 2,
        text: '确保其推荐的公司是未有XMP销售人员跟进的新用户',
      },
      {
        image: 3,
        text: '新用户必须通过该链接完成信息登记，方可视为有效推荐'
      }
    ]
  },
  amount: {
    title: '返利金额',
    text: '对于每一个通过推荐链接成功注册并购买了XMP产品的新用户，推荐人可以获得该用户的首笔合同款项的月平均成交额的20%作为现金返利',
    add: {
      p1: '例如，某公司通过合作伙伴的链接进行首次注册，并最终带来了￥100000 一年使用期的成交合作。那么最终合作伙伴可以获得返利为 : 0.2*(100000/12)=1666.7 元。',
      p2: '* 返现是一次性的，后续客户进行续费充值不会进行二次返现'
    }
  },
  time: {
    title: '返利时间',
    text: '新用户购买产品并付款成功后，推荐人的返利将在14个工作日内发放。'
  },
  join: {
    title: '加入方式',
    list: [
      `扫描${isMobileByScreen() ? '右': '下'}方二维码， 填写个人信息`,
      '等候XMP运营经理联系， 领取专属推广链接',
      '邀请好友使用您的推广链接注册， 并开通XMP套餐'
    ]
  },
  attention: {
    title: '注意事项',
    text: '如果发现任何欺诈行为，如被推荐公司已经进行注册但谎称新客户，XMP有权取消伙伴的返利资格。 ',
    add: {
      p: [
        '* XMP可能会不定期调整推荐返利计划的规则，如有变动，恕不另行通知。',
        '* XMP保留对此推荐返利计划的最终解释权。',
        '* 已推荐的好友需要在完成信息登记一个月内完成购买，否则将不被视为有效推荐。'
      ]
    }
  },
  joinUrl: 'https://ogbgxkx7jk.feishu.cn/share/base/form/shrcnPR6Rx0fuoUisPVLvsl0ncf?ccm_open_type=form_v1_link_share'
}
export {
  images,
  content
}
