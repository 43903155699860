export const AT = 'at';

export function goLoginOrSignUp(language) {
  const token = window.localStorage.getItem('at');
  const host = window.location.host;

  const lang = language === 'cn' ? 'zh-CN' : 'en-US';
  const baseSearch = window.location.search?.[0] === '?' ? window.location.search.slice(1) : '';

  // 加上 mode: 1(应用) | 2(电商)
  const modeQuery = window.location.href.includes('appsolution') ? 'mode=1' : window.location.href.includes('ecommerce') ? 'mode=2' : '';
  const extendQuery = `${modeQuery}&landing_site=official_${language}&lang=${lang}`;
  const search = !baseSearch ? extendQuery : `${baseSearch}&${extendQuery}`;
  const url = `//${host}/m/${token ? 'login' : 'sign-up'}?${search}`;

  window.open(url, '_blank');
}