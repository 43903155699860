import React from "react";
import "./index.scss";
import { useTranslation } from "react-i18next";
import SwiperCore, { Autoplay,Pagination } from "swiper";
import { Swiper, SwiperSlide} from "swiper/react";
import dashboard from "@/assets/images/Home/dashboard.svg";
import dashboardcn from "@/assets/images/Home/dashboardcn.svg";
import logo from "@/assets/images/Home/banner/logo.svg"
import reportImage from "@/assets/images/Home/banner/report.png"

SwiperCore.use([Autoplay,Pagination]);

function Carousel({type,openDialog}) {
    const { t } = useTranslation();
    let { i18n } = useTranslation();
    
    return (
      <div className={`Carousel ${i18n.language}`}>
        <div className="carousel_container">
        <Swiper
              loop
              centeredSlides={true}
              centeredSlidesBounds={true}
              speed={600}
              pagination={{
                clickable: true,
              }}
              autoplay={{
                delay: 4000,
                disableOnInteraction: false,
              }}
              modules={[Pagination]}
              noSwiping={true}
        >
            <SwiperSlide className={`first_carousel_item ${(type === 'mobile' || i18n.language === 'en') ? 'swiper-no-swiping' : ''}`}>
              <div className="main_wrapper">
                <div className="content_wrapper">
                  <h1 className="title1" dangerouslySetInnerHTML={{ __html: type === 'mobile' ? t('home.main.carousel1.mobileTitle1') : t("home.main.carousel1.title1") }}></h1>
                  <h2 className="title2 font-dinot">{t("home.main.carousel1.title2")}</h2>
                  <div className="text">
                    {t(`${type}.home.main.text`)}
                  </div>
                </div>
                <div className="btn_wrapper">
                    <button
                      onClick={() => openDialog()}
                      className={["btn font-bold button_link ", type].join(" ")}
                    >
                    {t('home.main.carousel1.freeUse')}
                    </button>
                </div>
                <div className="dashboard_wrapper">
                  <img
                    src={i18n.language === "en" ? dashboard : dashboardcn}
                    alt="dashboard"
                  />
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide className="second_carousel_item">
              <div className="main_wrapper">
                <img src={logo} alt="banner" className="banner_logo"/>
                <div className="title_wrapper">
                  <span className="year">2024</span>
                  <h1>巴西市场中轻度手游</h1>
                  <h1>洞察报告</h1>
                  <p className="generalize">市场概况丨买量大盘丨品类洞察丨买量指南</p>
                </div>
                <div className="reportImage_wrapper">
                  <img src={reportImage} alt="reportImage" />
                </div>
                <div className="btn_wrapper">
                    <a
                      target="_blank"
                      href="https://app.jingsocial.com/microFrontend/leadGeneration/jsf-leads/list/contentMarketing/TfXdKALWLyDkNYyvEMngZ7/eF49rgUUtizXaztbDTGiKF"
                      rel="noreferrer"
                      className={["btn font-bold button_link ", type].join(" ")} 
                    >
                      领取报告
                    </a>
                </div>
              </div>
            </SwiperSlide>
        </Swiper>
        </div>
      </div>
    );
  }

export default Carousel;