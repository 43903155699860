import React, { useEffect, useRef, useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import backGround from "../../assets/images/Example/bg.png";
import Brand from "../../components/common/Brand";
import { brandList } from "./config.js";
import { screenType } from '../../components/common/utils.js'
import BottomBar from "../../components/common/BottomBar";
import { goLoginOrSignUp } from '@/utils/common.js';

import "./index.scss";

function Example(props) {
  const ref1 = useRef(null);
  const { t } = useTranslation();
  let { i18n } = useTranslation();
  const [type, setType] = useState("");

  const toPage = (name) => () =>{
    window.location.href = `/${i18n.language}/more/${t(name, { lng: 'en' }).toLowerCase().replace(' ', '-')}`;
  }

  const handleLoginOrSignUp = () => {
    goLoginOrSignUp(i18n.language);
  }

  useEffect(()=>{
    setType(screenType());
  },[])

  const isInViewport1 = useIsInViewport(ref1);
  return (
    <div className={`Example ${i18n.language}`}>
      <div className="about_wrapper">
        <div className="content_wrapper">
          <h1 className={`title ${i18n.language} ${i18n.language === 'cn' ? 'font-num' : 'font-black'}`}>{t("example.title")}</h1>
          <div className="content">{t("example.text")}</div>
        </div>
        <div className="back_wrapper">
          <img src={backGround} alt="" />
        </div>
        <div className="bg_wrapper"></div>
      </div>
      <div
        ref={ref1}
        className={["brand_wrapper", isInViewport1 && "appear"].join(" ")}
      >
        {brandList.map((item, index) => {
          return <Brand item={item} key={item.id} pcType={type} toPage={toPage}></Brand>;
        })}
      </div>
      <BottomBar type="type1" text={type === 'mobile' ? t("example.connect.mobileTitle") : t("example.connect.title")} buttonText={t("example.connect.text")} onClick={handleLoginOrSignUp}></BottomBar>
    </div>
  );
}

function useIsInViewport(ref) {
  const [isIntersecting, setIsIntersecting] = useState(false);
  const observer = useMemo(() => {
    return new IntersectionObserver(([entry]) =>
      setIsIntersecting(entry.isIntersecting)
    );
  }, []);

  useEffect(() => {
    if (!isIntersecting) {
      observer.observe(ref.current);
    }

    return () => {
      observer.disconnect();
    };
  }, [ref, isIntersecting, observer]);

  return isIntersecting;
}

export default Example;
