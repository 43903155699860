import React, { useEffect, useRef, useState } from "react";
import AppSwiper from "../../components/common/AppSwiper";
import AI from "./AI";
import Build from "./Build";
import {
  tabBarList,
  leftList,
  leftList_en,
  rightList,
  rightList_en
} from "./config";
import Continuation from "./Continuation";
import "./index.scss";
import Info from "./Info";
import Manage from "./Manage";
import Record from "./Record";
import Team from "./Team";
import { useTranslation } from "react-i18next";
import { screenType, throttle } from "../../components/common/utils";
import BottomBar from "../../components/common/BottomBar";
import { goLoginOrSignUp } from '@/utils/common.js';

function Use(props) {
  const ManageRef = useRef(null);
  const BuildRef = useRef(null);
  const InfoRef = useRef(null);
  const ContinuationRef = useRef(null);
  const AIRef = useRef(null);
  const RecordRef = useRef(null);
  const TeamRef = useRef(null);

  const MainRef = useRef(null)
  const { t } = useTranslation();
  let { i18n } = useTranslation();
  const fontProportion = 0.009;
  const minFontSize = 12;
  const initFontSize = window.innerWidth * fontProportion;
  const scaleNum = initFontSize / minFontSize;
  const [activeIndex, setActiveIndex] = useState(0);
  const [isShow, setIsShow] = useState(false);
  const [type, setType] = useState("");
  const [setProportion] = useState(scaleNum > 1 ? 1 : scaleNum);
  const [setSize] = useState(initFontSize);
  const handleClick = (item) => () => {
    setActiveIndex(item.id);
    let getEle = document.getElementById(item.scrollName);
    console.log(getEle.getBoundingClientRect())
    window.scrollTo({
      top: getEle.offsetTop - 200
    })
  };

  const refsArray = [ManageRef, BuildRef, InfoRef, ContinuationRef, AIRef, RecordRef, TeamRef];

  const bindHandleScroll = throttle(() => {
    const totalHeight = [...refsArray, MainRef].reduce((a,b) => a + b.current.getBoundingClientRect().height, 0)
    if(Math.abs(MainRef.current.getBoundingClientRect().top) < totalHeight && window.scrollY > 0) {
      setIsShow(true);
    } else {
      setIsShow(false)
    }
    refsArray.forEach((ref, index) => {
      const rect = ref.current.getBoundingClientRect()
      const topInView = rect.top < window.innerHeight / 2
      const bottomInView = rect.bottom > window.innerHeight

      if(topInView && bottomInView) {
        setActiveIndex(index+1)
      }
    })
    const topPastScreen = ManageRef.current.getBoundingClientRect().top >= window.innerHeight / 2;
    const bottomPastScreen = TeamRef.current.getBoundingClientRect().bottom < window.innerHeight / 2;
    if (topPastScreen || bottomPastScreen) {
      setActiveIndex(0);
    }
  }, 300)



  //当屏幕宽度发送变化时
  const handleResize = (event) => {
    const fontSize = window.innerWidth * fontProportion;
    setSize(fontSize);
    if (fontSize < minFontSize) {
      setProportion(fontSize / minFontSize);
    } else {
      setProportion(1);
    }
  };

  useEffect(() => {
    setType(screenType());
    window.addEventListener("scroll", bindHandleScroll);
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("scroll", bindHandleScroll);
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const toNewBlank = (url) => () => {
    const w = window.open("about:blank");
    w.location.href = url;
  };

  const handleLoginOrSignUp = () => {
    goLoginOrSignUp(i18n.language);
  }

  return (
    <div className={`Use ${i18n.language}`}>
      <div className="main_container" ref={MainRef}>
        <div className="content_wraper">
          <div className="title">
            <h1 className={i18n.language === 'en' ? 'font-black' : 'font-num'}>{type === 'mobile' ? t("use.main.mobileTitle1") : t("use.main.title1")}</h1>
          </div>
          <div className="free">
             <button
                className={["free_btn font-bold button_link", type].join(" ")}
                onClick={handleLoginOrSignUp}
              >
                {t('use.main.freeUse')}
              </button>
          </div>
        </div>
        <div className="banner_wrapper">
          <div className="up"></div>
          <div className="down"></div>
          <AppSwiper
            bannerList={i18n.language === "cn" ? leftList : leftList_en}
            direction={false}
          ></AppSwiper>
          <AppSwiper
            bannerList={i18n.language === "cn" ? rightList : rightList_en}
            direction={true}
          ></AppSwiper>
        </div>

      </div>
      {isShow &&
        <div className={`tabBar ${i18n.language}`}>
          {tabBarList.map((item, index) => {
            return (
              <div
                className={`tabBar_item ${i18n.language === 'cn' ? 'font-num' : 'font-bold'} ${activeIndex === index+1 && 'tabActive'}`}
                key={item.id}
                onClick={handleClick(item)}
              >{item.name}</div>
            );
          })}
        </div>
        }

      <div id="Manage" ref={ManageRef}>
        <Manage toFree={toNewBlank} type={type} onClick={handleLoginOrSignUp}></Manage>
      </div>
      <div id="Build" ref={BuildRef}>
        <Build toFree={toNewBlank} type={type} onClick={handleLoginOrSignUp}></Build>
      </div>
      <div id="Info" ref={InfoRef}>
        <Info toFree={toNewBlank} type={type} onClick={handleLoginOrSignUp}></Info>
      </div>
      <div id="Continuation" ref={ContinuationRef}>
          <Continuation toFree={toNewBlank} type={type} onClick={handleLoginOrSignUp}></Continuation>
        </div>
      <div id="AI" ref={AIRef}>
        <AI toFree={toNewBlank} type={type} onClick={handleLoginOrSignUp}></AI>
      </div>
      <div id="Record" ref={RecordRef}>
        <Record toFree={toNewBlank} type={type} onClick={handleLoginOrSignUp}></Record>
      </div>
      <div id="Team" ref={TeamRef}>
        <Team toFree={toNewBlank} type={type} onClick={handleLoginOrSignUp}></Team>
      </div>

      <BottomBar type="type2" text={type === 'mobile' ? t("use.connect.mobileTitle") : t('use.connect.title')} buttonText={t("use.connect.freeUse")} onClick={handleLoginOrSignUp}></BottomBar>
    </div>

  );
}

export default Use;
