import i18n from "../../i18n";

const { t } = i18n
const leftList = [1,2,3].map(item => ({
  id: item,
  url: require(`../../assets/images/Use/Top/cn${item}.png`)
}))

const rightList = [4,5,6,7].map(item => ({
  id: item,
  url: require(`../../assets/images/Use/Top/cn${item}.png`)
}))
const leftList_en = [1,2,3].map(item => ({
  id: item,
  url: require(`../../assets/images/Use/Top/en${item}.png`)
}))

const rightList_en = [4,5,6,7].map(item => ({
  id: item,
  url: require(`../../assets/images/Use/Top/en${item}.png`)
}))


const tabBarList = [
  { id: 1, name: t("use.tabBar.Manage"), scrollName: "Manage" },
  { id: 2, name: t("use.tabBar.Build"), scrollName: "Build" },
  { id: 3, name: t("use.tabBar.Info"), scrollName: "Info" },
  { id: 4, name: t("use.tabBar.Continuation"), scrollName: "Continuation"},
  { id: 5, name: t("use.tabBar.AI"), scrollName: "AI" },
  { id: 6, name: t("use.tabBar.Record"), scrollName: "Record" },
  { id: 7, name: t("use.tabBar.Team"), scrollName: "Team" },
];

export { leftList, rightList, leftList_en, rightList_en, tabBarList};
