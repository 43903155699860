import React from "react";
import "./index.scss";
import FreeUse from "../../../components/common/FreeUse";
import { t } from "i18next";
import VideoPlayer from "../../../components/common/VideoPlayer";
import i18n from "../../../i18n";

function Manage(props) {
  const {type, onClick} = props;
  const list = [1,2,3].map(item => ({
    id: item,
    src: require(`../../../assets/images/Use/Manage/${item}.svg`),
    content: t(`use.manage.text${item}`)
  }))
  return (
    <div className={["Manage",i18n.language].join(" ")}>
      <div className="Manage_content_wrapper">
        <div className="content_wrapper">
        <h2 className="title">{type === 'mobile' ? t("use.manage.mobileTitle") : t("use.manage.title")}</h2>
        <div className="content">
          <div className="left_wrapper">
            <div className="text_wrapper">
              {
                list.map(item => (
                  <div className="content_item" key={item.id}>
                    <div className="img_wrapper">
                      <img src={item.src} alt="" />
                    </div>
                    <div className="text">{item.content}</div>
                  </div>
                ))
              }
            </div>
            <div className="free">
              <FreeUse
                config={{ bgColor: "blue", value: "use.manage.apply",type, onClick: onClick}}
              ></FreeUse>
            </div>
          </div>
          
        </div>
        </div>
       
        <div className="video_wrapper">
          <VideoPlayer name={"Manage"}></VideoPlayer>
          </div>
      </div>
    </div>
  );
}

export default Manage;
