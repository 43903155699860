import React, { useEffect, useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as Logo } from "../../../assets/images/Home/logo.svg";
import { screenType } from "../../common/utils";

import { ReactComponent as MyInsgram } from '@/assets/images/Footer/ins.svg'
import { ReactComponent as MyFacebook } from '@/assets/images/Footer/facebook.svg'
import "./index.scss";
import ContactUs from "../ContactUs/index.jsx";

const Footer = () => {
  const { t } = useTranslation();
  let { i18n } = useTranslation();
  const [type, setType] = useState("");
  useEffect(() => {
    setType(screenType());
  }, [setType]);

  const sitemap = useMemo(() => {
    const list = [
      {
        id: 1,
        name: t("footer.about"),
        url: `${i18n.language === 'cn' ? 'https://mobvista.cn/?utm_source=xmp' : 'https://www.mobvista.com/en/?utm_source=xmp'}`,
        default: "/",
      },
      { id: 2, name: t("footer.use"), url: `/${i18n.language}/use` },
      { id: 3, name: t("footer.example"), url: `/${i18n.language}/example` },
      { id: 4, name: t("footer.blog"), url: `/${i18n.language}-blog` },
      { id: 5, name: t("footer.partners"), url: `/${i18n.language}/partner` },
      { id: 7, name: t("footer.appsolution"), url: `/${i18n.language}/appsolution` },
    ];

    if(i18n.language === 'cn'){
      list.push({ id: 6, name: t("footer.ecommerce"), url: `/${i18n.language}/ecommerce`});
    }

    list.sort((a, b) => a.id - b.id);

    return list;
    },[t, i18n.language]
 );
  const getBrands = () => {
    const brandLinks = [
      { name: "Nativex", url: `https://www.nativex.com/${i18n.language}/?utm_source=xmp` },
      { name: "Mintegral", url: `https://www.mintegral.com/${i18n.language}/?utm_source=xmp` },
      { name: "GameAnalytics", url: "https://gameanalytics.com/?utm_source=xmp" },
      { name: "SpotMax", url: "https://gameanalytics.com/?utm_source=xmp" },
    ];
    if (i18n.language === "cn") {
      brandLinks.push(
        { name: "热云数据", url: "https://www.reyun.com/?utm_source=xmp" },
        { name: "热力引擎", url: "https://www.solar-engine.com/?utm_source=xmp" }
      );
    }
    return brandLinks.map((link) => (
      <li key={link.name}>
        <a href={link.url}>
          {link.name}
        </a>
      </li>
    ));
  };

  return (
    <div className="Footer">
      <div className="content_wrapper">
        <button className="logo_wrapper button_link">
          <Logo className="logo"  />
          <div className={["title", i18n.language].join(" ")}>
            <h4 className="font-black">{type === 'mobile' ? t("footer.mobileTitle1") : t("footer.title1")}</h4>
            <h4 className="font-black">{type === 'mobile' ? t("footer.mobileTitle2") :t("footer.title2")}</h4>
          </div>
        </button>
        <div className="right_wrapper">
          <div className={`menus ${i18n.language}`}>
            <div className="sitemap">
              <h4 className="menu_title font-black">{t("footer.sitemap")}</h4>
              <ul>
                {sitemap.map((item) => {
                  return (
                    <li key={item.id}>
                      <a
                        className="path_item"
                        href={item.url}
                      >
                        {t(item.name)}
                      </a>
                    </li>
                  );
                })}
              </ul>
            </div>
            <div className="brands">
              <h4 className="menu_title font-black">{t("footer.brand")}</h4>
              <ul>{getBrands()}</ul>
            </div>
          </div>
          <div className={`contact ${i18n.language}`}>
            {/* <button className="btn button_link">
              <span className="font-bold">{t('footer.connect')}</span>
            </button> */}
            <ContactUs />
            <div className="logos">
              <a href='https://www.linkedin.com/showcase/xmp-cross-channel-advertising-platform/' target="__blank">
                <MyInsgram />
              </a>
              <a href='https://www.facebook.com/mobvista.xmp' target="__blank">
                <MyFacebook />
              </a>
            </div>
          </div>
        </div>
      </div>
      {type === "mobile" ? (
        <div className={["mobile_list", i18n.language].join(" ")}>
          <div>Copyright 2023 Mobvista. All rights reserved.</div>
          <a
            className="privacy"
            target="_blank"
            rel="noreferrer"
            href={`/${i18n.language}/privacy`}
          >
            {t("footer.privacy")}
          </a>
          <a
            href="https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44010602003080"
            target="_blank"
            rel="noreferrer"
            className="policeNum"
          >
            粤公网安备44010602003080号
          </a>
          <a
            href="https://beian.miit.gov.cn/#/Integrated/index"
            target="_blank"
            rel="noreferrer"
            className="policeICP"
          >
            粤ICP备15042009号
          </a>
        </div>
      ) : (
        <div className={["record_wrapper", i18n.language].join(" ")}>
          <div className="hr"></div>
          <div>
            <div className="record">
              <div className="privacy">
                Copyright 2023 Mobvista. All rights reserved.
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={`/${i18n.language}/privacy`}
                >
                  {t("footer.privacy")}
                </a>
              </div>
            </div>
            <div className="police">
              <a
                href="https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44010602003080"
                target="_blank"
                rel="noreferrer"
                className="policeNum"
              >
                粤公网安备44010602003080号
              </a>
              <a
                href="https://beian.miit.gov.cn/#/Integrated/index"
                target="_blank"
                rel="noreferrer"
              >
                粤ICP备15042009号
              </a>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Footer;
