import React, { useMemo, useState } from "react";
import BuildVideoZh from "../../../assets/videos/zh/Build.mp4";
import BuildVideoEn from "../../../assets/videos/en/Build.mp4";
import AIVideoZh from "../../../assets/videos/zh/AI.mp4";
import AIVideoEn from "../../../assets/videos/en/AI.mp4";
import InfoVideoZh from "../../../assets/videos/zh/Info.mp4";
import InfoVideoEn from "../../../assets/videos/en/Info.mp4";
import RecordVideoZh from "../../../assets/videos/zh/Record.mp4";
import RecordVideoEn from "../../../assets/videos/en/Record.mp4";
import ContinuationEn from "../../../assets/videos/en/Continuation.mp4";
import TeamEn from "../../../assets/videos/en/Team.mp4";
import ContinuationZh from "../../../assets/videos/zh/Continuation.mp4"
import ManageEn from "../../../assets/videos/en/Manage.mp4"
import ManageZh from "../../../assets/videos/zh/Manage.mp4"
import TeamZh from "../../../assets/videos/zh/Team.mp4"
import { ReactComponent as Logo } from "../../../assets/images/Home/logo.svg";
import "./index.scss";
import { useTranslation } from "react-i18next";

const VideoMap = {
  en: {
    Build: BuildVideoEn,
    Info: InfoVideoEn,
    Record: RecordVideoEn,
    AI: AIVideoEn,
    Continuation: ContinuationEn,
    Manage: ManageEn,
    Team: TeamEn,
  },
  cn: {
    Build: BuildVideoZh,
    Info: InfoVideoZh,
    Record: RecordVideoZh,
    AI: AIVideoZh,
    Continuation: ContinuationZh,
    Manage: ManageZh,
    Team: TeamZh,
  }
}

const Video = (props) => {
  const { name } = props;
  const [playStatus, setPlayStatus] = useState(false);
  const [ref, setRef] = useState(null);
  let { i18n } = useTranslation();
  const videoUrl = useMemo(() => {
    return VideoMap[i18n.language][name];
  }, [i18n.language,name])

  const onPlay = () => {
    ref.play();
    setPlayStatus(false);
  };
  return (
    <div className="video_item">
      <video
        muted
        src={videoUrl}
        ref={(el) => {
          setRef(el);
        }}
        // onClick={() => {
        //   if (!playStatus) {
        //     setPlayStatus(true);
        //     ref.pause();
        //   } else {
        //     setPlayStatus(false);
        //     ref.play();
        //   }
        // }}
        width="100%"
        height="100%"
        autoPlay
        loop
        playsInline={true}
        controls={false}
      />
      {playStatus && <Play onPlay={onPlay} />}
    </div>
  );
};

const Play = (props) => {
  return (
    <div className="icon" onClick={props.onPlay}>
      <Logo className="code" />
    </div>
  );
};

export default Video;
