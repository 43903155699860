import React, { Component } from "react";
import { Translation } from "react-i18next";
import SwiperCore, { Autoplay, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import quoIcon from "../../../assets/images/Home/quotation.png";
import FreeUse from "../FreeUse";
import IncNumber from "../IncNumber";
SwiperCore.use([Autoplay, Pagination]);

export default class BrandSwiper extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
 
  render() {
    const { bannerList,type } = this.props;
    return (
      <Translation>
        {(t, {i18n}) => {
          return (
            <Swiper
              loop
              slidesPerView={type === 'mobile' ? 1.2 : 1.25}
              centeredSlides={true}
              centeredSlidesBounds={true}
              speed={400}
              pagination={{
                clickable: true,
              }}
              modules={[Pagination]}
              grabCursor
             
            >
              {bannerList?.map((item, index) => {
                return (
                  <SwiperSlide key={index}>
                    <div className="bg_wrapper">
                      <div className="content_wrapper">
                        <div className="logo">
                          <img src={item.logo} alt="" width={item.width} height={item.height}/>
                        </div>
                        <div className="content font-dinot">{item.text}
                          <img className="icon" src={quoIcon} alt="" />
                        </div>
                        <div className="count">
                          <div className="record_item">
                            <IncNumber
                              text={item.countNum1}
                              down={item.down[0]}
                              type1={type}
                            ></IncNumber>
                             <div className="text">
                              {type === 'mobile' && item.mobileText1 ? item.mobileText1 : item.countText1 }          
                            </div>
                          </div>
                          <div className="record_item">
                            <IncNumber
                              text={item.countNum2}
                              down={item.down[1]}
                              type1={type}
                            ></IncNumber>
                            <div className="text">
                            {item.countText2}      
                            </div>
                          </div>
                        </div>
                        <div className="more">
                          <FreeUse
                            config={{
                              bgColor:"blue",
                              value: t("home.option.more"),
                              onClick: () => window.location.href = `/${i18n.language}/more/${t(item.name, { lng: 'en' }).toLowerCase().replace(' ', '-')}`,
                              color: "#000",
                              type
                            }}
                          ></FreeUse>
                        </div>
                      </div>
                      { type !== 'mobile' && <img className="praise_img" src={item.img} alt="" />}
                    </div>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          );
        }}
      </Translation>
    );
  }
}
