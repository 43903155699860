import React, { useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import {getUTMParams, UTM_MATCH_CONDITION, UTM_PARAMS} from "../utils";

function MtgTracking() {
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const sample = getUTMParams();
        const urlUtmParamsStr = new URLSearchParams(sample).toString();
        const storedUtmParams = sessionStorage.getItem(UTM_PARAMS)

        if ((!storedUtmParams || storedUtmParams !== urlUtmParamsStr) && urlUtmParamsStr) {
            sessionStorage.setItem(UTM_PARAMS, urlUtmParamsStr);
        }
    }, []);

    useEffect(() => {
        const handleNavigation = () => {
            const utmParams = sessionStorage.getItem(UTM_PARAMS);

            if (UTM_MATCH_CONDITION.some(x=>utmParams === (new URLSearchParams(x.condition)).toString())) {
                navigate(`${location.pathname}?${utmParams}`);
            }
        };

      handleNavigation();

      // 在组件卸载时移除事件监听
      return () => {
        handleNavigation();
      };
    }, [location.pathname,navigate]);

    return null;
  }

export default MtgTracking;
