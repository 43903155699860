import React from "react";
import "./index.scss";
import FreeUse from "../../../components/common/FreeUse";
import { t } from "i18next";
import VideoPlayer from "../../../components/common/VideoPlayer";
import i18n from "../../../i18n";

function Record(props) {
  const {type, onClick} = props;
  const list = [1, 2, 3].map((item) => ({
    id: item,
    src: require(`../../../assets/images/Use/Record/${item}.svg`),
    content: t(`use.record.text${item}`),
  }));
  return (
    <div className={["use-record",i18n.language].join(" ")}>
      <div className="Record_content_wrapper">
        <h2 className="title">{type === 'mobile' ? t("use.record.mobileTitle") : t("use.record.title")}</h2>
        <div className="content_wrapper">
          <div className="video_wrapper">
          <VideoPlayer name={"Record"}></VideoPlayer>
          </div>
          <div className="left_wrapper">
            <div className="text_wrapper">
            {
              list.map(item => (
                  <div className="content_item" key={item.id}>
                    <div className="img_wrapper">
                      <img src={item.src} alt="" />
                    </div>
                    <div className="text">{item.content}</div>
                  </div>
                ))
              }
            </div>
            <div className="free">
              <FreeUse
                config={{ bgColor: "orange", value: "use.record.apply",type, onClick: onClick }}
              ></FreeUse>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Record;
