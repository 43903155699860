import i18n from "@/i18n"

export function throttle (fn, time) {
  let timer = null;
  return function (...args) {
    if (timer) {
      return;
    }
    timer = setTimeout(() => {
      fn.call(this, ...args);
      timer = null;
    }, time)
  }
}


export const isMobileByScreen = () => {
  return window.innerWidth < 959;
}

export const screenType = ()=> {
  return !isMobileByScreen()? 'pc' : 'mobile';
}

const getCnFreeUrl = (url) =>{
  return `https://app.jingsocial.com/mF/commonLandingPage/CTA/eccf74033e45474caa79ff918add9dc6?pushId=${url}`
}

const getEnFreeUrl = (url) =>{
  return `https://forms.gle/4r7p6sq3dUsRpHA19`
}

export const getFreeUrl = (name) =>{
  const urlValue = i18n.t(name)
  return i18n.language === 'cn'? getCnFreeUrl(urlValue) : getEnFreeUrl(urlValue)
}

export const UTM_SOURCE = 'utm_source';
export const  UTM_MEDIUM = 'utm_medium';
export const UTM_PARAMS = 'utm_params';
export const UTM_ID = 'utm_id';
export const UTM_TERM = 'utm_term';

export const UTM_MATCH_CONDITION = [
  {
    condition: {[UTM_SOURCE]: 'mintegral_adv'},
    link: i18n.t('utmLink.mtg'),
  },
  {
    condition: {
      [UTM_SOURCE]: 'partner',
      [UTM_MEDIUM]: 'adspower'
    },
    link: i18n.t('utmLink.partner_adspower'),
  },
  {
    condition: {
      [UTM_SOURCE]: 'partner',
      [UTM_MEDIUM]: 'adspower',
      [UTM_ID]: 'client',
    },
    link: i18n.t('utmLink.partner_adspower_client'),
  },
  {
    condition: {
      [UTM_SOURCE]: 'partner',
      [UTM_MEDIUM]: 'adspower',
      [UTM_ID]: 'bookmarks',
    },
    link: i18n.t('utmLink.partner_adspower_bookmarks'),
  },
  {
    condition: {
      [UTM_SOURCE]: 'bing',
      [UTM_MEDIUM]: 'cpc'
    },
    link: i18n.t('utmLink.bing_cpc'),
  },
  {
    condition: {
      [UTM_SOURCE]: '360',
      [UTM_MEDIUM]: 'cpc'
    },
    link: i18n.t('utmLink.360_cpc'),
  },
  {
    condition: {
      [UTM_SOURCE]: 'sogou',
      [UTM_MEDIUM]: 'cpc'
    },
    link: i18n.t('utmLink.sogou_cpc'),
  },
  {
    condition: {
      [UTM_SOURCE]: 'google',
      [UTM_MEDIUM]: 'cpc'
    },
    link: i18n.t('utmLink.google_cpc'),
  },
  {
    condition: {
      [UTM_SOURCE]: 'facebook',
      [UTM_MEDIUM]: 'cpc',
    },
    link: i18n.t('utmLink.facebook_cpc'),
  },
  {
    condition: {
      [UTM_SOURCE]: 'facebook',
      [UTM_MEDIUM]: 'cpc',
      [UTM_TERM]: 'ecommerce',
    },
    link: i18n.t('utmLink.facebook_cpc_ecommerce'),
  },
  {
    condition: {
      [UTM_SOURCE]: 'qimai',
      [UTM_MEDIUM]: 'cpl',
    },
    link: i18n.t('utmLink.qimai_cpl'),
  },
  {
    condition: {
      [UTM_SOURCE]: 'Insightrackr_adv'
    },
    link: i18n.t('utmLink.Insightrackr_adv')
  },
]

export const getUTMParams = () => {
  const sample = {};
  const currentURL = new URL(window.location.href);

  // 有顺序要求
  [UTM_SOURCE,  UTM_MEDIUM, UTM_ID, UTM_TERM].forEach(key => {
    const val = currentURL.searchParams.get(key);
    if (val) {
      sample[key] = val;
    }
  });
  return sample;
}

export const getUTMParamsStr = () => {
  const sample = getUTMParams();
  if (sample) {
    return (new URLSearchParams(sample)).toString();
  }
  return '';
}

export const hasUTMParams = () => {
  return !!Object.keys(getUTMParams()).length;
};

export const getUTMLink = () => {
  const params = getUTMParams();
  const matchItem = UTM_MATCH_CONDITION.find(x => Object.keys(x.condition).length === Object.keys(params).length && Object.entries(params).every(([key, value]) => x.condition[key] === value));
  return matchItem?.link;
}
