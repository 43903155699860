import "./index.scss";
import i18n from "../../../i18n";
function BottomBar(props) {
  const { type, text, buttonText, onClick } = props;

  return (
    <div className={`connect_wrapper ${type} ${i18n.language}`}>
      <div className="orange"></div>
      <div className="wrapper">
        <div className="content_wrapper">
          <h2 className={`content ${i18n.language === 'cn' ? 'font-num': 'font-black'}`} dangerouslySetInnerHTML={{ __html: text }} ></h2>
          <div className="btn font-bold f-white" onClick={onClick}>
            {buttonText}
          </div>
        </div>
        <div className="small_box"></div>
        <div className="big_box"></div>
      </div>
    </div>
  );
}

export default BottomBar;
