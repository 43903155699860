import React from "react";
import "./index.scss";
import FeatureRow from "../../../components/common/FeatureRow";
import {FeatureContentList} from '../config'

function Feature() {
  const list = [1,2,3].map(item => ({
    id: item,
    reverse: (item % 2) === 0,
    contentList: FeatureContentList[item - 1]
  }))
  return (
    <div className="EcommerceFeature">
      <div className="feature_content_wrapper">
        {list.map(item => (
          <div className="feature_content_item" key={item.id}>
            <FeatureRow
              reverse={item.reverse}
              title={item.contentList.title}
              description={item.contentList.description}
              imageUrl={item.contentList.imageSrc}
            >
              <div className="text_content">
                {item.contentList.textContent.map((item,index) => (
                  <div className="content_item" key={index}>
                    <div className="icon_wrapper">
                      <img src={item.icon} alt="Feature_icon" />
                    </div>
                    <div className="text">{item.text}</div>
                  </div>
                ))}
              </div>
            </FeatureRow>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Feature;
