import cmge from "@/assets/images/Home/PraiseList/logo/cmge.png";
import eyewind from "@/assets/images/Home/PraiseList/logo/eyewind.png";
import minijoy from "@/assets/images/Home/PraiseList/logo/minijoy.png";
import sagigames from "@/assets/images/Home/PraiseList/logo/sagigames.png";
import tantan from "@/assets/images/Home/PraiseList/logo/tantan.png";
import supercent from "@/assets/images/Home/PraiseList/logo/supercent.png";
import ilana from "@/assets/images/Home/PraiseList/logo/ilana.png";
import tiptop from "@/assets/images/Home/PraiseList/logo/tiptop.png";
import leiting from '@/assets/images/Home/PraiseList/logo/leiting.png'
import going from '@/assets/images/Home/PraiseList/logo/going.png'
import alictus from '@/assets/images/Home/PraiseList/logo/alictus.png'
import gamehaus from '@/assets/images/Home/PraiseList/logo/gamehaus.png'

import { chunk } from "lodash";
import i18n from "../../i18n";

const { t } = i18n

const praiseList = [
  {
    text: t('home.option.list.going.text'),
    countText1: t('home.option.list.going.countText1'),
    mobileText1: t('home.option.list.going.mobileCountText1'),
    countText2: t('home.option.list.going.countText2'),
    countNum1: '15%',
    countNum2: '10%',
    down: [false,false],
    img: require('../../assets/images/More/going/going-home.jpg'),
    logo: going,
    name: 'example.brand.item11.name',
    width: '220px',
    height: '80px'
  },
  {
    text: t('home.option.list.gamehus.text'),
    countText1: t('home.option.list.gamehus.countText1'),
    mobileText1: t('home.option.list.gamehus.mobileCountText1'),
    countText2: t('home.option.list.gamehus.countText2'),
    countNum1: '50%',
    countNum2: '30%',
    down: [true,false],
    img: require('../../assets/images/More/gamehaus/gamehaus-image.jpg'),
    logo: gamehaus,
    name: 'example.brand.item12.name',
    width: '220px',
    height: '80px'
  },
  {
    text: t('home.option.list.alictus.text'),
    countText1: t('home.option.list.alictus.countText1'),
    mobileText1: t('home.option.list.alictus.mobileCountText1'),
    countText2: t('home.option.list.alictus.countText2'),
    countNum1: '80%',
    countNum2: '39%',
    down: [true,false],
    img: require('../../assets/images/More/alictus/alictus-image.jpg'),
    logo: alictus,
    name: 'example.brand.item13.name',
    width: '220px',
    height: '80px'
  },
  {
    text: t('home.option.list.eyewind.text'),
    countText1: t('home.option.list.eyewind.countText1'),
    countText2: t('home.option.list.eyewind.countText2'),
    countNum1: '200%',
    countNum2: '36%',
    down: [false,false],
    img: require('../../assets/images/Home/PraiseList/eyewind.png'),
    logo: eyewind,
    name: 'example.brand.item3.name',
    width: '227px',
    height: '80px'
  },
  {
    text: t('home.option.list.supercent.text'),
    countText1: t('home.option.list.supercent.countText1'),
    countText2: t('home.option.list.supercent.countText2'),
    countNum1: '200%',
    countNum2: '33%',
    down: [false,false],
    img: require('../../assets/images/Home/PraiseList/supercent.png'),
    logo: supercent,
    name: 'example.brand.item7.name',
    width: '246px',
    height: '83px'
  },
  {
    text: t('home.option.list.sagigames.text'),
    countText1: t('home.option.list.sagigames.countText1'),
    countText2: t('home.option.list.sagigames.countText2'),
    countNum1: '75%',
    countNum2: '32%',
    down: [true,false],
    img: require('../../assets/images/Home/PraiseList/sagigames.png'),
    logo: sagigames,
    name: 'example.brand.item6.name',
    width: '220px',
    height: '82px'
  },
  {
    text: t('home.option.list.cmge.text'),
    countText1: t('home.option.list.cmge.countText1'),
    mobileText1: t('home.option.list.cmge.mobileCountText1'),
    countText2: t('home.option.list.cmge.countText2'),
    countNum1: '75%',
    countNum2: '32%',
    down: [true,false],
    img: require('../../assets/images/Home/PraiseList/cmge.png'),
    logo: cmge,
    name: 'example.brand.item2.name',
    width: '220px',
    height: '80px'
  },
  {
    text: t('home.option.list.tiptop.text'),
    countText1: t('home.option.list.tiptop.countText1'),
    countText2: t('home.option.list.tiptop.countText2'),
    countNum1: '70%',
    countNum2: '200%',
    down: [true,false],
    img: require('../../assets/images/Home/PraiseList/TipTop.png'),
    logo: tiptop,
    name: 'example.brand.item10.name',
    width: '220px',
    height: '80px'
  },
  {
    text: t('home.option.list.tantan.text'),
    countText1: t('home.option.list.tantan.countText1'),
    countText2: t('home.option.list.tantan.countText2'),
    countNum1: '95%',
    countNum2: '300%',
    down: [true,false],
    img: require('../../assets/images/Home/PraiseList/tantan.png'),
    logo: tantan,
    name: 'example.brand.item8.name',
    width: '220px',
    height: '80px'
  },
  {
    text: t('home.option.list.minijoy.text'),
    countText1: t('home.option.list.minijoy.countText1'),
    countText2: t('home.option.list.minijoy.countText2'),
    countNum1: '80%',
    countNum2: '30%',
    down: [true,false],
    img: require('../../assets/images/Home/PraiseList/minijoy.png'),
    logo: minijoy,
    name: 'example.brand.item4.name',
    width: '220px',
    height: '80px'
  },
  {
    text: t('home.option.list.ilana.text'),
    countText1: t('home.option.list.ilana.countText1'),
    countText2: t('home.option.list.ilana.countText2'),
    countNum1: '300%',
    countNum2: '40%',
    down: [false,false],
    img: require('../../assets/images/Home/PraiseList/ilana.png'),
    logo: ilana,
    name: 'example.brand.item9.name',
    width: '220px',
    height: '80px'
  },
  {
    text: t('home.option.list.leiting.text'),
    countText1: t('home.option.list.leiting.countText1'),
    mobileText1: t('home.option.list.leiting.mobileCountText1'),
    countText2: t('home.option.list.leiting.countText2'),
    countNum1: '95%',
    countNum2: '42%',
    down: [true,false],
    img: require('../../assets/images/Home/PraiseList/leiting.png'),
    logo: leiting,
    name: 'example.brand.item1.name',
    width: '220px',
    height: '80px'
  }
]
const brandList = [
  {
    title: t('home.company.section1'),
    list: [
      'google',
      'meta',
      'tiktok',
      'asa',
      'kwai',
      'huawei',
      'mtg',
      'liftoff',
      'applovin',
      'unity',
      'ironSource',
    ].map(item => `${process.env.PUBLIC_URL}/official/brandList/${item}.png`),
  },
  {
    title: t('home.company.section2'),
    list: [
      'appsflyer',
      'adjust',
      'tenjin',
      'trackingio',
      'solorEngine',
      'singular'
    ].map(item => `${process.env.PUBLIC_URL}/official/brandList/${item}.png`),
  },
  {
    title: t('home.company.section3'),
    list: [
      'max',
      'topon'
    ].map(item => `${process.env.PUBLIC_URL}/official/brandList/${item}.png`),
  },
  {
    title: t('home.company.section4'),
    list: [
      'shopify',
      'shopline',
      'shoplazza'
    ].map(item => `${process.env.PUBLIC_URL}/official/brandList/${item}.png`),
    remark: {
      // shopline: 'SHOPLINE 数据预计将于2024年接入'
    }
  } 
]

const applications_cn = Array.from({length: 7}).map((item, index) => ({
  title: t(`home.application.list.item${index+1}`),
  img: require(`../../assets/images/Home/application/${index+1}.png`)
}))

const applications_en = [5,4,3,6,2,1,7].map((item) => ({
  title: t(`home.application.list.item${item}`),
  img: require(`../../assets/images/Home/application/${item}.png`)
}))


const imageContext = require.context(
  "/public/official/companyList",
  false,
  /\.(png|jpe?g|gif|svg)$/
);
const imageFileNames = imageContext.keys().map((key) => key.replace("./", ""));

const companyLists = chunk(
  imageFileNames,
  Math.ceil(imageFileNames.length / 4)
).map((item) =>
  item.map((iitem) => `${process.env.PUBLIC_URL}/official/companyList/${iitem}`)
);

const protectList = [
  {
    text: t('home.protect.nda'),
    mobileText: t('home.protect.mobilenda'),
    img: require('../../assets/images/Home/protect/nda.png')
  },
  {
    text: t('home.protect.ccpa'),
    mobileText: t('home.protect.mobileccpa'),
    img: require('../../assets/images/Home/protect/ccpa.png')
  },
  {
    text: t('home.protect.ePrivacyseal'),
    img: require('../../assets/images/Home/protect/eprivacyseal.png')
  },
  {
    text: t('home.protect.aicpa'),
    img: require('../../assets/images/Home/protect/aicpa.png')
  }
]

export {
  companyLists,
  praiseList,
  applications_cn,
  applications_en,
  brandList,
  protectList
};
