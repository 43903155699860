import React from "react";
import "./index.scss";
import { icon1, icon2, icon3 } from "./config";
import FreeUse from "../../../components/common/FreeUse";
import { t } from "i18next";
import VideoPlayer from "../../../components/common/VideoPlayer";
import i18n from "../../../i18n";

function Team(props) {
  const {type, onClick} = props;
  return (
    <div className={["Team",i18n.language].join(" ")}>
      <div className="Team_background"></div>
      <div className="Team_content_wrapper">
        <h2 className="title">{t("use.team.title")}</h2>
        <div className="content_wrapper">
        <div className="video_wrapper">
            <VideoPlayer name={"Team"}></VideoPlayer>
            {/* <img src={teamImg} alt="" /> */}
          </div>
          <div className="left_wrapper">
            <div className="text_wrapper">
              <div className="content_item">
                <div className="img_wrapper">
                  <img src={icon1} alt="" />
                </div>
                <div className="content">
                  <div className="user">{t("use.team.item1.title")}</div>
                  <div className="user_text">
                    <div className="text">{t("use.team.item1.text1")}</div>
                  </div>
                </div>
              </div>
              <div className="content_item">
                <div className="img_wrapper">
                  <img src={icon2} alt="" />
                </div>
                <div className="content">
                  <div className="user">{t("use.team.item2.title")}</div>
                  <div className="user_text">
                    <div className="point"></div>
                    <div className="text">{t("use.team.item2.text1")}</div>
                  </div>
                  <div className="user_text">
                    <div className="point"></div>
                    <div className="text">{t("use.team.item2.text2")}</div>
                  </div>
                </div>
              </div>
              <div className="content_item">
                <div className="img_wrapper">
                  <img src={icon3} alt="" />
                </div>
                <div className="content">
                  <div className="user">{t("use.team.item3.title")}</div>
                  <div className="user_text">
                    <div className="point"></div>
                    <div className="text">{t("use.team.item3.text1")}</div>
                  </div>
                  <div className="user_text">
                    <div className="point"></div>
                    <div className="text">{t("use.team.item3.text2")}</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="free">
              <FreeUse
                config={{ bgColor: "blue", value: "use.team.apply",type, onClick: onClick }}
              ></FreeUse>
            </div>
          </div>
          
        </div>
      </div>
    </div>
  );
}

export default Team;
