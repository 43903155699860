import React from "react";
import { useTranslation } from "react-i18next";
import "./index.scss";

function FreeUse(props) {
  const { t } = useTranslation();
  const { bgColor, value, type, color, onClick } = props.config;

  return (
    <button
      className={["FreeUse button_link", bgColor,type].join(" ")}
      onClick={ onClick }
    >
      <span className="text font-bold" style={{color: color}}>{t(value)}</span>
      <div className="icon_wrapper">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6 ml-3 mr-3 md:w-10 md:h-10"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          style={{color: color}}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M14 5l7 7m0 0l-7 7m7-7H3"
          />
        </svg>
      </div>
    </button>
  );
}

export default FreeUse;
