import React from 'react';
import logo from '@/assets/images/logo.png';
import '../index.scss';

function PrivateEN(){
    return (
        <div className='PrivacyPolicy'>
            <div className="header">
                <a href="https://xmp.mobvista.com/login"><img src={logo} width="60" alt='logo'/></a>
            </div>
            <div className={"container"}>
            <div className={"content"}>
                <h2>Privacy Policy</h2>
                <p>&nbsp;</p>
                <p>Jan 11, 2022</p>
                <p>
                    XMP entities (for purpose of European data protection laws, XMP entities
                    providing XMP Service which are subject to GDPR can be viewed HERE,
                    collectively, “XMP”, or “we”, “us”, “our” ) provide this privacy policy to
                    explain how we collect, use, and share information. For the purposes of European
                    data protection laws, XMP is the data controller of your personal information.
                    This privacy policy (“Policy”) covers XMP’s handling of two types of
                    information:
                </p>
                <ol style={{ listStyleType: 'upper-alpha' }}>
                    <li>
                        Information we receive when we provide our XMP’s platform, software,
                        technology, tools or any other service (“XMP Services” or “Services”),
                        including (i) information about end user (“End User”) of mobile application
                        of our clients (“Customer”) we collect through our application programming
                        interface (“API”) or our Services from Customer, Monetization Partner and
                        Mobile Measurement Partner (“MMP”) (hereinafter together refer to as “Third
                        Party Partners”); (ii) information about End User’s interaction with
                        advertisements collect through our API or our Services, or collected and
                        transferred to us by Customer, Third Party Partners or any third party
                        designated by Customer; (iii) information generated during End User’s use of
                        Customer’s mobile apps, websites, platforms or services which is collected
                        and transferred to us by Customer, Third Party Partner or any third party
                        designated by Customer; and (iv) information provided Customer when they use
                        the Services.
                    </li>
                    <li>
                        Information we receive through our website at
                        https://xmp.mobvista.com/en,
                        https://xmp.mobvista.com/login or any other website on
                        which this Policy is posted, as well as information we get through other
                        corporate services and web portals we make available to clients and business
                        partners (“XMP Sites”, “Website”, “Site”) from XMP Website visitors
                        (“Visitors”, “you” or “your”).
                    </li>
                </ol>
                <p>
                    This Policy is subject to any service agreement between XMP and you. By using
                    the XMP Service or XMP Site, you acknowledge that you have read, understood and
                    agreed to this Privacy Policy. Please review this Privacy Policy before using
                    the XMP Service or the XMP Site.
                </p>
                <p>
                    We evaluate this Policy and procedures to implement improvements and refinements
                    from time to time. If we make material changes to this Policy we will notify you
                    by means of a prominent notice on XMP Site prior to the change becoming
                    effective.
                </p>
                <p>
                    Data protection and ensuring our business partners’ and your trust is at the
                    core of XMP’s business principles. Accordingly, being compliance with applicable
                    data protection laws is our top priority and this Policy is updated to be
                    compliance with the applicable data protection laws, including EU General Data
                    Protection Regulation (“GDPR”), the Children’s Online Privacy Protection Act
                    (“COPPA”), the California Customer Privacy Act (“CCPA”) , the Brazilian General
                    Data Protection Law (Lei Geral de Proteção de Dados Pessoais) (“LGPD”) and the
                    Chinese Personal Information Protection Law (“Chinese PIPL”).
                </p>
                <p>&nbsp;</p>
                <h3>Privacy Policy of the XMP Services</h3>
                <p>&nbsp;</p>
                <h4>1. Introduction</h4>
                <p>
                    This Policy applies to XMP’s information collection and use while: (i) Customer
                    uses or interacts with XMP Services; (ii) End User views advertisements served
                    by Third Party Partners or interact with Third Party Partners’ services in any
                    manner; or (iii) End User interacts with platforms, applications, websites or
                    other services provided by Third Party Partners. In the Privacy Policy of the
                    XMP Services, the Customer and the End User mentioned in this section are
                    collectively referred to as “User”, “you” or “your”;
                </p>
                <p>
                    The XMP Services comprise a SaaS platform which integrates top media platforms,
                    programmed advertising platform, ad exchange platform, mediation platform, MMP
                    and other third party partners into one, that enable Customer to manages its
                    user aquisiton accounts, initials UA campaigns, attributes each action and
                    analyses campaigns cross-platform with just several clicks.<span
                        className="Apple-converted-space"
                        >&nbsp;</span
                    >
                </p>
                <p>
                    The XMP Services may link to third party websites, Apps or other third party
                    content. Please note that we cannot control the treatment of a User’ information
                    by such third parties, and that the privacy practices of third parties likely
                    differ from those described in this Policy.
                </p>
                <p>&nbsp;</p>
                <h4>
                    2. How And What We Collect From User
                    <span className="Apple-converted-space">&nbsp;</span>
                </h4>
                <p>
                    The categories of personal information we collect depend on how User interacts
                    with us, the XMP Services, and the requirements of applicable law. We collect
                    information that User provides to us, information we obtain automatically when
                    you use the XMP Services, and information from other sources such as Third Party
                    Partners with User’s authorization, as described below.
                </p>
                <p>
                    We may collect the following personal information that User provides to us
                    directly or through Third Party Partners.
                </p>
                <p>
                    Account Information. We may collect information when User creates an account,
                    such as name, email address, and other relevant information User provides (e.g.,
                    third-party platform credentials).<span className="Apple-converted-space"
                        >&nbsp;</span
                    >
                </p>
                <p>
                    Transactions with XMP. We may collect personal information and details
                    associated with the transactions User enter into with XMP, including payment
                    information (if any). Any payments made via the XMP Services are processed by
                    third-party payment processors. We do not directly collect or store any payment
                    card information entered through the XMP Services, but we may receive
                    information associated with User’s payment card information.
                </p>
                <p>
                    Provision of the XMP Services. We may collect personal information of End Users
                    of User’s product, such as device ID (e.g. Android ID, IMEI or MAC), country, IP
                    address, mobile advertising identifier (e.g. Apple IDFA, IDFV or Google
                    advertising ID (OAID or GAID)), the date and time of End User’s first initial of
                    Customer’s product, for the purpose to provide the XMP Services to our
                    Customers.
                </p>
                <p>
                    User’s Communications with XMP. We may collect personal information, such as
                    email address, phone number, job title or mailing address when you request
                    information about the XMP Services, register for our blogs or newsletter,
                    request customer or technical support, or otherwise communicate with XMP.
                </p>
                <p>
                    Business Development and Strategic Partnerships. We may collect personal
                    information from individuals and third parties to assess and pursue potential
                    business opportunities.
                </p>
                <p>
                    We cooperates with reputational top media platforms, ad exchange platforms,
                    traffic supply partners, Monetization Partner and MMPs. The following privacy
                    policies linked provide you information about the above listed parties’
                    processing of User’s personal information.
                </p>
                <ul>
                    <li><a href="https://www.appsflyer.com/legal/terms-of-use/">Appsflyer</a></li>
                    <li><a href="https://www.tenjin.com/privacy/">Tenjin</a></li>
                    <li><a href="https://www.applovin.com/privacy/">Max</a></li>
                    <li><a href="https://www.umeng.com/policy">Umeng</a>&nbsp;</li>
                    <li>
                        <a href="https://www.talkingdata.com/privacy.jsp?languagetype=en"
                            >TalkingData</a
                        >
                    </li>
                    <li><a href="https://www.adjust.com/terms/privacy-policy/">Adjust</a></li>
                    <li><a href="https://policies.google.com/privacy?hl=en-US">Google</a></li>
                    <li><a href="https://www.facebook.com/about/privacy">Facebook</a></li>
                    <li>
                        <a href="https://www.tiktok.com/legal/privacy-policy-row?lang=en">Tiktok</a>
                    </li>
                    <li><a href="https://twitter.com/en/privacy">Twitter</a></li>
                    <li><a href="https://www.apple.com/legal/privacy/">Apple</a></li>
                    <li><a href="https://snap.com/en-US/privacy/privacy-policy">Snapchat</a></li>
                    <li><a href="https://www.mintegral.com/en/privacy/">Mintegral</a></li>
                    <li><a href="https://www.applovin.com/privacy/">AppLovin</a></li>
                    <li><a href="https://unity3d.com/legal/privacy-policy">Unity</a></li>
                    <li><a href="https://www.is.com/privacy-policy/">ironSource</a></li>
                    <li><a href="https://vungle.com/privacy/">Vungle</a></li>
                    <li>
                        <a
                            href="https://lf6-cdn-tos.bytescm.com/obj/ies-hotsoon-draft/account_center/e912e0b7-40d8-44f6-acfb-312f327d7685.html"
                            >Oceanengine</a
                        >
                    </li>
                    <li><a href="https://www.tencent.com/en-us/privacy-policy.html">Tencent</a></li>
                    <li><a href="https://www.kwai.com/business/legal">Kwai</a></li>
                </ul>
                <h5>About Sensitive Personal Information</h5>
                <p>
                    Sensitive Personal Information refers to personal information that, once leaked
                    or illegally used, will easily lead to infringement of the human dignity or harm
                    to the personal or property safety of a natural person. The scope of sensitive
                    personal information may vary pursuant to different applicable data protection
                    law.
                </p>
                <p>
                    We will not collect your sensitive personal information without your consent.
                    Third Party Partners will transmit or authorize us to process User’s sensitive
                    personal information after notifying User the necessity of processing the
                    sensitive personal information and the impact of User rights and interests with
                    User’s consent.
                </p>
                <h5>YouTube API Service</h5>
                <p>
                    We may use YouTube API Service when Customer purports advertising campaigns with
                    video materials. However, we may not collect any personal information of User
                    via YouTube API Service but only video materials. You can browse privacy policy
                    for YouTube API Serivce through
                    <a href="https://policies.google.com/privacy">
                        <u>https://policies.google.com/privacy</u></a
                    > and <a href="https://www.youtube.com/t/terms">
                        <u>https://www.youtube.com/t/terms</u></a
                    > By using XMP, users are agreeing to be bound by the <a href="https://www.youtube.com/t/terms">YouTube Terms of Service</a>. User can revoke our access to User’s data (if any) via the Google security
                    settings page at
                    <a href="https://security.google.com/settings/security/permissions"
                        >https://security.google.com/settings/security/permissions</a
                    >.
                </p>
                <p>
                    We will not store all authorized data retrieved or provided through YouTube API
                    Service for more than 30 days unless authorizations or consents are provided by
                    Users every 30 days.
                </p>
                <p>&nbsp;</p>
                <h4>3. How We Use Users’ Personal Information</h4>
                <p>
                    User’s device might be distinguished from other devices based on information it
                    automatically send, such as IP address. User’s device may be identified based on
                    a scan of User’s device’s unique combination of characteristics. Identifier for
                    Advertiser or other information may be stored or accessed on User’s device for
                    purposes presented under this Policy. User’s device can receive and send
                    information that allows User to see and interact with ads and content. We use
                    User’s personal information to provide the XMP Services (e.g. attribution
                    information management and campaign analysis) to our Customer. Where required,
                    our Customer or Third Party Partners will obtain User’s consent for and on our
                    behalf for our processing of User personal information for the purposes herein.
                    User can withdraw their consents at any time.
                </p>
                <p>
                    Where necessary in our legitimate interests we use User’s personal information:
                    (i) to provide the XPM Serives, including but not limited to measuring the
                    performance and effectiveness of ads that End User sees or interacts with and
                    reporting the campaign information in order to perform our contractual
                    obligations to our Customer; (ii) to understand and analyze User’s usage trends
                    and preferences, improve the XPM Services, and to develop new products; (iii)
                    for purpose of ensuring systems and processes work properly and securely.
                </p>
                <p>
                    We may also collect and use personal information of Users who are representing
                    our Customer to use the XMP Services (e.g. employees of mobile app developer or
                    advertisers) for purpose of business contact and direct marking (e.g. sending
                    marketing emails). Where required, our Customer will obtain such User’s consent
                    for and on our behalf for our processing of their personal information.
                </p>
                <p>
                    We may also use personal information about Users to comply with applicable laws,
                    regulations or codes of practice, and to protect our legitimate interest and
                    protect the rights or property of us or any other third parties.
                </p>
                <p>&nbsp;</p>
                <h4>4. Disclosure of User’s Personal Information</h4>
                <p>
                    Subject to compliance with applicable laws and regulations, we may disclose
                    User’s personal information to any third party other than XMP’s affiliated
                    companies.
                </p>
                <p>
                    We may disclose the End User’s personal information to our Customer, to report
                    the measurement of the performance and effectiveness of ads that End User sees
                    or interacts with, including measuring and reporting the traffic quality in
                    order to perform our contractual obligations to our Customer, and to prevent
                    fraudulent activity. The personal information we disclose is personal
                    information related to the performance and effectiveness of advertisement, such
                    as device information, information of click or impression. Customer may use the
                    End User’s personal information to measure End User’s ad/site interaction and
                    evaluate the performance and effectiveness of ads that End User sees or
                    interacts with, identify End User’s interest areas, better understand the site
                    and app traffic usage or End User behavior in order to improve their services.
                    Customer’s use of the information that we disclose to them will be governed by
                    their privacy policies.
                </p>
                <p>
                    We may also use third party technical service providers (e.g. cloud service
                    provider) to process the User’s personal information for purpose of ensuring
                    systems and processes work properly and securely, and for purpose of data
                    storage.
                </p>
                <p>
                    According to the Chinese PIPL, we will not disclose User’s personal information
                    to any third party other than XMP’s affiliated company without User’s consent,
                    except for those information that does not identify an individual and could not
                    be recovered. About the name, contact information and other information of our
                    Customer and any Third Party Partners, Chinese User can inquire via
                    privacy@mobvista.com.
                </p>
                <p>
                    We may also share aggregate or anonymous information with other third parties in
                    accordance with the terms of this Policy.
                </p>
                <p>
                    Our affiliates may also have access to User’s personal information for the
                    purposes set out in this Privacy Policy.
                </p>
                <p>
                    We may share User’s personal information as required or permitted by law to
                    comply with a subpoena or similar legal process or government request, or when
                    we believe in good faith that disclosure is legally required or otherwise
                    necessary to protect our rights and property, or the rights, property or safety
                    of others, including to advisers, law enforcement agencies, judicial and
                    regulatory authorities. We may also transfer User’s personal information to a
                    third party that acquires all or part of our assets or shares, or that succeeds
                    us in carrying on all or a part of our business, whether by merger, acquisition,
                    reorganisation or otherwise.
                </p>
                <p>&nbsp;</p>
                <h4>5. International Transfers</h4>
                <p>
                    When User is located in the European Econocim Area (“EEA”), personal information
                    collected about you will be transferred to recipients in countries located
                    outside the EEA or can be remotely accessed in countries located outside the
                    EEA, including in countries which may not be decided by the European Commission
                    that it provides an adequate level of protection to personal information. We
                    have followed requirements as set out in applicable law to provide adequate
                    protection to User’s personal data. To the extent as required by applicable law,
                    our Customer will obtain your valid consent to the extent for us to transfer
                    User’s personal information to recipients including XMP’s affiliates and any
                    other recipients as described in this Privacy Policy, which are located outside
                    the EEA. User is entitled to withdraw it’s consent at any time.
                </p>
                <p>
                    XMP has entered into EU standard contractual clauses (“Model Contracts”) for
                    intra-group transfers of personal information to its affiliates. User can
                    request a copy of these Model Contracts by submitting request via email to
                    privacy@mobvista.com.
                </p>
                <p>
                    If User is located in the mainland areas of China, we store User’s personal
                    information inside the mainland areas of China. Where it is necessary to provide
                    User’s personal information to recipient outside mainland areas of China, we
                    will transfer User’s personal information according to the requirement of any
                    applicable data protection law.
                </p>
                <p>&nbsp;</p>
                <h4>6. Your Data Rights
                </h4>
                <p>
                    If User is in the EEA, User has a right to (subject to a limited number of
                    exceptions as permitted by GDPR): (i) request access to and rectification or
                    erasure of User’s personal information; (ii) obtain restriction of processing or
                    to object to processing of User’s personal information; and (iii) ask for a copy
                    of User’s personal information to be provided to them, or a third party, in a
                    digital format. User also has the right to lodge a complaint about the
                    processing of User’s personal information with User’s local data protection
                    authority in the EEA.
                </p>
                <p>
                    If User is resident of the State of California in the United States, effective
                    from January 1, 2020, User can request the following information about how we
                    have collected and used your personal information during the past 12 months:
                </p>
                <ol>
                    <li>The categories of personal information we have collected.</li>
                    <li>The categories of sources from which we collected the personal i</li>
                    <li>
                        The business or commercial purpose for collecting and/or selling personal i
                    </li>
                    <li>The categories of third parties with whom we share the personal i</li>
                    <li>
                        The categories of personal information that we sold or disclosed for a
                        business purpose.
                    </li>
                    <li>
                        The categories of third parties to whom the personal information was sold or
                        disclosed for a business purpose.
                    </li>
                    <li>
                        User can request a copy of the personal information that we have
                        collectedduring the past 12 months.
                    </li>
                    <li>
                        User can ask us to delete the personal information that we have collected.
                    </li>
                    <li>
                        User isentitled to exercise the rights described above free from
                        discrimination as prohibited by the CCPA. User may submit requests to
                        exercise your California privacy rights described above by writing to us
                        at: <a href="mailto:privacy@mobvista.com">privacy@mobvista.com</a>and (ii)
                        opt out of selling of your data by clicking on the following link: <a
                            href="mailto:privacy@mobvista.com"
                            >DO NOT SELL MY DATA</a
                        >. User can provide opt-in consent for sale of personal information about
                        them by writing email to us at <a
                            href="mailto:privacy@mobvista.com"
                            >privacy@mobvista.com.</a
                        >
                    </li>
                </ol>
                <p>
                    These rights are not absolute, and in some instances, we may decline User’s
                    request as permitted by law. We will need to verify User’s identity to process
                    User’s information, access, and deletion requests and reserve the right to
                    confirm User’s California residency.
                </p>
                <p>
                    If User is in the mainland areas of China, User has the following rights related
                    to personal information except when laws and administrative regulations
                    otherwise provide.
                </p>
                <ol>
                    <li>
                        Right to be informed, right to decide and right to restrict or refuse
                        processing of User’s personal information.
                    </li>
                    <li>
                        Right to access and copy User’s personal information except in special
                        circumstance provided by law.
                    </li>
                    <li>
                        Right to request to transfer User’s personal information to another data
                        handler.
                    </li>
                    <li>
                        Right to request to revise and supplement User’s personal information when
                        User notices that the personal information we process is inaccurate or
                        incomplete.
                    </li>
                    <li>
                        Right to delete User’s personal information in the following circumstances：
                    </li>
                </ol>
                <ol style={{ listStyleType: 'lower-alpha', marginLeft: '2rem' }}>
                    <li>
                        Where the purpose of processing has been achieved or is unable to be
                        achieved, or the personal information is no longer necessary for achieving
                        the purpose of processing;
                    </li>
                    <li>
                        Where XMP lceases the provision of its product or service involved, or the
                        retention period has expired;
                    </li>
                    <li>Where consent is withdrawn by the User;</li>
                    <li>
                        Where the processing of personal information by XMP is in violation of any
                        law, administrative regulations or any agreement; or
                    </li>
                    <li>
                        Any other circumstance as provided by law or administrative regulations.
                    </li>
                </ol>
                <p>
                    If it is technically difficult to delete the personal information, XMP shall
                    cease the processing of the personal information, except for the storage and any
                    necessary measure taken for security protection.
                </p>
                <p>
                    User can contact us via <a href="mailto:privacy@mobvista.com"
                        ><u>privacy@</u><u>mobvista</u><u>.com</u></a
                    > and request your data rights.
                </p>
                <p>&nbsp;</p>
                <h4>7. Security of User’s Personal Information</h4>
                <p>
                    The security of User’s information is our utmost priority, and we take strict
                    measures in order to protect our data from unauthorized access, use, disclosure
                    or destruction of data. We have implemented physical, technical and
                    administrative security measures for the XMP Services that comply with
                    applicable laws and industry standards. For example, we use firewalls,
                    encryption technology and other automated software designed to protect against
                    fraud and identity theft; our data is only stored in centers that provide
                    high-level security for User’s information. Physical access is strictly
                    controlled both at the perimeter and at building ingress points by our staff
                    utilizing video surveillance and other electronic means.
                </p>
                <p>
                    We also protect User’s privacy by seeking to minimize the amount of sensitive
                    data that we store on our servers in the first place. We also seek appropriate
                    contractual protection from our partners regarding their treatment of user data.
                </p>
                <p>
                    Generally, XMP will retain the information for one (1) year unless any
                    applicable law requires otherwise. The criteria used to determine the retention
                    periods include: (i) how long the personal data is needed to provide the
                    services and operate the business; (ii) the type of personal data collected; and
                    (iii) whether we are subject to a legal, contractual or similar obligation to
                    retain the data (e.g., mandatory data retention laws, government orders to
                    preserve data relevant to an investigation, or data that must be retained for
                    the purposes of litigation or disputes).
                </p>
                <p>&nbsp;</p>
                <h4>8. Protection for Minors
                </h4>
                <p>
                    The XPM Services are not mainly for minors. If a User is deemed as minor under
                    applicable law, please make sure that before the use of the application which
                    integrates XMP’s product or service or the application of an advertiser that
                    partners with XMP, parents or other guardian and such User have together read
                    the application’s privacy policy with the guardianship and guiding of parents or
                    other guardian, and their consent have been provided for processing of personal
                    information.
                </p>
                <p>
                    We process minor’s personal information according to requirements of related
                    laws and regulations. Except as otherwise provided by law, we will only collect,
                    use, store, disclose, or make available to the public the personal information
                    where permitted by law, with consent of parents or other guardians, or for the
                    necessity of protection of the minors. If we notice the collection of personal
                    information of minors without verifiable consent of parents, we will take
                    measures to delete related information as soon as possible.
                </p>
                <p>
                    If you are parents or other guardian of minors, please contact us via the email
                    we provide in this Privacy Policy when you have any question regarding to the
                    protection of minors.
                </p>
                <p>
                    If you are Users in the U.S., we ensure that we comply with the Children’s
                    Online Privacy Protection Act (COPPA).  If a parent or guardian becomes aware
                    that his or her child has provided us with information without their consent, he
                    or she should promptly contact us and we take reasonable steps to ensure that
                    such information is deleted from our files.
                </p>
                <p>&nbsp;</p>
                <h4>9. Marketing
                </h4>
                <p>
                    We may contact you periodically by e-mail to provide information regarding our
                    products, services and content that may be of interest to you. If applicable law
                    requires that we receive your consent before we send you certain types of
                    marketing communications, we will only send you those types of communications
                    after receiving your consent.
                </p>
                <p>
                    If you decide at any time that you no longer wish to receive such communications
                    from us, you may follow the unsubscribe instructions provided in any of the
                    communications or request to be opted-out of receiving such communications via
                    email to <u>privacy@</u><u>mobvista</u><u>.com</u>.
                </p>
                <h3>&nbsp;</h3>
                <h3>Privacy Policy of XMP Website</h3>
                <p>&nbsp;</p>
                <h4>1. Introduction</h4>
                <p>
                    This Privacy Policy of XMP Website applies to information collection and use
                    including while you are visiting and using the XMP Site. For the purposes of
                    GDPR, XMP is the data controller of your personal
                    information.
                </p>
                <p>&nbsp;</p>
                <h4>2. What Information We Collect</h4>
                <p>
                    As you navigate through this XMP Site, some of your personal information may be
                    actively collected (for example, names, birth dates, personal ID card numbers,
                    addresses, telephone numbers, E-mail addresses, occupations, education, work
                    experience and related information), or passively collected (which you did not
                    actively provide) such as your browser type, domain name, IP address, pages
                    visited, and length of time that you spend browsing the website using various technologies and
                    means, such as Internet Protocol Address, cookies, Internet Tags and
                    navigational data collection. In order to improve our service quality, it may be
                    necessary to ask for personal information to fulfil your requests and provide a
                    better service. XMP is entitled to take measures to validate the authenticity of
                    personal information you provide. If you give us personal information of
                    somebody else, you agree and warrant that you have their permission to do so.
                </p>
                <p>&nbsp;</p>
                <h4>3. How We Use Your Information</h4>
                <p>
                    The legal basis of our processing of your personal information is your consent.
                </p>
                <p>
                    The provision of personal information by you may be used for where in our
                    legitimate interest for the following purposes: (i) to provide you with XMP
                    products or services or information in relation to the same; (ii) to sign you up
                    for an event or training; (iii) send you marketing information about us or our affiliates;
                     (iv) for business administration, including statistical analysis; (v)
                    to personalize your visit to the XMP Site and to assist you while you use the
                    Site; or (vi) to improve the XMP Site by helping us understand who uses the
                    Site.
                </p>
                <p>
                    We may also contact you periodically by e-mails to provide information regarding
                    our products, services and content that may be of interest to you. If applicable
                    law requires that we receive your consent before we send you certain types of
                    marketing communications, we will only send you those types of communications
                    after receiving your consent.
                </p>
                <p>
                    If you decide at any time that you no longer wish to receive such communications
                    from us, you may follow the unsubscribe instructions provided in any of the
                    communications or request to be opted-out of receiving such communications via
                    email to <u>privacy@</u><u>mobvista</u><u>.com</u>.
                </p>
                <p>&nbsp;</p>
                <h4>4. Disclosure of Your Personal Information</h4>
                <p>
                    We may employ third parties to provide services to us and to assist in the hosting and operation of the XMP website,including
                    <a href="https://xmp.mobvista.com/official/one-pager/Third-party+Technology+Service+Providers.pdf">our cloud server provider</a>,
                    web analytics provider (as detailed in our
                    <a href="#Cookies Policy">Cookie Policy</a>
                    ), and
                    <a href="https://xmp.mobvista.com/official/one-pager/Third-party+Technology+Service+Providers.pdf">email service provider</a>
                    ,
                    and these third parties may have access to your personal information.
                    We may also share your personal information with our affiliates for the purposes set forth in this Privacy Policy.
                </p>
                <p>
                    We also employ a third-party business data service provider ("AroundDeal") to provide us with corporate contact services
                    for the purpose of sending marketing messages to visitors to the Site.
                    With your expressly authorized consent, we will transmit your IP address,
                    time of page visit, and link to the page visited to AroundDeal, match the information through
                    AroundDeal's database of business contacts, and based on the matching results, AroundDeal may provide us with your name,
                    email, job title, location, company name, company industry, and Linkin account number,
                    and send you marketing messages on our behalf.
                    Information about AroundDeal and its privacy policy can be found
                    <a href="https://www.arounddeal.com/privacy-center/privacy-policy">here</a>.
                </p>
                <p>
                    If we believe that you intend or have abused or damaged the XMP website, we will investigate
                    and cooperate with relevant law enforcement authorities to protect our legitimate rights and interests.
                </p>
                <p>
                    At the same time, we may share your personal information as required or permitted by law to comply
                    with a subpoena or similar legal process or government request, or when we believe in good faith
                    that disclosure is legally required or otherwise necessary to protect our rights and property, or
                    the rights, property or safety of others, including to advisers, law enforcement agencies, judicial
                    and regulatory authorities. We may also transfer your personal information to a third party that
                    acquires all or part of our assets or shares, or that succeeds us in carrying on all or a part of
                    our business, whether by merger,acquisition, reorganization or otherwise.
                </p>
                <p>&nbsp;</p>
                <h4>5. International Transfers</h4>
                <p>
                    When you are located in the mainland areas of China, we store your personal
                    information inside the mainland areas of China. Where it is necessary to provide
                    your personal information to recipient outside mainland areas of China, we will
                    transfer your personal information according to the requirement of any
                    applicable data protection law.
                </p>
                <p>
                    When you are located in the European Econocim Area (“EEA”), personal information
                    collected about you will be transferred to recipients in countries located
                    outside the EEA or can be remotely accessed in countries located outside the
                    EEA, including in countries which may not be decided by the European Commission
                    that it provides an adequate level of protection to personal information. We
                    have followed requirements as set out in applicable law to provide adequate
                    protection to your personal data. To the extent as required by applicable law,
                    our business partner will obtain your valid consent to the extent for us to
                    transfer your personal information to recipients including XMP’s affiliates and
                    any other recipients as described in this Privacy Policy, which are located
                    outside the EEA. You are entitled to withdraw your consent at any time.
                </p>
                <p>
                    XMP has entered into EU standard contractual clauses (“Model Contracts”) for
                    intra-group transfers of personal information to its affiliates. You can request
                    a copy of these Model Contracts by submitting your request via email to
                    privacy@mobvista.com.For the transfer of Personal Information to AroundDeal,
                    XMP has entered into an EU Standard Contract Clause with AroundDeal (" AroundDeal Standard Contract ")
                    for the transfer of Personal information to AroundDeal. You can request a copy of your AroundDeal standard contract by sending an email to privacy@mobvista.com.
                </p>
                <p>
                    When you are located in the mainland areas of China, we store your personal information inside the mainland areas of China.
                    Where it is necessary to provide your personal information to recipient outside mainland areas of China,
                    we will transfer your personal information according to the requirement of any applicable data protection law.
                </p>
                <p>&nbsp;</p>
                <h4>6. Your Data Rights</h4>
                <p>
                    You have the right to access, modify, correct or delete any personal data which
                    we may have collected. To exercise this right please contact
                    privacy@mobvista.com.
                </p>
                <p>
                    If you are in the EEA, you have a right to (subject to a limited number of
                    exceptions): (i) request access to and rectification or erasure of your personal
                    information; (ii) obtain restriction of processing or to object to processing of
                    your personal information; and (iii) ask for a copy of your personal information
                    to be provided to them, or a third party, in a digital format. You also have the
                    right to lodge a complaint about the processing of your personal information
                    with your local data protection authority in the EEA.
                </p>
                <p>
                    If you are a resident of the State of California in the United States,
                     you may ask us about our collection and use of your personal data in the past 12 months, specifically:
                </p>
                <ol>
                    <li>The categories of Personal Information we have collected.</li>
                    <li>
                        The categories of sources from which we collected the Personal Information.
                    </li>
                    <li>
                        The business or commercial purpose for collecting and/or selling Personal
                        Information.
                    </li>
                    <li>
                        The categories of third parties with whom we share the Personal Information.
                    </li>
                    <li>
                        The categories of Personal Information that we sold or disclosed for a
                        business purpose.
                    </li>
                    <li>
                        Your right: You can request a copy of the Personal Information that we have
                        collected about you during the past 12 months.
                    </li>
                    <li>
                        You can ask us to delete the Personal Information that we have collected
                        from you.
                    </li>
                    <li>
                        You can ask us to correct your personal information.
                    </li>
                </ol>
                <p>
                    You are entitled to exercise the rights described above free from discrimination as prohibited by the CCPA.
                </p>
                <p>
                    You may (i) exercise your California privacy rights above by sending a written request to privacy@mobvista.com
                    or by sending a written document to the following address: Department of Law, Room 4312, 222 Xingmin Road,
                    Tianhe District, Guangzhou, Guangdong, China;(ii) request to restrict the use of your sensitive Personal
                    Information by sending a written request to privacy@mobvista.com.
                </p>
                <p>
                    For the avoidance of doubt, we do not sell or share (as defined by the CCPA) your personal information.
                </p>
                <p>
                    If you are a resident of the State of Virginia in the United States,
                    as of January 1, 2023, you may exercise the following personal information rights with us:
                </p>
                    <p>
                        -Check whether we are processing your personal information and access it;
                    </p>
                    <p>
                        -Correct inaccuracies in your personal information;
                    </p>
                    <p>
                        -delete your personal information;
                    </p>
                    <p>
                        -obtain a copy of the personal information you have provided to us; and
                    </p>
                    <p>
                        -request a refusal to allow us to process your Personal Information for the purposes of
                        (i) personalized advertising, (ii) the sale of your Personal Information, or (iii) user
                        profiling that could have a significant legal or similar impact on you.
                    </p>
                <p>
                    You have the right to exercise the above rights without discrimination, as set forth in the VCDPA.
                    You may exercise your Virginia Personal Information rights described above by sending a written request to privacy@mobvista.com.
                    If you do not provide effective information for us to provide effective feedback, we may refuse to exercise your rights.
                    If you have different opinions, you can further send a written complaint to privacy@mobvista.com.
                </p>
                <p>
                    For the avoidance of doubt, we will not sell your personal information or use your personal information for personalized advertising or user profiling.
                </p>
                <p>&nbsp;</p>
                <p>
                    If you are a resident of the mainland of the People's Republic of China, you have the following rights related
                    to your personal information except when laws and administrative regulations
                    otherwise provide.
                </p>
                <p>
                    (1) Right to be informed, right to decide and right to restrict or refuse
                    processing of your personal information.
                </p>
                <p>
                    (2) Right to access and copy your personal information except in special
                    circumstance provided by law.
                </p>
                <p>
                    (3) Right to request to transfer your personal information to another data
                    handler.
                </p>
                <p>
                    (4) Right to request to revise and supplement your personal information when you
                    notice that the personal information we process is inaccurate or incomplete.
                </p>
                <p>
                    (5) Right to delete your personal information in the following circumstances：
                </p>
                <div style={{ marginLeft: '2rem' }}>
                    <p>
                        ① Where the purpose of processing has been achieved or is unable to be
                        achieved, or the personal information is no longer necessary for achieving
                        the purpose of processing;
                    </p>
                    <p>
                        ② Where XMP ceases the provision of its product or service involved, or the
                        retention period has expired;
                    </p>
                    <p>③ Where consent is withdrawn by the User;</p>
                    <p>
                        ④ Where the processing of personal information by XMP is in violation of any
                        law, administrative regulations or any agreement; or
                    </p>
                    <p>
                        ⑤ Any other circumstance as provided by law or administrative regulations.
                    </p>
                    <p>&nbsp;</p>
                </div>
                <p>
                    If it is technically difficult to delete the personal information, XMP shall
                    cease the processing of the personal information, except for the storage and any
                    necessary measure taken for security protection.
                </p>
                <p>&nbsp;</p>
                <h4>7. Security of Your Personal Information</h4>
                <p>
                    We are committed to protecting the security of your personal information. We use
                    a variety of security technologies and procedures to help protect your personal
                    information from unauthorized access, use, or disclosure. We will only keep your
                    personal information for as long as necessary and in any event in relation to
                    personal information collected by us on the Site we will only keep this personal
                    information for one (1) year.
                </p>
                <p>&nbsp;</p>
                <h4>8. The Protection of Minors</h4>
                <p>
                    The Site does not knowingly collect any personal information from minors. The
                    personal information of any minor you provide shall be agreed by the minor’s
                    parent or guardian in advance. Minor is defined in accordance with the laws
                    applicable to this Privacy Policy of XMP Website.
                </p>
                <p>&nbsp;</p>
                <h4>9. Use of Cookies and Other Tracking Technologies</h4>
                <p>
                    XMP may use cookies or other technology (collectively “Cookies”) to collect
                    certain information about you when you are visiting the Site. Please read our
                    Cookies Policy for more details.
                </p>
                <p>&nbsp;</p>
                <h4>10. Outside Sites and Linking</h4>
                <p>
                    The Site may contain links and pointers to other sites on the Internet that are
                    owned or operated by third party vendors and other third parties (the “Outside
                    Sites“). XMP is not responsible for the availability of, or the content located
                    on or through, any Outside Site, including any webcasting or other form of
                    transmission received from any Outside Sites. You should contact the site
                    administrator or Webmaster for those Outside Sites if you have any concerns
                    regarding such links or the content located on such Outside Sites. XMP permits
                    certain third party links to the home page of its Site, provided that XMP is
                    provided with notice of such links and does not thereafter object to such
                    linking. XMP a reserves the right to revoke unilaterally any consent that it may
                    at any time give to any linkage, including linkages to the home page. The
                    Outside Sites are being provided to you only as a convenience and any link or
                    pointer contained on this Site does not imply endorsement of the Outside Sites.
                </p>
                <p>&nbsp;</p>
                <h4>11. Copyright</h4>
                <p>
                    All Site content, design, text, graphics, images, logos, buttons, icons,
                    interfaces, audio and video clips, and the selection and arrangements thereof
                    are the exclusive property of XMP, or its respective content providers, and are
                    protected by Hong Kong SAR and international copyright laws. All software used
                    on the Site is the property of XMP or its respective software suppliers, and
                    such software is protected by Hong Kong SAR and international copyright laws and
                    other applicable laws and treaties. Users of this Site shall not transmit/post
                    any information to this Site that infringes the copyright or other intellectual
                    property rights of others.
                </p>
                <p>&nbsp;</p>
                <h4>12. Trademarks</h4>
                <p>
                    All trademarks, service marks and trade names used on the Site (collectively the
                    “Marks”) are solely owned by XMP. The Marks may not be used in connection with
                    any product or service that is not a product or service of XMP or that is likely
                    to cause confusion among customers, or that in any manner disparages or
                    discredits XMP.
                </p>
                <p>&nbsp;</p>
                <h4>13. Online Conduct</h4>
                <p>
                    Any conduct by a person that in XMP ‘s sole discretion restricts or inhibits any
                    other person from using or enjoying the Site, is prohibited. You agree to use
                    the Site only for lawful purposes and in accordance with that prohibition. You
                    agree that you will not post on the Site or transmit any unlawful, harmful,
                    threatening, abusive, harassing, defamatory, vulgar, obscene, sexually explicit,
                    profane, hateful, racially, ethnically, or otherwise objectionable material of
                    any kind, including, but not limited to, any material that encourages conduct
                    that would constitute a criminal offense, give rise to civil liability or
                    otherwise violate any applicable local, state, national, or international law.
                </p>
                <p>&nbsp;</p>
                <h4>14. Termination of Usage</h4>
                <p>
                    XMP may, without incurring any liability to you, terminate access by you or
                    suspend any access to all or part of the Site, without notice, for any conduct
                    that XMP, in its sole discretion, believes is in violation of any applicable law
                    or this Agreement, or is harmful to the interests of another user, a
                    third-party, a merchant, a sponsor, a licensor, a service provider, or the Site.
                </p>
                <p>
                    Due to the rapidly evolving technologies on the Internet, we may occasionally
                    update this policy. All revisions will be posted to this website
                    https://xmp.mobvista.com/en
                </p>
                <p>&nbsp;</p>
                <h3><b>Contact Us</b></h3>
                <p>&nbsp;</p>
                <p>
                    If you are Users in the mainland areas of China, please contact us in the
                    following ways:
                </p>
                <p>
                    If you have any question or advice for our privacy policy, please contact us via
                    privacy@mobvista.com. Our office hour is Monday to Friday from 10:00 to 18:00.
                    You can also contact us via the below address:
                </p>
                <p>
                    43/F, East Tower of Tianying Square, No. 222 of Xingmin Road, Tianhe district,
                    Guangzhou, Guangdong Province, China.
                </p>
                <p>
                    If you are User outside mainland areas of China, please contact us in the
                    following ways, please contact XMP’s Data Protection Officer at
                    privacy@mobvista.com. You can also write to the data protection representative
                    Eurocore B.V. in the EEA at:
                </p>
                <p>Hullenbergweg 278, 1101BV Amsterdam</p>
                <p>
                    Please be sure to include your personal name (and the full company name, if you
                    are inquiring on behalf of such company), address and email address in any
                    correspondence to us so that we can respond to your inquiry in a timely manner.
                </p>
                <p>&nbsp;</p>
                <h3 id="Cookies Policy">XMP Cookies Policy</h3>
                <p>&nbsp;</p>
                <h4>What are cookies?</h4>
                <p>
                    Cookies are small text files that websites place on your device as you are
                    browsing. They are processed and stored by your web browser, and can typically
                    be read on your future visits.
                </p>
                <p>In general, there are four types of cookies based on what purpose they serve.</p>
                <p>
                    Strictly necessary cookies – These cookies are essential for you to browse the
                    website and use its features, such as accessing secure areas of the site.
                </p>
                <p>
                    Preferences cookies – also known as “functionality cookies,” these cookies allow
                    a website to remember choices you have made in the past, like what language you
                    prefer, what region you would like weather reports for, or what your user name
                    and password are so you can automatically log in.
                </p>
                <p>
                    Statistics cookies – Also known as “performance cookies,” these cookies collect
                    information about how you use a website, like which pages you visited and which
                    links you clicked on. Their sole purpose is to improve website functions. This
                    includes cookies from third-party analytics services as long as the cookies are
                    for the exclusive use of the owner of the website visited.
                </p>
                <p>
                    Marketing cookies – These cookies track your online activity to help advertisers
                    deliver more relevant advertising or to limit how many times you see an ad.
                </p>
                <p>&nbsp;</p>
                <h4>What type of cookies does XMP use?</h4>
                <p>XMP only uses the following two type of cookies for the following purposes.</p>
                <p>
                    Preferences cookies- XMP solely uses the preferences cookie to remember your
                    set-up in the past in order to optimize the information presented to you.
                </p>
                <p>
                    Statistics cookies- XMP also uses third party cookie of Google Analytics for
                    purpose of helping us understand how the website is being used. Google Analytics
                    collects first-party cookies, data related to the device/browser, IP address and
                    on-site/app activities to measure and report statistics about user interactions.
                    Google uses Google Analytics to provide the Google Analytics measurement service
                    to XMP. For more information about the Google Analytics cookies, please read
                    here.
                </p>
                <p>&nbsp;</p>
                <h4>Opt out</h4>
                <p>
                    To opt out of our cookies used to remember the language you select when you
                    visit our website, please find the guidance of managing and deleting cookies via
                    www.allaboutcookies.org. To opt out of the third party cookie of Google
                    Analytics, please click here.
                </p>
                <p>&nbsp;</p>
                <h4>Contact Us</h4>
                <p>
                    If you are Users in the mainland areas of Cina, please contact us in the
                    following ways:
                </p>
                <p>
                    If you have any question or advice for our privacy policy, please contact us via
                    privacy@mobvista.com. Our office hour is Monday to Friday from 10:00 to 18:00   .
                    You can also contact us via the below address:
                </p>
                <p>
                    43/F, East Tower of Tianying Square, No. 222 of Xingmin Road, Tianhe district,
                    Guangzhou, Guangdong Province, China.
                </p>
                <p>
                    If you are User outside mainland areas of China, please contact us in the
                    following ways, please contact XMP’s Data Protection Officer at
                    privacy@mobvista.com. You can also write to the data protection representative
                    Eurocore B.V. in the EEA at:
                </p>
                <p>Hullenbergweg 278, 1101BV Amsterdam</p>
                <p>
                    Please be sure to include your personal name (and the full company name, if you
                    are inquiring on behalf of such company), address and email address in any
                    correspondence to us so that we can respond to your inquiry in a timely manner.
                </p>
                <p>&nbsp;</p>
            </div>
            </div>
        </div>
    );
}

export default PrivateEN;