import i18n from "i18next";
import translation_en from "./en.json";
import translation_cn from "./cn.json";
import { initReactI18next } from "react-i18next";
const lng = "cn";
i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      en: {
        translation: translation_en,
      },
      cn: {
        translation: translation_cn,
      },
    },
    lng: window.location.pathname.startsWith('/en') ? 'en' : 'cn',
    fallbackLng: lng,
    interpolation: {
      escapeValue: false,
    },
  });
export default i18n;
