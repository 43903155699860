import Home from '../pages/Home';
import Use from '../pages/Use';
import Example from '../pages/Example';
import More from '../pages/More'
import Encyclopedia from '../pages/Encyclopedia'
import Partner from '../pages/Partner'
import Affiliate from "../pages/Affiliate";
// import HelpLook from '../pages/HelpLook';
import Ecommerce from '../pages/Ecommerce';
import PrivateCN from '../pages/Privacy/CN';
import PrivateEN from '../pages/Privacy/EN';
import AppSolution from '../pages/AppSolution';


const routers = [
  {
    title: 'Home',
    path: '/',
    component: Home,
  },
  {
    title: 'Home',
    path: '/:lang',
    component: Home,
  },
  {
    title: 'Use',
    path: '/:lang/use',
    component: Use,
  },
  {
    title: 'Example',
    path: '/:lang/example',
    component: Example,
  },
  {
    title: 'More',
    path: '/:lang/more/:name',
    component: More,
  },
  {
    title:'Encyclopedia',
    path:'/cn/encyclopedia',
    component: Encyclopedia
  },
  {
    title:'Partner',
    path:'/:lang/partner',
    component: Partner
  },
  {
    title:'Affiliate',
    path:'/cn/affiliate',
    component: Affiliate
  },
  {
    title:'Ecommerce',
    path: '/cn/ecommerce',
    component: Ecommerce
  },
  {
    title:'PrivacyCN',
    path:'/cn/privacy',
    component: PrivateCN
  },
  {
    title:'PrivateEN',
    path:'/en/privacy',
    component: PrivateEN
  },
  {
    title:'AppSolution',
    path:'/:lang/appsolution',
    component: AppSolution
  },
  // {
  //   title:'HelpLook',
  //   path:'/:lang/blog',
  //   component: HelpLook
  // },
  {
    title: '404',
    path: '*',
    component: Home,
  },
];

export default routers;
