import React from "react";
import "./index.scss";
import FreeUse from "../../../components/common/FreeUse";
import { t } from "i18next";
import VideoPlayer from "../../../components/common/VideoPlayer";
import i18n from "../../../i18n";

function AI(props) {
  const {type, onClick} = props;
  const list = [1, 2, 3].map((item) => ({
    id: item,
    src: require(`../../../assets/images/Use/AI/${item}.svg`),
    content: t(`use.ai.text${item}`),
  }));
  return (
    <div className={["AI",i18n.language].join(" ")}>
      <div className="AI_background"></div>
      <div className="AI_content_wrapper">
        <h2 className={`title ${i18n.language === 'cn' ? 'font-num' : 'font-black'}`}>{t("use.ai.title")}</h2>
        <div className="content_wrapper">
          <div className="left_wrapper">
            <div className="text_wrapper">
              {list.map((item) => (
                <div className="content_item" key={item.id}>
                  <div className="img_wrapper">
                    <img src={item.src} alt="" />
                  </div>
                  <div className="text">{item.content}</div>
                </div>
              ))}
            </div>
            <div className="free">
              <FreeUse
                config={{ bgColor: "blue", value: "use.ai.apply",type, onClick: onClick }}
              ></FreeUse>
            </div>
          </div>
          <div className="video_wrapper">
            <VideoPlayer name={"AI"}></VideoPlayer>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AI;
