import React, { useState, useEffect } from "react";
import "./index.scss";
import { useTranslation } from "react-i18next";
import closeBtn from '../../../assets/images/close_btn.svg'

function PrivacyDialog() {
  const { t } = useTranslation();
  const [isDialogVisible, setDialogVisible] = useState(true);

  useEffect(() => {
    if (localStorage.getItem('IS_PRIVACY_CHOSEN')) {
      setDialogVisible(false);
    }
  }, []);

  const handleAgree = () => {
    window.advt = window.advt || function () {(advt._q = advt._q || []).push([].slice.call(arguments));};
    (function (d, s) {
      function ce(src) {
        var cs = d.createElement(s);
        cs.src = src;
        cs.async = 1;
        d.body.appendChild(cs);
      };
      ce('https://wa.arounddeal.com/wv/v1_ucintrcadwxggguv.js');
    })(document, 'script');

    closeDialog()
  };


  const closeDialog = () => {
    setDialogVisible(false);
    localStorage.setItem('IS_PRIVACY_CHOSEN', 'true');
  }

  const handleRefuse = () => {
    closeDialog()
  };

  if (!isDialogVisible) {
    return null;
  }

  return (
    <div className="privacyDialog">
      <div className="content_wrapper">
        <p className="font-dinot">{t("privacyDialog.main")}</p>
        <div className="button_wrapper">
          <button className="privacy_agree font-bold button_link" onClick={handleAgree}>{t('privacyDialog.agree_btn')}</button>
          <button className="privacy_refuse font-bold button_link" onClick={handleRefuse}>{t('privacyDialog.refuse_btn')}</button>
        </div>
        <div className="close_btn" onClick={closeDialog}>
            <img src={closeBtn} alt="" />
        </div>
      </div>
    </div>
  );
}

export default PrivacyDialog;