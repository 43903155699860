import React from "react";
import "./index.scss";
import { useTranslation } from "react-i18next";
import FeatureRow from "../../../components/common/FeatureRow";
import quoIcon from "../../../assets/images/Home/quotation.png";
import {contentCNList, contentENList} from "../config"

function Feature() {
  let { i18n } = useTranslation();
  const list = [1,2,3,4].map(item => {
    const contentList = i18n.language === 'cn' ? contentCNList : contentENList
    return {
        id: item,
        reverse: (item % 2) === 0,
        content: contentList[item - 1]
    }
  })
  return (
    <div className={`AppSolutionFeature ${i18n.language}`}>
      <div className="feature_content_wrapper">
        {list.map(item => (
          <div className="feature_content_item" key={item.id}>
            <FeatureRow
              reverse={item.reverse}
              tag={item.content.tag}
              title={item.content.title}
              mobiletitle={item.content.mobiletitle}
              description={item.content.description}
              mobileDescription={item.content.mobileDescription}
              imageUrl={item.content.imageSrc}
            >
              <div className="text_content">
                {Object.keys(item.content.text).map(textKey => (
                  <div className="content_item" key={textKey}>
                    <div className="mark_square"></div>
                    <div className="text">{item.content.text[textKey]}</div>
                  </div>
                ))}
                <div className="quote_content font-dinot">
                    <img className="quote_icon" src={quoIcon} alt="quote_icon" />
                    <div className="quote_text">
                      {item.content.quote}
                      <img className="quote_logo" src={item.content.logo} alt="quote_logo"/>
                    </div>
                </div>
              </div>
            </FeatureRow>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Feature;
