import React from "react";
import "./index.scss";
import FreeUse from "../../../components/common/FreeUse";
import { t } from "i18next";
import VideoPlayer from "../../../components/common/VideoPlayer";
import i18n from "../../../i18n";

function Continuation(props) {
  const { type, onClick } = props;
  const list = [1, 2, 3, 4].filter((item, index) => {
    // 如果 i18n.language 是 en 且索引小于3，保留该项
    if (i18n.language === 'en' && index === 3) {
      return false;
    }
  
    // 对于其他情况，保留所有项
    return true;
  }).map((item) => ({
    id: item,
    src: require(`../../../assets/images/Use/Continuation/${item}.svg`),
    content: t(`use.continuation.text${item}`),
  }));
  return (
    <div className={["Continuation", i18n.language].join(" ")}>
      <div className="Continuation_background"></div>
      <div className="Continuation_content_wrapper">
        <div className="content_wrapper">
          <div className="video_wrapper">
            <VideoPlayer name={"Continuation"}></VideoPlayer>
          </div>
          <div className="content">
            <h2 className={`title ${i18n.language === 'cn' ? 'font-num' : 'font-black'}`}>{type === 'mobile' ? t("use.continuation.mobileTitle") : t("use.continuation.title")}</h2>
            <div className="left_wrapper">
              <div className="text_wrapper">
                {list.map((item, index) => (
                  <div className="content_item" key={item.id}>
                    <div className="img_wrapper">
                      <img src={item.src} alt="" />
                    </div>
                    <div className="text">{item.content}</div>
                  </div>
                ))}
              </div>
              <div className="free">
                <FreeUse
                  config={{
                    bgColor: "blue",
                    value: "use.continuation.apply",
                    type,
                    onClick: onClick,
                    color: 'black'
                  }}
                ></FreeUse>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Continuation;
