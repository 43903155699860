export const profile = 'XMP by Mobvista是汇量科技旗下一款的跨渠道智能投放工具。作为首款整合全球广告渠道、MMP、变现平台的工具，XMP by Mobvista通过批量搭建广告、素材管理、打通 ROAS 数据、SDK 平台精准拓量等功能，实现精细化广告投放的同时，简化广告投放流程，效果倍数飙升'

export const platforms = [
  {
    type: '海外大媒体',
    content: 'Google Ads、Meta for Business、TikTok for Business 、Kwai'
  },
  {
    type: 'SDK广告平台',
    content: 'Mintegral、Applovin、Unity Ads、Vungle、ironSource'
  },
  {
    type: '硬核渠道',
    content: 'Petal Ads(原华为广告)'
  },
  {
    type: '三方监测平台',
    content: 'Appsflyer、Adjust、Tenjin、TrackingIO'
  },
]

export const functions = [
  {
    title: '一站接管海外主流广告平台',
    content: 'XMP集成全球广告平台，可实现一站式管理广告、素材、数据，无需切换媒体和账户，支持跨平台单次操作投放，以及跨平台数据横向对比，同时无需 VPN 即可流畅操作，高效管理广告投放。'
  },
  {
    title: '极速批量搭建&管理广告',
    content: 'XMP提供组合批量叉乘功能，帮助开发者团队一次性创建多个广告，每个广告对应不同的地区，设置对应出价和素材，只需在创建时选好绑定关系即可，无需反复创建，大幅提升广告创建效率。'
  },
  {
    title: '素材网盘精细化管理',
    content: 'XMP企业素材库，支持批量上传素材，跨渠道即取即用，实现批量推送视频素材至 YouTube 频道，帮助优化&设计团队高效协同管理素材。同时在企业素材库可设置素材标签+文件夹精细化管理素材，素材表现多维分析，包括自动评分、ROAS、留存、LTV等数据，精准定位优质创意。'
  },
  {
    title: '数据多维可视化分析',
    content: '提供跨渠道跨账号聚合数据报表，帮助开发者从产品视角、 团队视角复盘全渠道买量效果。打通多方买量和变现数据，广告平台、MMP （三方归因平台）、变现平台统一报表分析，开放 API 支持数据回传，数据实时回传至 BI 系统。'
  },
  {
    title: 'SDK 广告平台子渠道精细化运营',
    content: 'XMP 流量池功能基于SDK广告平台（如Mintegral 、AppLovin等）子渠道全量数据，提供了多种子渠道大盘指标包括子渠道排名、eCPM、CPI、IVR 以及 ROAS、LTV  等，开发者团队可以轻松查看不同市场、不同品类的 Top 子渠道，并且通过大盘数据比对，获悉自身 Campaign 在各个子渠道上的竞争力，为其子渠道优化提供数据指导。'
  },
  {
    title: 'AI 盯盘自动化托管广告',
    content: '7*24 小时全天候自动监控，支持自定义 AI 监控规则和频率，支持预警通知、开关、调预算、调出价等多种自动执行操作，减少广告投放盯盘压力。'
  },
  {
    title: '团队高效协作',
    content: '可根据管理者、优化师、设计师等不同角色，自定义管理部门和用户，区分优化师和设计师不同权限，实时查看团队人效，支持广告账户、素材等共享协作，设计师和优化师高效协同。'
  },
]

export const customers = [
  {
    title: 'TanTan',
    content: 'XMP集成全球广告平台，可实现一站式管理广告、素材、数据，无需切换媒体和账户，支持跨平台单次操作投放，以及跨平台数据横向对比，同时无需 VPN 即可流畅操作，高效管理广告投放。'
  },
  {
    title: '中手游',
    content: '中手游通过 XMP 实现全球精细化运营'
  },
  {
    title: '雷霆游戏',
    content: '雷霆游戏通过 XMP 挖掘起量素材'
  },
  {
    title: 'Supercent',
    content: '韩国开发者Supercent通过XMP实现全球高效增长'
  },
  {
    title: '哇哇鱼',
    content: '哇哇鱼通过 XMP 建立出海买量体系'
  },
]