import React from 'react';
import qrCodeUrl from '@/assets/images/Footer/qr-code.png';
import { ReactComponent as ContactImage } from '@/assets/images/Footer/contact.svg';
import { useTranslation } from "react-i18next";

import './index.scss';

export default function ContactUs() {
  const { t, i18n } = useTranslation();

  return (
    <div className="contact-container">
      <div className="contact-popover">
        <div class="contact-content">
          <img className="contact-qr-code" src={qrCodeUrl}></img>
          <div className="contact-info">
            <div className="contact-title">{ t('footer.connect') }</div>
            <div className="contact-email">
              <span className={`contact-item-label custom-family ${i18n.language}`}>{ t('footer.email') }:</span>
              <span className="custom-family">xmp-support@mobvista.com</span>
            </div>
            <div className="contact-phone">
              <span className={`contact-item-label custom-family ${i18n.language}`}>{ t('footer.mobilePhone') }:</span>
              <span className="custom-family">+86 17512804270</span>
            </div>
          </div>
        </div>
        {/* <div className="contact-triangle"></div> */}
      </div>

      <div className="contact-entry">
        <ContactImage className="contact-entry-icon" />
      </div>
    </div>
  )
}