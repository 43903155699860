import React from "react";
import "./index.scss";
import { useTranslation } from "react-i18next";
import { ReactComponent as Up } from "../../../assets/images/up.svg";
import { ReactComponent as Down } from "../../../assets/images/down.svg";
import {efficiencyCNList,efficiencyENList} from "../config"

function BusinessCase() {
    let { i18n } = useTranslation();
    const list = [1,2,3].map(item => {
      const efficiencyList = i18n.language === 'cn' ? efficiencyCNList : efficiencyENList
      return {
        id: item,
        efficiency: efficiencyList[item-1]
      }
    })
    
    return (
      <div className={`AppSolutionBusinessCase ${i18n.language}`}>
        {list.map(item => (
            <div className="business_case_item" key={item.id}>
                    <div className="logo_wrapper">
                        <img className="logo" src={item.efficiency.logo} alt="logo"/>
                    </div>
                    <div className="efficiency_wrapper">
                        <div className="efficiency_num blue">
                            <div className="icon_wrapper"> 
                                {item.efficiency.down ? <Down /> : <Up/>}
                            </div>
                            <span className="num font-num ">{item.efficiency.num}</span>
                        </div>
                        <div className="efficiency_value">{item.efficiency.value}</div>
                    </div>
            </div>
        ))}
      </div>
    );
  }
  
  export default BusinessCase;