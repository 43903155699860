import "./App.css";
import Header from "./components/common/Header";
import Footer from "./components/common/Footer";
import UTMTracking from "./components/common/UTMTracking";
import PrivacyDialog from "./components/common/PrivacyDialog"
import "./i18n";
import { Routes, Route,Navigate, useLocation } from "react-router-dom";
import routers from "./routers";

function App() {
  const location = useLocation()
  const isShow = !location.pathname.includes('privacy');
  return (
    <div className="App">
      <UTMTracking />
      {isShow && <Header />}
        <Routes>
          {routers.map((item, index) => {
            if(item.path === '*'){
              return(
                <Route key={item.title || index} path="*" element={<Navigate to="/" replace={true}/>} />
              )
            }
            return (

                <Route
                  key={item.title || index}
                  exact
                  path={item.path}
                  element={<item.component location={location}/>}
                />

            );
          })}
        </Routes>
      {isShow && <Footer />}
      <PrivacyDialog/>
    </div>
  );
}

export default App;
