import React, { useEffect, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import Marquee from "react-fast-marquee";
import { throttle, screenType, getFreeUrl } from '../../components/common/utils.js'
import heroImage from "../../assets/images/AppSolution/heroImage.png"
import heroImageMobile from "../../assets/images/AppSolution/heroImage_mobile.png"
import BottomBar from "../../components/common/BottomBar/index.jsx";
import Feature from "./Feature/index.jsx";
import BusinessCase from "./BusinessCase/index.jsx";
import {commentList} from "./config.js"
import { chunk } from "lodash";
import { goLoginOrSignUp } from '@/utils/common.js';

import "./index.scss";

function AppSolution() {
  const { t } = useTranslation();
  let { i18n } = useTranslation();
  const [type, setType] = useState("");

  const [ceilCount, setceilCount] = useState(2)

  const imageContext = require.context(
    "/public/official/companyList-grey",
    false,
    /\.(png|jpe?g|gif|svg)$/
  );
  const imageFileNames = imageContext.keys().map((key) => key.replace("./", ""));

  const companyLists = chunk(
    imageFileNames,
    Math.ceil(imageFileNames.length / ceilCount)
  ).map((item) =>
    item.map((iitem) => `${process.env.PUBLIC_URL}/official/companyList-grey/${iitem}`)
  );

  const handleResize = useCallback(() => {
    const throttleFn = throttle(() => {
      setType(screenType());
    }, 300);
    throttleFn();
  },[setType]);

  const handleLoginOrSignUp = () => {
    goLoginOrSignUp(i18n.language);
  }

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    setceilCount(type === 'mobile' ? 3 : 2)

    return () => {
      window.removeEventListener('resize', handleResize);
    }
  }, [handleResize,type]);

  return (
    <div className={`AppSolution ${i18n.language}`}>
      <div className="main_container">
        <div className="content_wrapper">
          <div className="title">
            <h1 className={i18n.language === 'en' ? 'font-black' : 'font-num'}>{type === 'mobile' ? t("appsolution.main.mobileTitle1") : t("appsolution.main.title1")}</h1>
            <p className="content font-dinot">{t("appsolution.main.text")}</p>
          </div>
          <div className="free">
             <button
                className={["free_btn font-bold button_link", type].join(" ")}
                onClick={handleLoginOrSignUp}
              >
                {t('appsolution.main.freeUse')}
              </button>
          </div>
          <div className='commentList'>
            {commentList.map((item,index) => {
              return <div className="comment" key={index}><span className="comment_icon">✓&nbsp;</span>{item.comment}</div>
            })}
          </div>
        </div>
        <div className="heroImage_wrapper">
                {type === 'mobile' ?
                  <img src={heroImageMobile} alt="heroImageMobile" /> :
                  <img src={heroImage} alt="heroImage" />
                }
        </div>
      </div>
      <div className="swiper_wrapper">
          {companyLists.map((item, index) =>
            <Marquee gradient={false} direction={(index === 1|| index === 3 ? 'right' : 'left')} key={index}>
              {item?.map((iitem, index) => {
                  return <img src={iitem} key={iitem} alt="" />;
              })}
            </Marquee>
          )}
      </div>
       <div className="feature_wrapper">
          <Feature/>
        </div>
        <div className="businessCase_wrapper">
          <BusinessCase/>
        </div>
      <BottomBar type="type1" text={type === 'mobile' ? t("appsolution.connect.mobileTitle") : t("appsolution.connect.title")} buttonText={t("appsolution.connect.text")} onClick={handleLoginOrSignUp}></BottomBar>
    </div>
  );
}

export default AppSolution;
