import { t } from "i18next";
import React from "react";
import { useTranslation } from "react-i18next";
import FreeUse from "../../../components/common/FreeUse";
import "./index.scss";
import VideoPlayer from "../../../components/common/VideoPlayer";

function Build(props) {
  let { i18n } = useTranslation();
  const { type, onClick } = props;
  const list = [1, 2, 3, 4].map((item) => ({
    id: item,
    src: require(`../../../assets/images/Use/Build/${item}.svg`),
    content: t(`use.build.text${item}`),
  }));
  return (
    <div className={`Build ${i18n.language}`}>
      <div className="Build_background"></div>
      <div className="Build_content_wrapper">
        <h2 className="title">{t("use.build.title")}</h2>
        <div className="content_wrapper">
          <div className="video_wrapper">
            <VideoPlayer name={"Build"}></VideoPlayer>
          </div>
          <div className="left_wrapper">
            <div className="text_wrapper">
              {list.map((item) => (
                <div className="content_item" key={item.id}>
                  <div className="img_wrapper">
                    <img src={item.src} alt="" />
                  </div>
                  <div className="text">{item.content}</div>
                </div>
              ))}
            </div>
            <div className="free">
              <FreeUse
                config={{
                  bgColor: "orange",
                  value: "use.build.apply",
                  type,
                  onClick: onClick
                }}
              ></FreeUse>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Build;
