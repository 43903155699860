import React from "react";
import "./index.scss";
import FreeUse from "../../../components/common/FreeUse";
import { t } from "i18next";
import VideoPlayer from "../../../components/common/VideoPlayer";
import i18n from "../../../i18n";

function Info(props) {
  const { type, onClick } = props;
  const list = [1, 2, 3, 4, 5].map((item) => ({
    id: item,
    src: require(`../../../assets/images/Use/Info/${item}.svg`),
    content: t(`use.info.text${item}`),
  }));

  return (
    <div className={["Info", i18n.language].join(" ")}>
      <div className="Info_background"></div>
      <div className="Info_content_wrapper">
        <div className="content_wrapper">
          <h2 className="title">{t("use.info.title")}</h2>
          <div className="content">
            <div className="left_wrapper">
              <div className="text_wrapper">
                {list.map((item) => (
                  <div className="content_item" key={item.id}>
                    <div className="img_wrapper">
                      <img src={item.src} alt="" />
                    </div>
                    <div className="text">{item.content}</div>
                  </div>
                ))}
              </div>
              <div className="free">
                <FreeUse
                  config={{
                    bgColor: "orange",
                    value: "use.info.apply",
                    type,
                    onClick: onClick
                  }}
                ></FreeUse>
              </div>
            </div>
          </div>
        </div>

        <div className="video_wrapper">
          <VideoPlayer name={"Info"}></VideoPlayer>
        </div>
      </div>
    </div>
  );
}

export default Info;
