import { useState, useMemo, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import {routerList} from "./config"
import { useNavigate, useLocation} from "react-router-dom";
import { ReactComponent as Logo } from "../../../assets/images/Home/logo.svg";
import {throttle, screenType, getFreeUrl, hasUTMParams, getUTMParamsStr} from '../utils.js';
import "./index.scss";
import { goLoginOrSignUp } from '@/utils/common.js';

function Header(props) {
  const { t } = useTranslation();
  let { i18n } = useTranslation();
  const navigate = useNavigate();
  const [type, setType] = useState("");

  const [isDropDown, setDropDown] = useState(false);
  const pathInfo = useLocation();
  const componentsName = window.location.href.split(`${i18n.language}`)[1];
  const [registerUrl,setRegisterUrl] = useState("")
  const utmParamsStr = getUTMParamsStr();
  const pathList = useMemo(() => {
    const list = [
      { id: 1, name: "header.use", url: `/${i18n.language}/use` },
      { id: 2, name: "header.example", url: `/${i18n.language}/example` },
      { id: 3, name: "header.blog", url: hasUTMParams() ? `/${i18n.language}-blog?${utmParamsStr}` : `/${i18n.language}-blog`},
      { id: 5, name: "header.appsolution", url: `/${i18n.language}/appsolution` },
    ];

    if(i18n.language === 'cn'){
      list.push({ id: 4, name: "header.ecommerce", url: `/cn/ecommerce` });
    }

    list.sort((a, b) => a.id - b.id);

    return list;
    },[i18n.language, utmParamsStr]
  );
  const getInitialActiveIndex = () => {
    return pathList.findIndex(item => item.url === pathInfo.pathname)
  };

  const [activeIndex, setActiveIndex] = useState(getInitialActiveIndex())

  const handleResize = useCallback(() => {
    const throttleFn = throttle(() => {
      setType(screenType());
    }, 300);
    throttleFn();
  },[setType]);

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    }
  }, [handleResize]);

  const toPath = (item, index) => () => {
    setDropDown(false);
    navigate(item.url);
    setActiveIndex(-1)
  };

  const toggleLanguage = useCallback((lang) => {
    const { pathname } = window.location;
    let omitLangPath = pathname.replace(/^\/\w+/, '');
    const naviateUrl = `${lang}${omitLangPath}`;
    navigate(naviateUrl);
    i18n.changeLanguage(lang);
    setDropDown(false);
    window.location.reload();
  }, [setDropDown, i18n, navigate])

  const switchMenu = (index) => {
    setActiveIndex(index)
  }

  const handleLoginOrSignUp = () => {
    goLoginOrSignUp(i18n.language);
  }

  return (
    <div className={`Header ${i18n.language}`}>
      <Logo className="logo" onClick={toPath({ url: `/${i18n.language}/` })} />
      <div className="path_wrapper">
        {pathList.map((item, index) => {
          return <a className={`path_item font-black ${activeIndex === index && 'active'}`} href={item.url} key={item.id} onClick={() => switchMenu(index)}>{t(item.name)}</a>
        })}
      </div>

      <div className="app_wrapper">
        <button onClick={handleLoginOrSignUp} className="apply button_link">
          <span className='font-bold'>{t('header.apply')}</span>
        </button>
        <div className="lang font-black">
          <span className="font-black">{t("header.lang")}</span>
          <div className="dropDown">
            {i18n.language === "cn" && (
              <div
                onClick={() => toggleLanguage('en')}
              >
                EN
              </div>
            )}
            {i18n.language === "en" && (
              <div
                onClick={() => toggleLanguage('cn')}
              >
                CN
              </div>
            )}
          </div>
        </div>
        {type === "mobile" && (
          <div
            className="drop"
            onClick={() => {
              setDropDown(!isDropDown);
            }}
          >
            <div></div>
            <div></div>
            <div></div>
          </div>
        )}
      </div>
      {isDropDown && (
        <div className="drop_list">
          <div className="path_wrapper">
            {pathList.map((item, index) => {
              return (
                <div
                  className={[
                    "path_item font-bold",
                  ].join(" ")}
                  key={item.id}
                >
                  <a href={item.url}    rel="noreferrer">
                  {t(item.name)}
                </a>
              </div>
              );
            })}
          </div>
          <div className="lang">
            <div
              className={i18n.language === "cn" ? "active" : ""}
              onClick={() => toggleLanguage('cn')}
            >
              简体中文
            </div>
            <div
              className={i18n.language === "en" ? "active" : ""}
              onClick={() => toggleLanguage('en')}
            >
              EN
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Header;
