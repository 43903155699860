import { profile, platforms, functions, customers } from "./config";

import "./index.scss";
function Profile() {
  return <div className="profile">{profile}</div>;
}

function Platform() {
  return (
    <>
      <h4>XMP 支持的平台</h4>
      <ul>
        {platforms.map((item) => (
          <li>
            <span>{item.type}：</span>
            {item.content}
          </li>
        ))}
      </ul>
    </>
  );
}

function Introduction({ title, list }) {
  return (
    <>
      <h4>{title}</h4>
      <ul>
        {list.map((item, index) => (
            <li className="introduction">
              <h5>{index+1}、{item.title}</h5>
              {item.content}
            </li>
          )) }
      </ul>
    </>
  )
}
function Encyclopedia() {
  return (
    <div className="Encyclopedia">
      <main>
        <Profile />
        <Platform />
        <Introduction title="产品功能" list={functions}/>
        <Introduction title="客户案例" list={customers}/>
      </main>
    </div>
  );
}
export default Encyclopedia;
