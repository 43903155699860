import React, { useEffect, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { throttle, screenType, getFreeUrl } from '../../components/common/utils.js'
import heroImage from "../../assets/images/Ecommerce/heroImage.jpg"
import platform from "../../assets/images/Ecommerce/platform.svg"
import platformMobile from "../../assets/images/Ecommerce/platform_mobile.svg"
import BottomBar from "../../components/common/BottomBar";
import Feature from "./Feature/index.jsx";
import BusinessCase from "./BusinessCase/index.jsx";
import { goLoginOrSignUp } from '@/utils/common.js';

import "./index.scss";

function Ecommerce() {
  const { t } = useTranslation();
  let { i18n } = useTranslation();
  const [type, setType] = useState("");

  const handleResize = useCallback(() => {
    const throttleFn = throttle(() => {
      setType(screenType());
    }, 300);
    throttleFn();
  },[setType]);

  const handleLoginOrSignUp = () => {
    goLoginOrSignUp(i18n.language);
  }

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    }
  }, [handleResize]);

  return (
    <div className={`Ecommerce ${i18n.language}`}>
      <div className="main_container">
        <div className="content_wrapper">
          <div className="title">
            <h1 className={i18n.language === 'en' ? 'font-black' : 'font-num'}>{type === 'mobile' ? t("ecommerce.main.mobileTitle1") : t("ecommerce.main.title1")}</h1>
            <p className="content font-dinot">{t("ecommerce.main.text")}</p>
          </div>
          <div className="free">
             <button
                className={["free_btn font-bold button_link", type].join(" ")}
                onClick={handleLoginOrSignUp}
              >
                {t('ecommerce.main.freeUse')}
              </button>
          </div>
        </div>
        <div className="heroImage_wrapper">
            <img src={heroImage} alt="heroImage" />
        </div>
      </div>
       <div className="platform_wrapper">
        <div className="content_wrapper">
            <h2 className="title">{t("ecommerce.platform.title")}</h2>
            <div className="exmaple_wrapper">
                <div className="content_title color_blue">{t("ecommerce.platform.content_title1")}</div>
                {type === 'mobile' ?
                  <img src={platformMobile} alt="platformMobile" /> :
                  <img src={platform} alt="platform" />
                }
                <div className="content_title color_yellow">{t("ecommerce.platform.content_title2")}</div>
            </div>
            <span className="text font-dinot">{t("ecommerce.platform.text")}</span>
        </div>
       </div>
       <div className="feature_wrapper">
          <Feature/>
        </div>
        <div className="businessCase_wrapper">
          <BusinessCase/>
        </div>
      <BottomBar type="type1" text={type === 'mobile' ? t("ecommerce.connect.mobileTitle") : t("ecommerce.connect.title")} buttonText={t("ecommerce.connect.text")} onClick={handleLoginOrSignUp}></BottomBar>
    </div>
  );
}

export default Ecommerce;
