import tantan from "@/assets/images/AppSolution/logo/tantan.png";
import leiting from '@/assets/images/AppSolution/logo/leiting.png'
import cmge from '@/assets/images/AppSolution/logo/cmge.png'
import gamehaus from '@/assets/images/AppSolution/logo/gamehaus.png'
import supercent from '@/assets/images/AppSolution/logo/supercent.png'
import alictus from '@/assets/images/AppSolution/logo/alictus.png'
import featureCN1 from '@/assets/images/AppSolution/cn/feature_1.png'
import featureCN2 from '@/assets/images/AppSolution/cn/feature_2.png'
import featureCN3 from '@/assets/images/AppSolution/cn/feature_3.png'
import featureCN4 from '@/assets/images/AppSolution/cn/feature_4.png'
import featureEN1 from '@/assets/images/AppSolution/en/feature_1.png'
import featureEN2 from '@/assets/images/AppSolution/en/feature_2.png'
import featureEN3 from '@/assets/images/AppSolution/en/feature_3.png'
import featureEN4 from '@/assets/images/AppSolution/en/feature_4.png'

import i18n from "../../i18n";

const { t } = i18n

const commentList = [
  {comment: "Dedicated onboarding guidance."},
  {comment: "No credit card required."},
  {comment: "Cancel anytime."},
]

const contentCNList = [
  {
    imageSrc: featureCN1,
    title: t('appsolution.feature.feature1.title'),
    description: t('appsolution.feature.feature1.description'),
    mobileDescription: t('appsolution.feature.feature1.mobileDescription'),
    text: {
      text1: t('appsolution.feature.feature1.text1'),
      text2: t('appsolution.feature.feature1.text2'),
      text3: t('appsolution.feature.feature1.text3'),
      text4: t('appsolution.feature.feature1.text4'),
      text5: t('appsolution.feature.feature1.text5'),
    },
    quote: t('appsolution.feature.feature1.quote'),
    logo: leiting
  },
  {
    imageSrc: featureCN2,
    title: t('appsolution.feature.feature2.title'),
    description: t('appsolution.feature.feature2.description'),
    text: {
      text1: t('appsolution.feature.feature2.text1'),
      text2: t('appsolution.feature.feature2.text2'),
      text3: t('appsolution.feature.feature2.text3'),
      text4: t('appsolution.feature.feature2.text4'),
    },
    quote: t('appsolution.feature.feature2.quote'),
    logo: tantan
  },
  {
    imageSrc: featureCN3,
    title: t('appsolution.feature.feature3.title'),
    mobiletitle: t('appsolution.feature.feature3.mobiletitle'),
    description: t('appsolution.feature.feature3.description'),
    text: {
      text1: t('appsolution.feature.feature3.text1'),
      text2: t('appsolution.feature.feature3.text2'),
      text3: t('appsolution.feature.feature3.text3'),
      text4: t('appsolution.feature.feature3.text4'),
      text5: t('appsolution.feature.feature3.text5'),
    },
    quote: t('appsolution.feature.feature3.quote'),
    logo: cmge
  },
  {
    imageSrc: featureCN4,
    title: t('appsolution.feature.feature4.title'),
    mobiletitle: t('appsolution.feature.feature4.mobiletitle'),
    description: t('appsolution.feature.feature4.description'),
    text: {
      text1: t('appsolution.feature.feature4.text1'),
      text2: t('appsolution.feature.feature4.text2'),
      text3: t('appsolution.feature.feature4.text3'),
      text4: t('appsolution.feature.feature4.text4'),
    },
    quote: t('appsolution.feature.feature4.quote'),
    logo: gamehaus
  },
]

const contentENList = [
  {
    imageSrc: featureEN1,
    tag: "Automation",
    title: t('appsolution.feature.feature1.title'),
    text: {
      text1: t('appsolution.feature.feature1.text1'),
      text2: t('appsolution.feature.feature1.text2'),
      text3: t('appsolution.feature.feature1.text3'),
    },
    quote: t('appsolution.feature.feature1.quote'),
    logo: alictus
  },
  {
    imageSrc: featureEN2,
    tag: "Creatives",
    title: t('appsolution.feature.feature2.title'),
    mobiletitle: t('appsolution.feature.feature2.mobiletitle'),
    text: {
      text1: t('appsolution.feature.feature2.text1'),
      text2: t('appsolution.feature.feature2.text2'),
      text3: t('appsolution.feature.feature2.text3'),
    },
    quote: t('appsolution.feature.feature2.quote'),
    logo: tantan
  },
  {
    imageSrc: featureEN3,
    tag: "Data",
    title: t('appsolution.feature.feature3.title'),
    text: {
      text1: t('appsolution.feature.feature3.text1'),
      text2: t('appsolution.feature.feature3.text2'),
      text3: t('appsolution.feature.feature3.text3'),
    },
    quote: t('appsolution.feature.feature3.quote'),
    logo: leiting
  },
  {
    imageSrc: featureEN4,
    tag: "Launch",
    title: t('appsolution.feature.feature4.title'),
    mobiletitle: t('appsolution.feature.feature4.mobiletitle'),
    text: {
      text1: t('appsolution.feature.feature4.text1'),
      text2: t('appsolution.feature.feature4.text2'),
      text3: t('appsolution.feature.feature4.text3'),
      text4: t('appsolution.feature.feature4.text4'),
    },
    quote: t('appsolution.feature.feature4.quote'),
    logo: supercent
  },
]

const efficiencyCNList = [
    {
        logo: tantan,
        value: t('appsolution.businessCase.case1.item.value'),
        num: t('appsolution.businessCase.case1.item.num'),
        down: true
    },
    {
        logo: cmge,
        value: t('appsolution.businessCase.case2.item.value'),
        num: t('appsolution.businessCase.case2.item.num'),
        down: false
    },
    {
        logo: leiting,
        value: t('appsolution.businessCase.case3.item.value'),
        num: t('appsolution.businessCase.case3.item.num'),
        down: false
    }
]

const efficiencyENList = [
  {
      logo: alictus,
      value: t('appsolution.businessCase.case1.item.value'),
      num: t('appsolution.businessCase.case1.item.num'),
      down: false
  },
  {
      logo: supercent,
      value: t('appsolution.businessCase.case2.item.value'),
      num: t('appsolution.businessCase.case2.item.num'),
      down: false
  },
  {
      logo: tantan,
      value: t('appsolution.businessCase.case3.item.value'),
      num: t('appsolution.businessCase.case3.item.num'),
      down: true
  }
]


export {
    contentCNList,
    efficiencyCNList,
    commentList,
    contentENList,
    efficiencyENList
};
