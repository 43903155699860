import React from "react";
import logo from "@/assets/images/logo.png";
import "../index.scss";

function PrivateCN() {
  return (
    <div className="PrivacyPolicy">
      <div className="header">
        <a href="https://xmp.mobvista.com/login">
          <img src={logo} width="60" alt="logo" />
        </a>
      </div>
      <div className={"container"}>
        <div className={"content"}>
          <h2>隐私政策</h2>
          <p>&nbsp;</p>
          <p>2024年6月25日</p>
          <p>&nbsp;</p>
          <p>
            XMP主体（详见
            <a
              href="https://xmp.mobvista.com/official/one-pager/XMP_entities_list.pdf"
              target="_blank"
            >
              此处
            </a>
            ，以下统称为“XMP”或“我们”，）提供本隐私政策以介绍我们如何收集、使用以及共享信息。根据欧洲数据保护法律的规定，XMP是您个人信息的数据控制方。本隐私政策（以下简称“政策”）将介绍XMP对下列两类信息的处理：
          </p>
          <p>
            (1)
            我们在提供XMP平台、软件、技术、工具或XMP提供的任何服务（“XMP服务”或“服务”）过程中所处理的个人信息；
          </p>
          <p>
            (2) 我们通过我们的网站
            <a href="https://xmp.mobvista.com/">https://xmp.mobvista.com/</a>
            或其他载有本政策的网站、以及我们为客户或商业合作伙伴所提供的登录网站入口和其他服务（“XMP网站”、“网址”、“网站”）而从网站访问者处获得的信息。
          </p>
          <p>
            本隐私政策受XMP与您的服务协议的约束。在使用XMP服务或XMP网站前，请阅读本隐私政策。在使用XMP服务或XMP网站时，您确认您已经阅读、理解并同意本隐私政策。
          </p>
          <p>
            为了进一步完善我们的隐私政策和服务，我们会不定期对本隐私政策进行修订。如果本隐私政策发生了重大修改，我们会在新的隐私政策生效前通过XMP网站发布公告。
          </p>
          <p>
            数据保护以及保证业务合作伙伴和您的信任是XMP业务原则的核心。因此，遵守适用的数据保护法规是我们的重要任务，本政策已更新并符合可适用的数据保护法律的要求，包括欧盟通用数据保护法规（“GDPR”）、美国儿童在线隐私保护法（“COPPA”）、加州消费者隐私法案（“CCPA”）、巴西通用数据保护法（“LGPD”）。
          </p>
          <p>
            就本隐私政策之目的，受托处理者指的是《中华人民共和国个人信息保护法》下的受托处理者，GDPR及VCDPA下的处理者（“processor”），CCPA下的服务提供者（“serviceprovider”），而个人信息处理者指的是《中华人民共和国个人信息保护法》下的个人信息处理者，GDPR及VCDPA下的控制者（“controller”），CCPA下的公司（“business”）。
          </p>
          <p>&nbsp;</p>
          <h3>XMP服务隐私政策</h3>
          <p>1．介绍</p>
          <p>
            本隐私政策适用于下列情形下XMP的个人信息处理行为：（i）XMP的商业合作伙伴使用XMP服务或与XMP服务交互；或（ii）终端用户（“用户”）使用XMP的商业合作伙伴提供的平台、应用、网站或其他服务。
          </p>
          <p>
            XMP为商业合作伙伴提供一站式跨渠道智能投放服务，基于该等服务之目的，商业合作伙伴将委托我们收集和使用与用户有关的个人信息。在与商业合作伙伴进行商务沟通的过程中，XMP也会处理商业合作伙伴的联系人的个人信息。
          </p>
          <p>
            除本隐私政策另有明确说明外，在提供服务的过程中，XMP系作为受托处理者，严格根据我们的商业合作伙伴委托我们的处理目的、处理方式处理您的个人信息；我们的商业合作伙伴作为个人信息处理者，自主决定该等个人信息处理活动的处理目的、处理方式。
          </p>
          <p>
            XMP服务可能链接到第三方的网站、应用或其他第三方的内容。请注意我们无法控制第三方对用户信息的处理，以及第三方可能存在不同于本隐私政策所述的隐私保护规定。
          </p>
          <p>&nbsp;</p>
          <p>2．我们如何收集用户的信息</p>
          <p>
            我们通过我们的商业合作伙伴收集信息：（1）我们的商业合作伙伴可能会将用户的信息传输给我们；（2）当我们基于商业合作伙伴的授权和/或委托接入第三方平台（例如：推广平台、聚合平台、变现平台、归因平台），第三方平台可能会向我们传输用户的信息。
          </p>
          <p>
            为了向我们的商业合作伙伴提供XMP服务，我们通过我们的商业合作伙伴收集其联系人的信息。我们委托我们的商业合作伙伴代表我们获得其联系人就我们作为个人信息处理者收集和使用其个人信息的有效同意。
          </p>
          <p>
            就上述个人信息的收集和有效同意的获取，我们已在与我们的商业合作伙伴的合作协议中要求其承诺并保证个人信息来源的合法性，且其将根据适用法律的要求向个人告知我们处理个人信息的具体情况，并获得个人的有效同意（必要时获取适用法律要求的单独同意），且保证个人可以随时撤销该等同意。
          </p>
          <p>&nbsp;</p>
          <p>3．我们可能会从用户那里收集的信息</p>
          <p>
            （1）
            当我们向我们的商业合作伙伴提供XMP，我们可能会收集用户的如下信息：
          </p>
          <p>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;a.&nbsp;&nbsp;&nbsp;我们的商业合作伙伴向我们传输的用户信息：国际移动设备识别码（IMEI），苹果IDFA、IDFV，安卓ID、GAID、OAID，MAC地址；IP地址；广告行为数据，如花费，展示，点击，转化，收入，留存等；
          </p>
          <p>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;b.&nbsp;&nbsp;&nbsp;我们基于商业合作伙伴的授权和/或委托接入的第三方平台向我们传输的用户信息：国际移动设备识别码（IMEI），苹果IDFA、IDFV，安卓ID、GAID、OAID，MAC地址；IP地址；广告行为数据，如花费，展示，点击，转化，收入，留存等。
          </p>
          <p>
            （2）
            我们还可能收集我们商业合作伙伴的联系人的姓名、职务、电子邮件地址、帐户名和密码、IP地址和联系地址。
          </p>
          <p>&nbsp;</p>
          <p>4．关于敏感个人信息</p>
          <p>
            敏感个人信息通常是指一旦泄露或者非法使用，容易导致自然人的人格尊严受到侵害或者人身、财产安全受到危害的个人信息，其范围根据不同适用法律的规定会有所不同。
          </p>
          <p>
            未经您的有效同意，我们不会收集您的敏感个人信息。我们的商业合作伙伴将在向您充分告知处理敏感个人信息的必要性以及对个人权益的影响并依法征得您的同意后，才会向我们传输或授权我们处理您的敏感个人信息。
          </p>
          <p>&nbsp;</p>
          <p>5．我们如何使用用户的个人信息</p>
          <p>我们按照如下方式使用用户的个人信息：</p>
          <div>
            <table>
              <tr>
                <th colSpan={2}>
                  <p>个人信息的种类</p>
                </th>
                <th>
                  <p>使用方式</p>
                </th>
              </tr>
              <tr>
                <th>
                  <p>用户信息</p>
                </th>
                <th>
                  <p>包括本隐私政策第3条第（1）项所描述的所有个人信息类型</p>
                </th>
                <th>
                  <p>
                    我们作为受托处理者，通过对来源于我们的商业合作伙伴、第三方平台的广告数据进行分析、计算，协助我们的商业合作伙伴了解其广告投放的效果，以便其进一步优化广告投放策略。
                  </p>
                </th>
              </tr>
              <tr>
                <th>
                  <p>商业代表信息</p>
                </th>
                <th>
                  <p>包括本隐私政策第3条第（2）项所描述的所有个人信息类型</p>
                </th>
                <th>
                  <p>
                    我们作为个人信息处理者，将个人信息用于与我们的商业合作伙伴的代表进行商业联系或向他们进行直接营销（如发送营销邮件）以及在他们登录XMP网站和我们的关联公司网站时进行身份验证。
                  </p>
                </th>
              </tr>
              <tr>
                <th>
                  <p>敏感个人信息</p>
                </th>
                <th>
                  <p>/</p>
                </th>
                <th>
                  <p>未经您的同意，我们不会收集您的敏感个人信息。</p>
                  <p>
                    我们的商业合作伙伴将在向您充分告知处理敏感个人信息的必要性以及对个人权益的影响并依法征得您的同意后，才会向我们传输并授权我们处理您的敏感个人信息。
                  </p>
                </th>
              </tr>
            </table>
          </div>
          <p>&nbsp;</p>
          <p>6．我们处理个人信息的法律基础</p>
          <p>
            同意。根据适用法律的要求，我们通过合同约定由我们的商业合作伙伴代表我们就本隐私政策所描述的处理行为征得用户或其员工的同意。用户或其员工可以随时撤销同意。
          </p>
          <p>&nbsp;</p>
          <p>7．共享、委托处理、转让、公开披露、传输用户的个人信息</p>
          <p>（1）共享</p>
          <p>
            除非经过用户的授权同意，我们不会向其他第三方共享用户的个人信息。
          </p>
          <p>（2）委托处理</p>
          <div>
            <table>
              <tr>
                <th>
                  <p>第三方类型</p>
                </th>
                <th>
                  <p>第三方名称联系方式</p>
                </th>
                <th>
                  <p>信息类型</p>
                </th>
                <th>
                  <p>处理目的及处理方式</p>
                </th>
              </tr>
              <tr>
                <th>
                  <p>云服务提供商</p>
                </th>
                <th>
                  <p>
                    详见
                    <a
                      href="https://xmp.mobvista.com/official/one-pager/Cloud+Technology+Service+Providers.pdf"
                      target="_blank"
                    >
                      云服务提供商
                    </a>
                  </p>
                </th>
                <th>
                  <p>本隐私政策第3条提到的所有信息类型</p>
                </th>
                <th>
                  <p>①用于存储数据；</p>
                  <p>②响应数据计算和存储请求；</p>
                  <p>③用于保证系统和流程的正确和适当地运行</p>
                </th>
              </tr>
            </table>
          </div>
          <p>
            除本隐私政策明确表述之外，我们不会委托其他第三方处理用户的个人信息。
          </p>
          <p>（3）转让</p>
          <p>
            我们不会将用户的个人信息转移给任何公司、组织和个人，但以下情况除外：
          </p>
          <p>
            a.事先告知用户转移个人信息的种类、目的、方式和范围，并获得用户的单独同意；
          </p>
          <p>
            b.如涉及合并、分立、解散、被宣告破产等原因需要转移个人信息的，我们会向用户告知接收方的名称或者姓名和联系方式，并要求接收方继续履行个人信息处理者的义务。接收方变更原先的处理目的、处理方式的，我们会要求接收方重新取得用户的同意。
          </p>
          <p>（4）公开披露</p>
          <p>我们不会公开披露终端用户的个人信息，但以下情况除外：</p>
          <p>
            a.告知用户公开披露的个人信息的种类、目的、方式和范围并获得用户的单独同意后；
          </p>
          <p>b.在法律法规、法律程序、诉讼或政府主管部门强制要求的情况下。</p>
          <p>（5）国际传输</p>
          <p>
            如果您位于欧洲经济区内，从您收集到的信息，包括通过我们的XMP服务收集到的信息，将被传输到位于欧洲经济区以外、未被欧盟委员会认定为是对个人信息提供了适当程度保护措施的国家，包括中国和新加坡。XMP将遵守任何适用的法律，以确保国际信息传输的合规性。
          </p>
          <p>
            为了将个人信息传输至XMP的关联公司，XMP已经签订《欧盟标准合同条款》（“标准合同”）。您可以通过发送邮件到
            <a href="mailto:privacy@mobvista.com">privacy@mobvista.com</a>
            要求获得一份标准合同的副本。
          </p>
          <p>&nbsp;</p>
          <p>8．您的信息权利</p>
          <p>
            您有权获取、修改、修订或删除我们可能已经收集的您的个人信息。如需行使相关权利，请联系
            <a href="mailto:privacy@mobvista.com">privacy@mobvista.com</a>。
          </p>
          <p>&nbsp;</p>
          <p>
            如您位于欧洲经济区内，您将享有以下权利（受少数例外限制）：（i）要求查阅、修改或删除您的个人信息；（ii）限制或拒绝处理您的个人信息；以及（iii）要求向他人或第三方提供个人信息的电子版副本。同时，您有权就您的个人信息处理问题向欧洲经济区当地的数据保护部门发起投诉。
          </p>
          <p>&nbsp;</p>
          <p>
            如果您是美国加利福尼亚州的居民，您可以询问我们在过去12个月内收集和使用您的个人资料的情况，具体包括：
          </p>
          <p>
            <p>- 我们已经收集到的个人信息的种类。</p>
            <p>- 我们收集个人信息的来源的种类。</p>
            <p>- 我们收集、销售和/或共享个人信息的商事或商业目的。</p>
            <p>- 与我们披露个人信息的第三方的种类。</p>
            <p>- 我们为商业目的出售、共享或披露的个人信息种类。</p>
            <p>- 我们为商业目的向其出售、共享或披露的个人信息的第三方。</p>
            <p>
              -
              您的权利。您可以要求我们提供在过去12个月内收集的有关您的个人资料的副本。
            </p>
            <p>- 您可以要求我们删除我们从您处收集到的个人信息。</p>
            <p>- 您可以要求我们更正您的个人信息。</p>
          </p>
          <p>如CCPA所规定的，您有权不受歧视地行使上述权利。</p>
          <p>
            您可以（i）通过向privacy@mobvista.com发送书面请求或者向以下地址寄送书面文件：中国广东省广州市天河区兴民路222号之三4312房法务部，以行使您上述加州隐私权利；（ii）点击以下链接，要求禁止出售或共享您的数据：
            <a
              href="https://xmp.mobvista.com/official/one-pager/do-not-sell-my-data-XMP.pdf"
              target="_blank"
            >
              请勿出售我的数据
            </a>
            ；（iii）通过向
            <a href="mailto:privacy@mobvista.com">privacy@mobvista.com</a>
            发送书面请求要求限制使用您的敏感个人信息。
          </p>
          <p>为免疑义，我们不会出售、共享（根据CCPA的定义）您的个人信息。</p>
          <p>&nbsp;</p>
          <p>
            如果您是美国弗吉尼亚州的居民，自2023年1月1日起，您可以向我们行使以下个人信息权利：
          </p>
          <p>
            <p>- 确认我们是否正在处理您的个人信息，并访问这些个人信息；</p>
            <p>- 纠正您个人信息中的不准确之处；</p>
            <p>- 删除您的个人信息；</p>
            <p>- 获得您提供给我们的个人信息的副本；以及</p>
            <p>
              -
              要求拒绝我们为以下目的处理您的个人信息：(i)个性化广告，(ii)出售您的个人信息，或(iii)对您产生重大法律或类似影响的用户画像。
            </p>
          </p>
          <p>如VCDPA所规定的，您有权不受歧视地行使上述权利。</p>
          <p>
            您可以通过向
            <a href="mailto:privacy@mobvista.com">privacy@mobvista.com</a>
            发送书面请求，以行使您上述弗州个人信息权利。如果您行使上述权利时未能提供有效的信息以供我们进行有效的反馈等，我们可能会拒绝您的权利行使，如果您有不同意见的，可以进一步向
            <a href="mailto:privacy@mobvista.com">privacy@mobvista.com</a>
            发送书面申诉。
          </p>
          <p>
            为免疑义，我们不会出售您的个人信息，或使用您的个人信息进行个性化广告、用户画像。
          </p>
          <p>&nbsp;</p>
          <p>
            如果您是美国科罗拉多州的居民，自2023年7月1日起，您可以向我们行使以下个人信息权利：
          </p>
          <p>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;a.&nbsp;&nbsp;&nbsp;访问您的个人信息；
          </p>
          <p>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;b.&nbsp;&nbsp;&nbsp;纠正您个人信息中的不准确之处；
          </p>
          <p>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;c.&nbsp;&nbsp;&nbsp;删除您的个人信息；
          </p>
          <p>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;d.&nbsp;&nbsp;&nbsp;获得您提供给我们的个人信息的副本；以及
          </p>
          <p>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;e.&nbsp;&nbsp;&nbsp;要求拒绝我们为以下目的处理您的个人信息：(i)个性化广告，(ii)出售您的个人信息，或(iii)对您产生重大法律或类似影响的用户画像。
          </p>
          <p>如CPA所规定的，您有权不受歧视地行使上述权利。</p>
          <p>
            您可以通过向
            <a href="mailto:privacy@mobvista.com">privacy@mobvista.com</a>
            发送书面请求，以行使您上述科罗拉多州个人信息权利。如果您行使上述权利时未能提供有效的信息以供我们进行有效的反馈等，我们可能会拒绝您的权利行使，如果您有不同意见的，可以进一步向privacy@mobvista.com发送书面申诉。
          </p>
          <p>
            为免疑义，我们不会出售您的个人信息，或使用您的个人信息进行个性化广告、用户画像。
          </p>
          <p>&nbsp;</p>
          <p>
            如果您是美国康涅狄格州的居民，自2023年7月1日起，您可以向我们行使以下个人信息权利：
          </p>
          <p>1.确认我们是否正在处理您的个人信息，以及访问您的个人信息；</p>
          <p>2.纠正您个人信息中的不准确之处；</p>
          <p>3.删除您的个人信息；</p>
          <p>4.获得您提供给我们的个人信息的副本；以及</p>
          <p>
            5.拒绝我们为以下目的处理您的个人信息：(i)个性化广告，(ii)出售您的个人信息，或(iii)对您产生重大法律或类似影响的自动化用户画像。
          </p>
          <p>如CDPA所规定的，您有权不受歧视地行使上述权利。</p>
          <p>
            您可以通过向
            <a href="mailto:privacy@mobvista.com">privacy@mobvista.com</a>
            发送书面请求，以行使您上述康涅狄格州个人信息权利。如果您行使上述权利时未能提供有效的信息以供我们进行有效的反馈等，我们可能会拒绝您的权利行使，如果您有不同意见的，可以进一步向
            <a href="mailto:privacy@mobvista.com">privacy@mobvista.com</a>
            发送书面申诉。
          </p>
          <p>
            为免疑义，我们不会出售您的个人信息，或使用您的个人信息进行个性化广告、用户画像。
          </p>
          <p>&nbsp;</p>
          <p>
            如果您是美国犹他州的居民，自2023年12月31日起，您可以向我们行使以下个人信息权利：
          </p>
          <p>1.确认我们正在处理您的个人信息，并访问您的个人信息；</p>
          <p>2.删除您的个人信息；</p>
          <p>3.获得您提供给我们的个人信息的副本；以及</p>
          <p>
            4.要求我们停止为以下目的处理您的个人信息：(i)个性化广告，(ii)出售您的个人信息。
          </p>
          <p>如UCPA所规定的，您有权不受歧视地行使上述权利。</p>
          <p>
            您可以通过向
            <a href="mailto:privacy@mobvista.com">privacy@mobvista.com</a>
            发送书面请求，以行使您上述犹他州个人信息权利。如果您行使上述权利时未能提供有效的信息以供我们进行有效的反馈等，我们可能会拒绝您的权利行使，如果您有不同意见的，可以进一步向
            <a href="mailto:privacy@mobvista.com">privacy@mobvista.com</a>
            发送书面申诉。
          </p>
          <p>
            为免疑义，我们不会出售您的个人信息，或使用您的个人信息进行个性化广告、用户画像。
          </p>
          <p>&nbsp;</p>
          <p>
            如果您是中华人民共和国大陆地区的用户，除非法律、行政法规另有规定，您对个人信息的处理享有以下权利：
          </p>
          <p>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;a.&nbsp;&nbsp;&nbsp;知情权、决定权、有权限制或拒绝对您的个人信息进行处理。
          </p>
          <p>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;b.&nbsp;&nbsp;&nbsp;知除法律规定的特殊情况以外，您有权查阅、复制您的个人信息。
          </p>
          <p>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;c.&nbsp;&nbsp;&nbsp;您有权请求将您的个人信息转移至您指定的个人信息处理者。
          </p>
          <p>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;d.&nbsp;&nbsp;&nbsp;如您发现我们处理的关于您的个人信息不准确或者不完整的，您有权请求更正、补充。
          </p>
          <p>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;e.&nbsp;&nbsp;&nbsp;在下列情形下，您有权请求删除您的个人信息：
          </p>
          <p>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(i)处理目的已实现，无法实现或者为实现处理目的不再必要；
          </p>
          <p>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(ii)XMP停止提供产品或服务，或者保存期限已届满；
          </p>
          <p>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(iii)您撤回同意；
          </p>
          <p>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(v)法律、行政法规规定的其他情形。
          </p>
          <p>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(vi)如删除您的个人信息从技术上难以实现的，XMP将停止除存储和采取必要的安全保护措施之外的处理。
          </p>
          <p>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(i)处理目的已实现，无法实现或者为实现处理目的不再必要；
          </p>
          <p>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;f.&nbsp;&nbsp;&nbsp;您有权要求我们对本隐私政策进行解释说明。
          </p>
          <p>&nbsp;</p>
          <p>
            如果您对您的信息权利有任何疑问或请求，您可以通过发送邮件至
            <a href="mailto:privacy@mobvista.com">privacy@mobvista.com</a>
            联系我们。
          </p>
          <p>
            当您撤回同意后，我们将不再处理您的个人信息。但您撤回同意的决定，不会影响此前基于您的授权而开展的个人信息处理。
          </p>
          <p>&nbsp;</p>
          <p>9．用户个人信息的安全</p>
          <p>
            保护您的个人信息安全是我们的首要任务。为了防止您的个人信息未经授权而被获取、使用、披露或销毁，我们将采取严格的安全保护措施。我们会采取符合相关法律和行业标准的物理、技术以及行政管理措施。例如，我们会使用防火墙、加密技术以及其他为防止欺诈和身份窃取而设计的自动化软件；我们只将信息储存在能够提供较高保护程度的设备和场所。我们也会通过视频以及其他电子监控方式对周边以及建筑物入口所发生的数据访问实施严格监控。
          </p>
          <p>
            我们会尽量减少敏感信息在我们服务器的存放以保护您的隐私。我们也会从合同上要求合作伙伴保护用户数据。
          </p>
          <p>
            通常情况下，除非可适用法律另有要求，否则XMP将保留信息一（1）年。决定保留期限的标准包括：决定保存期限的标准包括：（i）为提供服务以及业务运营而需保留个人信息的时长；（ii）收集的个人信息的类型；以及（iii）我们是否会受到法律、合同或其他类似义务的约束而需保留数据（例如：强制性的数据保留法律、政府要求保留与调查相关的数据或为了诉讼或争议所必须保留的数据）。
          </p>
          <p>&nbsp;</p>
          <p>10．儿童</p>
          <p>
            我们保证遵守《儿童在线隐私保护法》（COPPA）以及任何可适用的儿童相关法律。此外，我们不会在知情的情况下收集我们已知悉为特定年龄（由可适用的儿童相关法律规定）以下的儿童的任何个人信息。
          </p>
          <p>
            如果父母或者监护人发现子女未经同意向我们提供了其个人信息，父母或监护人应当及时联系我们。我们会采取合理的措施保证这类信息从我们的文档中删除。
          </p>
          <p>&nbsp;</p>
          <p>11．推广营销</p>
          <p>
            我们可能定期通过邮件向您提供关于我们产品、服务的信息以及您可能感兴趣的内容。如果相关法律要求我们在向您发出营销信息之前须获得您的同意，则我们仅会在征得您同意后向您发出相关的营销信息。
          </p>
          <p>
            如您不希望收到此类营销信息，您可以随时通过营销信息中提供的指引取消订阅或者通过发送邮件到
            <a href="mailto:privacy@mobvista.com">privacy@mobvista.com</a>
            要求不再接收此类信息。
          </p>
          <p>&nbsp;</p>
          <h3>XMP网站隐私政策</h3>
          <p>1．介绍</p>
          <p>
            本XMP网站隐私政策适用于网站的信息收集和使用，包括您浏览和使用XMP网站时XMP收集和使用的信息。根据GDPR，XMP是您的个人信息的数据控制方。
          </p>
          <p>&nbsp;</p>
          <p>2．我们收集的信息</p>
          <p>
            当您访问XMP网站时，您主动提供的个人信息（例如：姓名、出生日期、个人身份证号码、地址、电话号码、邮箱地址、职业、教育背景、工作经历以及其他相关信息）将会被收集，部分您未主动提供的信息，例如浏览器类型、域名、IP地址、浏览过的网页以及使用不同技术和方法浏览网页的时间时长，包括IP地址、Cookies、互联网标签和浏览数据的收集。为了提高我们的服务质量，我们需要您的个人信息以实现您的个人需求以及提供更优质的服务。XMP有权采取措施验证您所提供的信息的准确性。如果您提供的信息是他人的个人信息，您同意并保证该等行为已经获得他人的允许。
          </p>
          <p>&nbsp;</p>
          <p>3．我们如何使用您的个人信息</p>
          <p>
            我们处理您的个人信息的合法性基础是您的同意。您向我们提供的个人信息可能用于下列目的（i）为您提供XMP的产品、服务或相关信息；（ii）为您注册报名相关活动或者培训；（iii）向您发送我们或我们关联公司的营销信息；（iv）联系并回复您的请求和咨询；（v）商业管理，包括数据分析；（vi）使用网站时，为您提供个性化访问服务以及帮助；或（vii）帮助我们了解我们的用户，完善我们的网站。
          </p>
          <p>
            我们会定期通过邮件向您提供我们产品、服务的信息以及您可能感兴趣的内容。如果相关法律要求，我们在向您发出营销信息之前须经您同意，则我们仅会在征得您的同意后向您发出相关的营销信息。
          </p>
          <p>
            如果您不希望收到此类营销宣传，您可以随时通过营销信息中提供的指引取消订阅或者通过发送邮件到
            <a href="mailto:privacy@mobvista.com">privacy@mobvista.com</a>
            要求不再接收此类宣传信息。
          </p>
          <p>&nbsp;</p>
          <p>4．披露您的个人信息</p>
          <p>
            我们聘用第三方为我们提供服务，对XMP网站的开展以及运营提供协助，包括为我们的
            <a
              href="https://xmp.mobvista.com/official/one-pager/Website+Technology+Service+Providers.pdf"
              target="_blank"
            >
              云服务器提供商
            </a>
            ，网站分析服务提供商（详见我们的Cookie政策），以及
            <a
              href="https://xmp.mobvista.com/official/one-pager/Third-party+Technology+Service+Providers.pdf"
              target="_blank"
            >
              邮件服务提供商
            </a>
            ，该等第三方可能会获取您的个人信息。受限于本隐私政策规定的目的，我们也可能向我们的关联公司共享您的个人信息。
          </p>
          <p>
            我们还聘用了第三方商业数据服务商（“AroundDeal”）为我们提供企业联系人批注[Legal6]:跳转pdf，见跳转文档中“xmp邮件服务商”服务，以向网站访客发送营销信息。在经您明确授权同意的前提下，我们会将您的IP地址、访问网页时间、访问页面链接传输给AroundDeal，通过AroundDeal的企业联系人数据库进行信息匹配，并依据匹配结果，AroundDeal可能会向我们提供您的姓名、电子邮件、职位、所在地区、公司名称、公司行业、领英账号，并且代表我们向您发送营销信息。如您需要了解AroundDeal的信息以及其隐私政策，请见
            <a href="https://www.arounddeal.com/privacy-center/privacy-policy"               target="_blank"
            >
              此处
            </a>
            。
          </p>
          <p>
            如果我们认为您企图或者已经滥用或损害网站，我们将会通过调查并与相关执法部门合作，以维护我们的合法权益。
          </p>
          <p>
            同时，我们将根据法律的要求或允许以配合法院传票、其他类似的法律程序或政府的要求而提供您的个人信息；或我们认为，按照法律规定，或为维护我们及/或其他第三方（包括顾问、执法部门、司法监督部门）的合法权益及安全，该等信息披露是必要的。我们也可能将您的个人信息传输给收购我们部分或全部资产、股份或以合并、收购、重组或其他方式继受取得我们部分或全部业务的第三方。
          </p>
          <p>&nbsp;</p>
          <p>5．国际传输</p>
          <p>
            如果您位于欧洲经济区内，从您收集到的个人信息，包括从XMP网站收集到的信息，会被传输到位于欧洲经济区以外的国家（包括XMP网站服务器所在的国家新加坡）。您在此明确同意将您的个人信息按本隐私政策所述传输给位于欧洲经济区以外的接收方，包括传输给XMP、XMP的关联公司及本隐私政策规定的其他接收方。
          </p>
          <p>
            为了将个人信息传输给XMP的关联公司，XMP已经签订《欧盟标准合同条款》（“标准合同”）。您可以通过发送邮件到
            <a href="mailto:privacy@mobvista.com">privacy@mobvista.com</a>
            请求获得一份标准合同的副本。为了将个人信息传输给为了将个人信息传输给AroundDeal，XMP已经与AroundDeal签订《欧盟标准合同条款》（“AroundDeal标准合同”）。您可以通过发送邮件到
            <a href="mailto:privacy@mobvista.com">privacy@mobvista.com</a>
            请求获得一份AroundDeal标准合同的副本。
          </p>
          <p>
            如您位于中华人民共和国大陆地区，我们根据本政策收集的关于您的个人信息，将存储在中华人民共和国大陆地区。我们不会将您的个人信息传输至境外。
          </p>
          <p>&nbsp;</p>
          <p>6．您的信息权利</p>
          <p>
            您有权获取、修改、修订或删除我们可能已经收集的您的个人信息。如需行使相关权利，请联系
            <a href="mailto:privacy@mobvista.com">privacy@mobvista.com</a>。
          </p>
          <p>
            如您位于欧洲经济区内，您将享有以下权利（受少数例外限制）：（i）要求查阅、修改或删除您的个人信息；（ii）限制或拒绝处理您的个人信息；以及（iii）要求向他人或第三方提供个人信息的电子版副本。同时，您有权就您的个人信息处理问题向欧洲经济区当地的数据保护部门发起投诉。
          </p>
          <p>
            如果您是美国加利福尼亚州的居民，您可以询问我们在过去12个月内收集和使用您的个人资料的情况，具体包括：
          </p>
          <p>
            <p>- 我们已经收集到的个人信息的种类。</p>
            <p>- 我们收集个人信息的来源的种类。</p>
            <p>- 我们收集、销售和/或共享个人信息的商事或商业目的</p>
            <p>- 与我们披露个人信息的第三方的种类。</p>
            <p>- 我们为商业目的出售、共享或披露的个人信息种类。</p>
            <p>- 我们为商业目的向其出售、共享或披露的个人信息的第三方。</p>
            <p>
              -
              您的权利。您可以要求我们提供在过去12个月内收集的有关您的个人资料的副本。
            </p>
            <p>- 您可以要求我们删除我们从您处收集到的个人信息。</p>
            <p>- 您可以要求我们更正您的个人信息。</p>
          </p>
          <p>如CCPA所规定的，您有权不受歧视地行使上述权利。</p>
          <p>
            您可以（i）通过向
            <a href="mailto:privacy@mobvista.com">privacy@mobvista.com</a>
            发送书面请求或者向以下地址寄送书面文件：中国广东省广州市天河区兴民路222号之三4312房法务部，以行使您上述加州隐私权利；
            （ii）通过向
            <a href="mailto:privacy@mobvista.com">privacy@mobvista.com</a>
            发送书面请求要求限制使用您的敏感个人信息。
          </p>
          <p>为免疑义，我们不会出售、共享（根据CCPA的定义）您的个人信息。</p>
          <p>&nbsp;</p>
          <p>
            如果您是美国弗吉尼亚州的居民，自2023年1月1日起，您可以向我们行使以下个人信息权利：
          </p>
          <p>
            <p>- 确认我们是否正在处理您的个人信息，并访问这些个人信息；</p>
            <p>- 纠正您个人信息中的不准确之处；</p>
            <p>- 删除您的个人信息；</p>
            <p>- 获得您提供给我们的个人信息的副本；以及</p>
            <p>
              -
              要求拒绝我们为以下目的处理您的个人信息：(i)个性化广告，(ii)出售您的个人信息，或(iii)对您产生重大法律或类似影响的用户画像。
            </p>
          </p>
          <p>如VCDPA所规定的，您有权不受歧视地行使上述权利。</p>
          <p>
            您可以通过向
            <a href="mailto:privacy@mobvista.com">privacy@mobvista.com</a>
            发送书面请求，以行使您上述弗州个人信息权利。如果您行使上述权利时未能提供有效的信息以供我们进行有效的反馈等，我们可能会拒绝您的权利行使，如果您有不同意见的，可以进一步向
            <a href="mailto:privacy@mobvista.com">privacy@mobvista.com</a>
            发送书面申诉。
          </p>
          <p>
            未免疑义，我们不会出售您的个人信息，或使用您的个人信息进行个性化广告、用户画像。
          </p>
          <p>&nbsp;</p>
          <p>
            如果您是中华人民共和国大陆地区的公民，除非法律、行政法规另有规定，您对个人信息的处理享有以下权利：
          </p>
          <p>（1）知情权、决定权、有权限制或拒绝对您的个人信息进行处理。</p>
          <p>（2）除法律规定的特殊情况以外，您有权查阅、复制您的个人信息。</p>
          <p>（3）您有权请求将您的个人信息转移至您指定的个人信息处理者。</p>
          <p>
            （4）如您发现我们处理的关于您的个人信息不准确或者不完整的，您有权请求更正、补充。
          </p>
          <p>（5）在下列情形下，您有权请求删除您的个人信息：</p>
          <p>
            <p>- 处理目的已实现，无法实现或者为实现处理目的不再必要；</p>
            <p>- XMP停止提供产品或服务，或者保存期限已届满；</p>
            <p>- 您撤回同意；</p>
            <p>- XMP违反法律、行政法规或违反约定处理个人信息的；</p>
            <p>- 法律、行政法规规定的其他情形。</p>
          </p>
          <p>
            如删除您的个人信息从技术上难以实现的，我们将停止除存储和采取必要的安全保护措施之外的处理。
          </p>
          <p>&nbsp;</p>
          <p>7．您的个人信息安全</p>
          <p>
            我们承诺保护您的个人信息安全。我们采用多种保护技术和程序以保护您的个人信息，防止您的个人信息未经授权而被获取、使用或者披露。我们只会在必要情况下保留您的个人信息，对于在网站上无论以何种方式收集到的有关您的个人信息，我们只会保留一年。
          </p>
          <p>&nbsp;</p>
          <p>8．未成年人保护</p>
          <p>
            本网站不会故意收集未成年人的个人信息。您所提供的任何未成年人的个人信息须事先征得未成年人的父母或者监护人的同意。对未成年人的定义由适用本隐私政策的法律作出规定。
          </p>
          <p>&nbsp;</p>
          <p>9．使用Cookies和其他追踪技术</p>
          <p>
            当您访问XMP网站时，XMP可能会使用cookies或者其他技术（统称“Cookies”）来收集您的个人信息。想了解更多信息，请阅读我们的Cookie政策。
          </p>
          <p>&nbsp;</p>
          <p>10．外部网站和链接</p>
          <p>
            本网站可能会含有跳转到第三方供应商和其他第三方（“外部网站”）拥有或运营的网站的链接以及指示。XMP对外部网站的有效性以及外部网站的内容（包括任何外部网站的网络广播或其他任何形式传送接收的内容）均不承担责任。如果您对相关链接或外部网站上的内容有任何疑问，请联系该外部网站的管理人员。如第三方已告知XMP将链接XMP的主页至该第三方的网站且XMP并未拒绝的，则XMP允许该第三方链接到XMP的主页。XMP保留在任何时候单方撤销上述同意的权利，包括链接到其主页的链接。本网站提供的任何外部网站仅仅是为您提供方便。本网站包含的任何链接或指示并不意味着对该外部网站的认可。
          </p>
          <p>&nbsp;</p>
          <p>11．版权</p>
          <p>
            所有的网站内容、设计、文本、图表、图像、标识、按钮、图标、界面、音频及视频、以及以上内容的所有排列组合均为XMP或其相应内容提供商的专属财产，受香港特别行政区和国际著作权法保护。网站所使用的所有软件为XMP或其相应的软件供应商的财产，受香港特别行政区以及国际著作权法和其他相关法律和条约保护。网站用户不得发送/上传任何侵犯他人著作权或其他知识产权的内容至本网站。
          </p>
          <p>&nbsp;</p>
          <p>12．商标</p>
          <p>
            网站使用的所有商标、服务标识和商业名称（统称“标识”）均为XMP独有。该等标识不得用于任何非XMP的产品或服务，或任何导致消费者混淆、或以任何方式诋毁或败坏XMP名声的其他产品或服务上。
          </p>
          <p>&nbsp;</p>
          <p>13．在线行为</p>
          <p>
            如XMP认为任何个人限制或禁止他人使用本网站，XMP有权对该等行为予以禁止。您同意仅以合法目的使用本网站，并同意不实施上述禁止的行为。您同意不会上传或转发任何违法的、损害他人利益的、威胁性的、侮辱性的、滋扰性的、诽谤的、粗俗的、淫秽的、具有明显的性暗示的、亵渎的、仇恨的、种族歧视的、地域歧视的或其他任何类型的不良信息至本网站，包括但不限于任何煽动违法行为的信息，例如任何构成刑事犯罪、民事责任或其他违反适用的地方的、州的、国内的或国际的法律的行为。
          </p>
          <p>&nbsp;</p>
          <p>14．终止使用</p>
          <p>
            基于任何违反相关法律、本隐私政策或损害其他用户、第三方、零售商、赞助商、授权方、服务提供者或网站利益的行为，XMP可自行决定终止或暂停您对网站的全部或部分访问而无须另行通知或承担任何责任。
          </p>
          <p>
            鉴于网络技术的飞速发展，我们会不定期更新本隐私政策。所有的修订会在网站
            <a href="https://xmp.mobvista.com/">https://xmp.mobvista.com/</a>
            公告。
          </p>
          <p>&nbsp;</p>
          <h3>XMP Cookies政策</h3>
          <h4>
            <strong>Cookies是什么？</strong>
          </h4>
          <p>
            Cookies是网站在你所浏览的设备上保存的小文本文件。它们在你的网站浏览器上被保存和处理，并在你下次浏览该网站时被读取。
          </p>
          <p>通常而言，基于cookies的使用目的，划分为以下四类cookies：</p>
          <p>
            必要型cookies。这些cookies对于你浏览网站并使用它的功能（如访问网站的安全区域）来说是必要的。
          </p>
          <p>
            偏好型cookies，又被称为功能型cookies。这些cookies允许网站记住你过去的设置，如你选择的语言、你希望天气预告所显示的区域，或者你的用户姓名和密码以便下次可自动登陆。
          </p>
          <p>
            分析型cookies，又被称为性能型cookies。这些cookies收集与你使用网站相关的信息，如你访问了哪个网页以及你点击了哪个链接。它们的目的仅仅是为了增强网站功能。只要这些cookies仅仅是为了你所浏览的网站的所用者使用，它们可能包括第三方提供的分析服务。
          </p>
          <p>
            营销型cookies。这些cookie会追踪你的网上活动轨迹以帮助广告主向你投放相关的广告或者限制你浏览广告的次数。
          </p>
          <p>&nbsp;</p>
          <h4>
            <strong>XMP使用的cookies是什么类型的cookies?</strong>
          </h4>
          <p>XMP仅为以下目的使用以下两类cookies：</p>
          <p>
            偏好型cookies。XMP使用偏好型cookies仅为了记住你过去设置（如语言），以便优化对你展示的信息。
          </p>
          <p>
            分析型cookies。XMP还会使用第三方谷歌分析（Google
            Analytics）的cookies以帮助我们了解我们的网站如何被使用。谷歌分析（Google
            Analytics）收集第一方cookies、与设备或浏览器相关的信息、IP地址或网站/应用内活动用以统计和汇报用户与网站的交互。谷歌向XMP提供谷歌分析（Google
            Analytics）的统计服务。关于谷歌分析（Google
            Analytics）的cookies的更多信息，请阅读此处 。
          </p>
          <p>&nbsp;</p>
          <h4>
            <strong>退出</strong>
          </h4>
          <p>
            如你不同意我们为保存你浏览网页时的语言设置而使用的cookies，请浏览
            <a href="http://www.allaboutcookies.org">www.allaboutcookies.org</a>
            获得关于管理和删除cookies的指引并根据指引进行设置。
            如你不同意谷歌分析的cookies，请点击此处进行设置。
          </p>
          <p>&nbsp;</p>
          <h4>
            <strong>联系我们</strong>
          </h4>
          <p>
            如您对本隐私政策有任何疑问或者建议，请通过
            <a href="mailto:privacy@mobvista.com">privacy@mobvista.com</a>
            联系XMP数据保护官。您也可以联系我们在欧洲经济区的数据保护代表Eurocore
            B.V，地址为：
          </p>
          <p>Hullenbergweg 278, 1101BV Amsterdam</p>
          <p>
            请确保附上您的姓名（如果您是代表公司咨询的，请附上公司的全称），地址、电子邮箱或其他联系方式，以便我们能够及时回复您的信息。
          </p>
          <p>&nbsp;</p>
        </div>
      </div>
    </div>
  );
}

export default PrivateCN;
