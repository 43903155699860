import { images, content } from "./config";
import QRCode from '../../assets/images/Affiliate/二维码.png'
import "./index.scss";


function Image({ index }) {
  return (
      <div className="image">
        <img className={`image${index}`} src={images[index]} key={index} alt="插图"/>
      </div>
  )
}

function Layout({ title, text, imgIndex, children }) {
  return (
      <div className={`wrapper wrapper${imgIndex}`}>
        <div className="text_content">
          <h1>{title}</h1>
          <p className="text_p">{text}</p>
          <div className="add_content">{children}</div>
        </div>
        <Image index={imgIndex}/>
      </div>
  )
}
function Qualification() {
  return (
      <div className="qualification">
        <h2>{content.qualification.title}</h2>
        <ul>
          {content.qualification.list.map((item, index) => (
              <li key={index}>
                <Image index={item.image} />
                <p>{item.text}</p>
              </li>
          ))}
        </ul>
      </div>
  )
}
function Join() {
  return (
      <div className="join">
        <h2>{content.join.title}</h2>
        <ul>
          {content.join.list.map((text, index) => (
              <li className="join_item" key={index}>
                <div className="join_step">0{index+1}</div>
                <p>{text}</p>
              </li>
          ))}
        </ul>
        <div className="qrcode">
          <img src={QRCode} alt="qrcode"/>
        </div>
      </div>
  )
}
function Affiliate() {
  return (
      <div className="affiliate">
        <main>
          <Layout title={content.plan.title} text={content.plan.text} imgIndex={0}>
            <a className="plan_button" href={content.joinUrl} target="_blank" rel="noreferrer">{content.plan.add.button}</a>
          </Layout>
          <div className="grey_wrapper"><Qualification /></div>
          <Layout title={content.amount.title} text={content.amount.text} imgIndex={4}>
            <p>{content.amount.add.p1}</p>
            <p className="tip">{content.amount.add.p2}</p>
          </Layout>
          <div className="grey_wrapper">
            <Layout title={content.time.title} text={content.time.text} imgIndex={5} />
          </div>
          <Join />
          <div className="grey_wrapper">
            <Layout title={content.attention.title} text={content.attention.text} imgIndex={6}>
              {content.attention.add.p.map((item, index) => (
                  <p key={index}>{item}</p>
              ))}
            </Layout>
          </div>
        </main>
      </div>
  );
}
export default Affiliate;
