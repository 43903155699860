import React, { useEffect, useRef, useState } from "react";
import "./index.scss";
import { ReactComponent as Arrow } from "./Arrow.svg";

function IncNumber(props) {
  const IncId = useRef(null);
  const { text, color, down, type1 } = props;
  const [count, setCount] = useState(0);
  const [type, setType] = useState("");

  useEffect(() => {
    let [num, type] = filiterStr(text);
    setCount(num);  // 直接将 num 赋给 count，没有动画效果
    setType(type);
  }, [text]);

  const filiterStr = (str) => {
    str = str.split("");
    let posPattern = /^\d+$/;
    let index = str.findIndex((item) => posPattern.test(item) === false);
    let num = str.slice(0, index).join("");
    let type = str.slice(index).join("");
    return [Number(num), type];
  };

  return (
    <div ref={IncId} className={["IncNumber", color, type1].join(" ")}>
      <h3 className="content font-num">
        {count}
        <span>{type}</span>
      </h3>
      <div className={["icon_wrapper", down && "down"].join(" ")}>
        <Arrow />
      </div>
    </div>
  );
}

export default IncNumber;
