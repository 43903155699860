import React, { useRef, useState,useEffect } from "react";
import { useTranslation } from "react-i18next";
import "./index.scss";
import Tag from "../Tag";
import { ReactComponent as Up } from "./Up.svg";
import { ReactComponent as Down } from "./Down.svg";
  
function FilterBox(props) {
    const { t } = useTranslation();
    const {label,options,filterType,currentTag,onDone} = props;
    const [isOpen, setIsOpen] = useState(false);
    const [selectedTags, setselectedTags] = useState(currentTag);
    const filterBoxRef = useRef();

    const handleTagClick = (tag) => {
      if (!selectedTags.includes(tag)) {
        setselectedTags([...selectedTags, tag]);
      }else {
        handleTagClose(tag)
      }
    };
  
    const handleTagClose = (closedTag) => {
      setselectedTags(selectedTags.filter((tag) => tag !== closedTag));
    };
  
    const handleReset = () => {
      setselectedTags([]);
      onDone(filterType,[]);
    };
  
    const handleDone = () => {
      setIsOpen(false);
      onDone(filterType,selectedTags)
    };

    // 点击筛选框以外的地方取消这次未点击确认的操作
    useEffect(() => {
      function checkOutsideClick(event) {
        if (filterBoxRef.current && !filterBoxRef.current.contains(event.target)) {
          setIsOpen(false);
          setselectedTags(currentTag);
        }
      }
      window.addEventListener('click', checkOutsideClick);
      
      return () => {
        window.removeEventListener('click', checkOutsideClick);
      }
      
    }, [currentTag])
  
    return (
      <div className="filterBox" ref={filterBoxRef}>
        <div className="label">
          <span className="font-dinot">{label}</span>
        </div>
        <div
          className="text_box"
          onClick={() => setIsOpen(!isOpen)}
        >
          {!selectedTags.length ? <span className="default_text font-dinot">{t('partner.notSelect')}</span> : selectedTags.join(", ")}
          <span className="icon_wrapper">
            {isOpen ? <Up className="icon"/> : <Down className="icon"/>}
          </span>
        </div>
        <div
          className="drop_down_box"
          style={{
            display: isOpen ? "block" : "none",
          }}
        >
          {isOpen && (
            <div>
              <div className="tag_box">
                {Object.entries(options).map(([key,value]) => (
                  <Tag
                    key={key}
                    name={value}
                    isSelected={selectedTags.includes(value)}
                    onClick={() => handleTagClick(value)}
                    onClose={() => handleTagClose(value)}
                  />
                ))}
              </div>
              <div className="btn">
                <div className="btn_reset" onClick={handleReset}>
                  <span className="font-dinot">{t('partner.resetAll')}</span>
                </div>
                <div className="btn_done" onClick={handleDone}>
                  <span className="font-dinot">{t('partner.done')}</span>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }

  export default FilterBox;